import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, colors } from "@material-ui/core";
import { docTypes } from "./accountingRPT-services";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default function AccountingRPTTable(props) {
  const { t } = useTranslation(["forms"]);
  const { data, color } = props;

  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: color ? color : colors.grey[500],
              color: "#fff",
            }}
          >
            <TableCell style={{ color: "#fff" }} align="center">
              {t("balance")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("dept")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("credit")}{" "}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              رقم المستند{" "}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("date")}{" "}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("doc-type")}{" "}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("ref-no")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("note")}{" "}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("net-profit")}
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row?.balance?.toFixed(2)}</TableCell>
              <TableCell align="center">{row?.dept.toFixed(2)}</TableCell>
              <TableCell align="center">{row?.credit.toFixed(2)}</TableCell>
              <TableCell align="center">{row?.doc_no}</TableCell>
              <TableCell align="center">{row?.date}</TableCell>
              <TableCell align="center" style={{ color: "#00f" }}>
                {row?.type > 0 && row?.type < 13 ? (
                  <Button
                    onClick={() => {
                      //  his.push({
                      //     pathname: `/int-receipt`,
                      //     state:'edrees'
                      //   });
                    }}
                  >
                    {docTypes[row?.type]?.name}
                  </Button>
                ) : null}
              </TableCell>
              <TableCell align="center">{row?.ref_no}</TableCell>
              <TableCell align="center">{row?.notes}</TableCell>
              <TableCell align="center">{row?.Profit}</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
