import React, { useState, useRef, Component } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeIcon from "@material-ui/icons/Home";
import Grid from "@material-ui/core/Grid";
import { useStylesSpDial } from "../../../St/Reviews/UseStyle";
import jsPDF from "jspdf";
import { callAddFont } from "../../amiri-font";
import ShareIcon from "@material-ui/icons/Share";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { useEffect } from "react";

import InvContainer from "./invContainer";

class ComponentToPrint extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const style = {
      backgroundColor: "#fff",
      color: "#000",
      
    };

    return (
      <Typography component="div" style={style}>
        <InvContainer />
        {/* <Header onUnmountComplete={this.props?.onUnmountComplete} /> */}
        {/* <Body onUnmountComplete={this.props?.onUnmountComplete} /> */}
        {/* <Footer onUnmountComplete={this.props?.onUnmountComplete} /> */}
      </Typography>
    );
  }
}

const InvCompany = () => {
  const componentRef = useRef();
  const reportDownloadRef = useRef();
  const clasSp = useStylesSpDial();

  
  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(true);
  };
  let his = useHistory();
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};
  const printDev=async()=>{
    if (locInv?.Delivery===1){
      const serializedinv = JSON.stringify({
        proc_id: locInv.proc_id,
        backInv: "InvSO",
        branch: locInv.branch,
        ProcTypeInvProc: locInv.ProcTypeInvProc,
        Delivery:locInv.Delivery,
      });
      await localStorage.setItem("datainv", serializedinv);
      his.push({ pathname: "/DeleveryNotes" });
    }
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => printDev(),
    // onAfterPrint: () => back(false),
  });
  const [childMountCount, setChildMountCount] = useState(0);
  const handleChildMountComplete = () => {
    setChildMountCount((prevCount) => prevCount + 1);
  };

  const styleCont = {
    backgroundImage: "linear-gradient(60deg, #4e9e84 , #2688ba)",
  };

  const back = (path) => {
    path ? his.push({ pathname: "/" }) : his.goBack();
  };



  const shareData = async () => {
    jsPDF.API.events.push(["addFonts", callAddFont]);
    // var doc = new jsPDF("p", "pt", "a3");
    var doc = new jsPDF("portrait", "pt", "a4");
   // doc.setFont("Amiri-Regular", "normal");
    doc.setFont("Helvetica", "bold");
    let mfile;
    await doc.html(reportDownloadRef.current, {
      html2canvas:{

      width:200,
      height:297,
      scale:0.75,
      
      },
      async callback(doc) {
        var blob = await doc.output("blob");
        mfile = new File([blob], "InvCompany.pdf", { type: blob.type });
      },
    });

    if (navigator.share && navigator.canShare({ files: [mfile] })) {
      await navigator.share({
        title: "hello to orax app",
        text: "welcome this is text",
        url: "orax.app/",
        files: [mfile],
      });
    } else {
      alert("cant share files");
    }
  };

  
  const handleGeneratePdf = async () => {
    jsPDF.API.events.push(["addFonts", callAddFont]);
    var doc = new jsPDF("portrait", "pt", "a4");
    // var doc = new jsPDF("p", "pt", [1040, 800]);
    //doc.setFont("Amiri-Regular", "normal");
    doc.setFont("Helvetica", "bold");
    doc.html(reportDownloadRef.current, {
      html2canvas:{
        width:200,
        height:297,
        scale:0.75,
        
        },
      async callback(doc) {
        await doc.save("InvCompany");
      },
    });
    // handlePrint();
  };

  const actions = [
    {
      icon: (
        <PrintIcon className={clasSp.ico} onClick={handlePrint} />

      ),
      // </Fab>
      name: "طباعـة",
    },
    {
      icon: (
        <ArrowDownward className={clasSp.ico} onClick={handleGeneratePdf} />
      ),
      name: "تنزيل",
    },
    {
      icon: <ShareIcon className={clasSp.ico} onClick={shareData} />,
      name: "مشاركة",
    },
    {
      icon: (
        <ArrowBackIcon className={clasSp.ico} onClick={() => back(false)} />
      ),
      name: "رجـوع",
    },
  ];

  return (
    <div className={clasSp.root}>
      <Grid item container justifyContent="center" style={styleCont}>
        <Grid item style={{ backgroundColor: "#fff", width: "21cm" }}>
          <CssBaseline />
          <div
            ref={reportDownloadRef}
            lang={"ar"}
            style={{
              fontFamily: "Amiri-Regular",
              fontStyle: "normal",
              letterSpacing: "normal",
            }}
          >
            <ComponentToPrint
              ref={componentRef}
              onUnmountComplete={handleChildMountComplete}
            />
          </div>
        </Grid>
      </Grid>

      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial}
        hidden={false}
        icon={
          <SpeedDialIcon openIcon={<HomeIcon />} onClick={() => back(true)} />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action, i) => (
          <SpeedDialAction
            key={i}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default InvCompany;
