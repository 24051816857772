import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AccountSelect from '../../../components/controls/accounts-select';
import SelectTypesFilter from '../balanceCustRPT/balanceCustRPT-typeFilter';
import { useStylesAuto, useStylesAutoBlack, useStylesAutoPurch, useStylesLbl, useStylesTF } from '../../../St/Reports/UseStyle';

export default function PaymentTransactionRPTForm(props) {

  const {
    rptParams,
    setRptParams,
    onChangeData,
    AutoOptions,
    onChangeAutoData, } = props;
  const accounts = rptParams.type.code == 0 ? AutoOptions.Accindex
    : AutoOptions.Accindex?.filter(ac => ac.id.toString().toLowerCase().startsWith(rptParams.type.code.toString()) || ac.id == -1);
  const calsBlack = useStylesAutoBlack();
  const claslbl = useStylesLbl();

  const clasTF = useStylesTF();

  return (
    <React.Fragment>
      <SelectTypesFilter {...{ rptParams, setRptParams, defaultAccount: accounts[0] }} />
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={4} md={6} sm={10} xs={12}>
          <AccountSelect
            accounts={accounts}
            account={rptParams.account}
            onSelect={(newValue) => { onChangeAutoData("account", newValue) }}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField fullWidth variant="outlined" size="small" id="datef"
            label="من تاريخ"
            className={clasTF.rootCust}
            InputProps={{ className: claslbl.clrBlack }}
            InputLabelProps={{ className: claslbl.clrBlackLblS }}
            type="date" format="YYYY-MM-DD"
            name="datef" value={rptParams.dateFrom}
            onChange={onChangeData("dateFrom")} />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField fullWidth variant="outlined" size="small" id="dateT"
            label="إلى تاريخ"
            className={clasTF.rootCust}
            InputProps={{ className: claslbl.clrBlack }}
            InputLabelProps={{ className: claslbl.clrBlackLblS }}
            type="date" format="YYYY-MM-DD"
            name="datet" value={rptParams.dateTo}
            onChange={onChangeData("dateTo")} />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete value={rptParams.branch}
            classes={calsBlack}
            onChange={(event, newValue) => {
              onChangeAutoData("branch", newValue)
            }}
            id="combo-box-demo" size="small"
            options={AutoOptions.Branches}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            style={{}}
            disableClearable
            renderInput={(params) => <TextField {...params}
              InputLabelProps={{ shrink: true, className: claslbl.clrBlackLblS }} label="الفرع" variant="outlined" />}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete value={rptParams.omla}
            classes={calsBlack}
            onChange={(event, newValue) => {
              onChangeAutoData("omla", newValue)
            }}
            id="combo-box-demo" size="small"
            options={AutoOptions?.Omlat}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            style={{}}
            disableClearable
            renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true, className: claslbl.clrBlackLblS }} label="العملة " variant="outlined" />}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={3} xs={6}>
          <Autocomplete
            value={rptParams.user}
            classes={calsBlack}
            onChange={(event, newValue) => {
              onChangeAutoData("user", newValue)
            }}
            id="combo-box-demo" size="small"
            options={AutoOptions?.Users}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            style={{}}
            disableClearable
            renderInput={(params) => <TextField {...params}
              label="المستخدم " variant="outlined" size='small'
              InputLabelProps={{ shrink: true, className: claslbl.clrBlack }} />}
          />
        </Grid>
      </Grid>

    </React.Fragment>
  );

}