import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { insh, sto } from "../../../../Context/InshData";
import { uri } from "../../../../help/Api";
import LoadingPage from "../../../../services/LoadingPage";
import dataF from "../../../../Server/dataF.json";
import { useHistory, useLocation } from "react-router-dom";
import InvoicesMain from "../invoiceMain";
import { getLS } from "../../../../utils/useLS";
import { Dialog } from "@material-ui/core";
import InvoicesFormMain from "./InvoiceesMains";
import { insh as inshst } from "../../../../Context/InshSt";

const defaultAutos = {
  Safes: [],
};
export default function InvoicesDialogs(props) {
    const {open, onClose,onSave ,invData } = props;

    const settingInv={
            titleHed: invData.ProcType_InvProc==="02"?"Sales Invoice": invData.ProcType_InvProc==="12"?"Return Sales Invoice":
            invData.ProcType_InvProc==="01"?"Purchase Invoice":invData.ProcType_InvProc==="11"?"Return Parchase":"First Stock",
            titleHedEn:invData.ProcType_InvProc==="02"?"Sales Invoice": invData.ProcType_InvProc==="12"?"Return Sales Invoice":
            invData.ProcType_InvProc==="01"?"Purchase Invoice":invData.ProcType_InvProc==="11"?"Return Parchase":"First Stock",
            cusType: invData.ProcType_InvProc==="02"||invData.ProcType_InvProc==="12"?1:2,
            cusN: invData.ProcType_InvProc==="02"||invData.ProcType_InvProc==="12"?"Cust":"Supp",
            stors: insh.items,
            PrTy_InPr: invData.ProcType_InvProc,
            iformid:invData.ProcType_InvProc==="02"|| invData.ProcType_InvProc==="12"?40002:invData.ProcType_InvProc==="22"?40005:40001,
            routI_R_RS: "Inv_R_RS_ToS",
            routIS: "Inv_Sub_ToS/" /*routR:'',routRS:'',*/,
    }

  const tb = {
    other: true,
    wait: true,
    CustomersInv:true,
    SafesInv: true,
    StocksInv: true,
    salesmen: true,
    BankInv:true,
    costcenter:true,
    defaultSalesUser:true,
    InvItems:true,
    PermissionsElemnts: true,
    PermissionsCuCo: true,
    Branches:true,
    MaxShifts:true,
    PermissionsCuCoPurch:invData.ProcType_InvProc==="01"|| invData.ProcType_InvProc==="11",
    Items: true,
    groupSub: true,
    PermissionsInvs:true,
    UsersA: true,
    typesInv:true,
    stor: { ...sto,branch:sto.branchi},
    parms: { 
      tname: "invs",
        branch:invData.branch,
        iempid: sto.empi,
        scurrenciestype: "C",
        takei: 40,
        iformid:invData.ProcType_InvProc==="02"|| invData.ProcType_InvProc==="12"?40002:invData.ProcType_InvProc==="22"?40005:40001,
       iformidCuCo: dataF[3].id /* for curr and yashml */,
        sProcTypeInvProc: invData.ProcType_InvProc, 
     },

  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "GetD",
    tb,
  );

  if (
    fetchAll ||
    !AutoOptions?.Customers   
  )
    return <LoadingPage />;


  return (
    <Dialog fullScreen open={open} onClose={onClose}>
    <InvoicesFormMain
      {...{
        AutoOptions,
        isPurches: invData.ProcType_InvProc==="01"|| invData.ProcType_InvProc==="11"?true:false,
        isStock:invData.ProcType_InvProc==="22"?true:false,
        isDialog:true,
        invData,
        onSave,
        onClose,
        formid:invData.ProcType_InvProc==="02"|| invData.ProcType_InvProc==="12"?40002:invData.ProcType_InvProc==="22"?40005:40001,
        invsType:invData.ProcType_InvProc==="02"?0: invData.ProcType_InvProc==="12"?1:invData.ProcType_InvProc==="01"?3:invData.ProcType_InvProc==="11"?4:5,
        cusType:invData.ProcType_InvProc==="02"||invData.ProcType_InvProc==="12"?1:2,
        nameInv:invData.ProcType_InvProc==="02"?"Sales Invoice": invData.ProcType_InvProc==="12"?"Return Sales Invoice":
        invData.ProcType_InvProc==="01"?"Purchase Invoice":invData.ProcType_InvProc==="11"?"Return Parchase":"First Stock",
        invStyle:invData.ProcType_InvProc==="02"? inshst.sal: invData.ProcType_InvProc==="12"? inshst.ret:
        invData.ProcType_InvProc==="01"? inshst.purch:invData.ProcType_InvProc==="11"? inshst.ret: inshst.safe,
        invStor:invData.ProcType_InvProc==="02"?{invsType: 0}: invData.ProcType_InvProc==="12"? {invsType:1}:
        invData.ProcType_InvProc==="01"?{invsType: 3}:invData.ProcType_InvProc==="11"? {invsType:4}:{invsType: 5},
        settingInv:settingInv,        
      }} 
    />
    </Dialog>    
)
}
