import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../../help/Api";

import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { sto } from "../../../../Context/InshData";
//import {useStylesHed} from '../../../St/comps/UseStyle';
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
function FooterCM(porps) {

  const {found,inv,sta}=porps;
  const locDinv = JSON.parse(localStorage.getItem("datainv")) || {};
  const clas = useInvAF();
  const sty = {
    cntr: { textAlign: "center", paddingTop: 10 },
    lft: { textAlign: "left", padingLeft: 5 },
    rit: { textAlign: "right", padingRight: 5 },
    dirR: {
      direction: "rtl",
      color: "black",

    },
    dirL: { direction: "ltr" },
  };
  return (
    <Grid item container style={sty.dirR}>
      <Grid item container style={sty.cntr} spacing={0}>

 
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={1}
        style={{  fontWeight: "bold", textAlign: "center" }}
      >
        <Grid item xs={12} style={{ fontFamily: "Amiri-Regular" }}>
        <Grid item xs={12} container className={clas.footerA}>
          
          {/* <Grid item xs={12}  className={clas.BdytotCntMmm} style={{textAlign:"right"}}>
          {inv.notes}
          </Grid> */}
          </Grid>
          <br/>
          <Grid item xs={12} container className={clas.footerB}>
         
          <Grid item xs={4}style={{ fontFamily: "Amiri-Regular" }}>
           {"الموظف"}{"  :"} {sto.usernames}
          </Grid>
        

          <Grid item xs={4} style={{ fontFamily: "Amiri-Regular" }}>
          {" "}
          {"  البائع" + "     " + " Salesmans Sign "}
        </Grid>
        <Grid item xs={4} style={{ fontFamily: "Amiri-Regular"  }}>
          {"توقيع المستلم" + "     " + " Receivrs Sign "}
        </Grid>
        </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
}
export default FooterCM;

