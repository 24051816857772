import React, { useState, useRef, useEffect } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HomeIcon from "@material-ui/icons/Home";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import { useStylesSpDial } from "../../../St/Reviews/UseStyle";

import axios from "axios";
import { uri } from "../../../help/Api";
const api = axios.create({ baseURL: uri });

class ComponentToPrint extends React.Component {
  state = {
    pwca: true,
  };

  constructor() {
    super();
  }

  render() {
    const locInv = JSON.parse(localStorage.getItem("premiApp")) || {};

    const styleCont = {
      // backgroundImage: 'linear-gradient(60deg, #4e9e84 , #2688ba)',
      backgroundColor: "#fff",
    };

    const style = {
      // backgroundColor: 'green',
      width: "80mm",
      backgroundColor: "#fff",
      //backgroundImage: 'linear-gradient(60deg, #4e9e84 , #2688ba)',
    };
    const stylle = {
      // backgroundColor: 'green',
      width: "58mm",
      backgroundColor: "#fff",
      //backgroundImage: 'linear-gradient(60deg, #4e9e84 , #2688ba)',
    };
    const styleTyg = {
      backgroundColor: "#fff",
      color: "#000",
      // paddingTop: '0.2cm',
      // paddingBottom: '0.2cm',

      padding: "0.2mm",
    };

    return (
      <Grid item container justifyContent="center" style={styleCont}>
        <Grid item style={locInv.stayWCa == true ? style : stylle}>
          <CssBaseline />
          <Typography component="div" style={styleTyg}>
            <Header onUnmountComplete={this.props?.onUnmountComplete} />
            <Body onUnmountComplete={this.props?.onUnmountComplete} />
            <Footer onUnmountComplete={this.props?.onUnmountComplete} />
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const CashairNoTax = () => {
  const componentRef = useRef();

  const clasSp = useStylesSpDial();
  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(true);
  };

  let his = useHistory();

  const back = (path) => {
    path ? his.push({ pathname: "/" }) : his.goBack();
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  onAfterPrint: () => back(false),
  });
  const [childMountCount, setChildMountCount] = useState(0);
  const handleChildMountComplete = () => {
    setChildMountCount((prevCount) => prevCount + 1);
  };
  // useEffect(() => {
  //   console.log(childMountCount);
  //   if (childMountCount === 3) {
  //     setTimeout(async () => {
  //       handlePrint();
  //     }, 500);
  //   }
  // }, [childMountCount]);

  const actions = [
    {
      icon: <PrintIcon className={clasSp.ico} onClick={handlePrint} />,
      name: "طباعـة",
    },

    {
      icon: (
        <ArrowForwardIcon className={clasSp.ico} onClick={() => back(false)} />
      ),
      name: "رجـوع",
    },
  ];
  return (
    <div className={clasSp.root}>
      <ComponentToPrint
        ref={componentRef}
        onUnmountComplete={handleChildMountComplete}
      />
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial}
        hidden={false}
        icon={
          <SpeedDialIcon openIcon={<HomeIcon />} onClick={() => back(true)} />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action, i) => (
          <SpeedDialAction
            key={i}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default CashairNoTax;
