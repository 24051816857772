import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  colors,
  TextField,
} from "@material-ui/core";

import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import BarcodeGenerator, { BarcodeGenerator04 } from "./BarcodeGenerator";
import Button from "../../../../../components/controls/Button";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useEffect } from "react";
import BarcodeGeneratorTemplate02 from "./BarcodeGeneratorTemplate02";
import { sto } from "../../../../../Context/InshData";
import BarcodeGeneratormusa from "./BarcodeGenerators";
import { style } from "../../../../../Fronts/Menues/st/cStyle";
import { useStylesLbl } from "../../../../../St/comps/UseStyle";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    alignContent: "center",
    textAlign: "center",
    border: "1px solid #000",
    backgroundColor: "#eee",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

class ComponentToPrint extends React.Component {
  state = {
    pwca: true,
  };
  constructor() {
    super();
  }

  render() {
    // const styleTyg = {
    //   // backgroundColor: '#fff',
    //   color: "#000",
    //   minWidth: "50mm",
    // };
    const styleTyg = {
      // backgroundColor: '#fff',
      color:'#000',
      padding: '0.1mm',
      // maxWidth: `${this.prop.maqas.widthLebal}mm`,
      alignContent: 'center'
    };
    return (
      <Typography component="div" style={styleTyg}>
        <BarcodeGeneratormusa
          // barcode={this.props.data.barcode}
          // itemName={this.props.data.symbol}
          // price={this.props.data.sale_price}

          barcode={this.props.data.barcode}
          itemName={this.props.data.symbol}
          price={this.props.data.sale_price}
          // expiry_date={this.props.data.expiry_date}
          // prody_date={this.props.data.prody_date}
          maqas={this.props.maqas}

        />
      </Typography>
    );
  }
}

class ComponentToPrintTemplate02 extends React.Component {
  state = {
    pwca: true,
  };

  render() {
    const styleTyg1 = {
      // backgroundColor: '#fff',
      color: "#000",
      padding: "0.1mm",
      minWidth: "50mm",
      alignContent: "center"
    };
    const styleTyg = {
      // backgroundColor: '#fff',
      color:'#000',
      padding: '0.1mm',
      // maxWidth: `${this.prop.maqas.widthLebal}mm`,
      alignContent: 'center'
    };

    return (
      <Typography justifyContent="center" component="div" style={styleTyg}>
        <BarcodeGeneratorTemplate02

          barcode={this.props.data.barcode}
          itemName={this.props.data.symbol}
          price={this.props.data.sale_price}
          expiry_date={this.props.data.expiry_date}
          prody_date={this.props.data.prody_date}
          maqas={this.props.maqas}
        />
      </Typography>
    );
  }
}
class ComponentToPrintTemplate04 extends React.Component {
  state = {
    pwca: true,
  };

  render() {
    const styleTyg = {
      // backgroundColor: '#fff',
      color:'#000',
      padding: '0.1mm',
      // maxWidth: `${this.prop.maqas.widthLebal}mm`,
    };

    return (
      <Typography component="div" style={styleTyg}>
        {/* <BarcodeGenerator04 */}
        <BarcodeGeneratormusa

          barcode={this.props.data.barcode}
          itemName={this.props.data.symbol}
          price={this.props.data.sale_price}
          expiry_date={this.props.data.expiry_date}
          prody_date={this.props.data.prody_date}
          maqas={this.props.maqas}
        />
      </Typography>
    );
  }
}
export default function BarcodeDialog(props) {
  const { open, data, setOpen } = props;
  const componentRef = useRef();
  const componentRef02 = useRef();
  const componentRef04 = useRef();
  const classes = useStyles();
  const maqasvalue = {
    widthScreen: 100,
    widthLebal: 60,
    wisthPaper: 50,
    margnTop:0,
    margnBottom:0,
    margnRight:0,
    marginLeft:0

  }
  const [maqas, setMaqas] = React.useState(maqasvalue);
  const claslbl = useStylesLbl();

  const onChangeData = (propName) => (event) => {
    setMaqas({ ...maqas, [propName]: event.target.value });
  };
  useEffect(() => {
    console.log("the sto is", data);
  });
  const pageStyle = `
  @page {
      size: ${maqas.widthLebal}mm 25mm,
  };
  @media all {
      .pageBreak {
          display: none
      }
  };
  
  @media print {
      .pageBreak {
          page-break-before: always;
      }
  }`;

  return (
    <Dialog justifyContent="center" open={open} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5">{"شاشة طباعة الباركود"}</Typography>
        <br />

      </DialogTitle>
      {/* <DialogContent className={...classes.dialogContent,}> */}
      <DialogContent justifyContent="center" className={classes.dialogContent} style={{
        width: maqas.widthScreen < 100 ? "100mm" : `${maqas.widthScreen}mm`,
        backgroundColor: '#fff', alignContent: "center"
      }}
      >

        <Grid item container lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={4} md={4} sm={4} xs={4} >
        <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="id"
              label={"Screen Width"}
              style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
              name="barcode"
              value={maqas.widthScreen}
              InputLabelProps={{ className: claslbl.clrBlackLblS }}
              InputProps={{ className: claslbl.clrBlackTxt }}
              onChange={onChangeData("widthScreen")} />
          </Grid>

          <Grid item lg={4} md={4} sm={4} xs={4} >
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="symbol"
              InputLabelProps={{ className: claslbl.clrBlackLblS }}
              InputProps={{ className: claslbl.clrBlackTxt }}
              label={"Lebal Width"}
              style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
              name="symbol"
              value={maqas.widthLebal}

              onChange={onChangeData("widthLebal")}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4} >
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="id"
              InputLabelProps={{ className: claslbl.clrBlackLblS }}
              InputProps={{ className: claslbl.clrBlackTxt }}
              label={"Screen Width"}
              // InputLabelProps={style{{}}}
              style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
              name="barcode"
              value={maqas.wisthPaper}

              onChange={onChangeData("wisthPaper")} />
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={4} >
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="margnTop"
              InputLabelProps={{ className: claslbl.clrBlackLblS }}
              InputProps={{ className: claslbl.clrBlackTxt }}
              label={"Top"}
              style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
              name="symbol"
              value={maqas.margnTop}

              onChange={onChangeData("margnTop")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={4} >
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="margnTop"
              InputLabelProps={{ className: claslbl.clrBlackLblS }}
              InputProps={{ className: claslbl.clrBlackTxt }}
              label={"Right"}
              style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
              name="symbol"
              value={maqas.margnBottom}

              onChange={onChangeData("margnBottom")}
            />
          </Grid>

          <Grid item lg={3} md={3} sm={4} xs={4} >
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="margnTop"
              InputLabelProps={{ className: claslbl.clrBlackLblS }}
              InputProps={{ className: claslbl.clrBlackTxt }}
              label={"Top"}
              style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
              name="symbol"
              value={maqas.margnTop}

              onChange={onChangeData("margnTop")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={4} >
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="margnTop"
              InputLabelProps={{ className: claslbl.clrBlackLblS }}
              InputProps={{ className: claslbl.clrBlackTxt }}
              label={"Right"}
              style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
              name="symbol"
              value={maqas.margnBottom}

              onChange={onChangeData("margnBottom")}
            />
          </Grid>
        </Grid>
        <div justifyContent="center" className={classes.dialogContent} style={{
          width: maqas.widthLebal < 50 ? "50mm" : `${maqas.widthLebal}mm`,
          backgroundColor: '#fff', alignContent: "center",
        }}
        >
          <ComponentToPrintTemplate02 ref={componentRef02} {...{ data, maqas }} />
        </div>
        <ReactToPrint justifyContent="center"
          trigger={() => (
            <Button
              text="طباعة"
              size="small"
              color="secondary"
              variant="outlined"
            />
          )}
          content={() => componentRef02.current}
          pageStyle={pageStyle}
        />
        <div justifyContent="center" className={classes.dialogContent} style={{
          width: maqas.widthLebal < 50 ? "50mm" : `${maqas.widthLebal}mm`,
          backgroundColor: '#fff', alignContent: "center"
        }}
        >
          <ComponentToPrint ref={componentRef} {...{ data, maqas }} />
        </div>
        <ReactToPrint justifyContent="center"
          trigger={() => (
            <Button
              text="طباعة"
              size="small"
              color="secondary"
              variant="outlined"
            />
          )}
          content={() => componentRef.current}
          pageStyle={pageStyle}
        />
        <div justifyContent="center" className={classes.dialogContent} style={{
          width: maqas.widthLebal < 50 ? "50mm" : `${maqas.widthLebal}mm`,
          backgroundColor: '#fff', alignContent: "center"
        }}
        >          
        <ComponentToPrintTemplate02 ref={componentRef02} {...{ data, maqas }} />
        </div>
        <ReactToPrint
          trigger={() => (
            <Button
              text="طباعة"
              size="small"
              color="secondary"
              variant="outlined"
            />
          )}
          content={() => componentRef02.current}
          pageStyle={pageStyle}
        />
        <div justifyContent="center" className={classes.dialogContent} style={{
          width: maqas.widthLebal < 50 ? "50mm" : `${maqas.widthLebal}mm`,
          backgroundColor: '#fff', alignContent: "center"
        }}
        >
          <ComponentToPrintTemplate04 ref={componentRef04} {...{ data,maqas }} />
        </div>
        <ReactToPrint justifyContent="center"
          trigger={() => (
            <Button
              text="طباعة"
              size="small"
              color="secondary"
              variant="outlined"
            />
          )}
          content={() => componentRef04.current}
          pageStyle={pageStyle}
        />
        {/* <Grid container xs={12} spacing={2}>
          <Grid item xs={4}>
            <div className={classes.dialogContent}>
              <ComponentToPrint ref={componentRef} {...{ data }} />
            </div>
            <ReactToPrint
              trigger={() => (
                <Button
                  text="طباعة"
                  size="small"
                  color="secondary"
                  variant="outlined"
                />
              )}
              content={() => componentRef.current}
              pageStyle={pageStyle}
            />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.dialogContent}>
              <ComponentToPrintTemplate02 ref={componentRef02} {...{ data }} />
            </div>
            <ReactToPrint
              trigger={() => (
                <Button
                  text="طباعة"
                  size="small"
                  color="secondary"
                  variant="outlined"
                />
              )}
              content={() => componentRef02.current}
              pageStyle={pageStyle}
            />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.dialogContent}>
              <ComponentToPrintTemplate02 ref={componentRef02} {...{ data }} />
            </div>
          </Grid>
        </Grid> */}
      </DialogContent>
      {/* <DialogContent className={classes.dialogContent}></DialogContent> */}
      <DialogActions className={classes.dialogAction}>
        <Button text="إغلاق" color="secondary" onClick={() => setOpen(false)} />
        <ReactToPrint
          trigger={() => <Button text="طباعة" color="secondary" />}
          content={() => componentRef.current}
        />
      </DialogActions>
    </Dialog>
  );
}
