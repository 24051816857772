import React, { Fragment, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ScannerIcon from "@material-ui/icons/Scanner";

import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,
} from '../../../../St/Reports/UseStyle';
import { AppBar, Button, colors, Container, CssBaseline, Hidden, Paper, Toolbar } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';

import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";
import CircularProgress from "@material-ui/core/CircularProgress";
import { insh } from "../../../../Context/InshSt";

export default function SalesTransferBar(props) {
    const { t } = useTranslation(["forms", "translation"]);

    const {
        rptParams,
        setBarcodeDialogOpen,
        buttonClassnameNew,
        buttonClassnameSave,
        buttonClassnameDelete,
        clasCAB,
        newToI,
        setting,
        stElemnt,
        loading,
        clasBA,
        backHome,
        back,
        uiElement,
        handOpenDw,
        TransitionUp,
        addToBS,
        setOpenDw,
        estelamAfter,
        deleteToI,
        printHandle,
        prevent
    } = props;

    return (
        <React.Fragment>
            <CssBaseline />

            
            <AppBar
                position="fixed"
                color="primary"
                style={{
                    backgroundImage: "linear-gradient(160deg, #6464d7 , #1a1a8a)", ...stElemnt.all.appBarBA,
                    direction: "ltr"
                }}

            >
                <Toolbar className={clasBA.ToolbarBA} variant="dense">

                    <Hidden only={["xs"]}>
                        {/* {!isDialog && (
                           
                        )} */}
                        <Button
                            style={{ color: "#fff" }}
                            onClick={backHome}
                            startIcon={<CloseIcon />}
                        >
                            {t("forms:exit")}
                        </Button>
                        <Button
                            style={{ color: "#fff" }}
                            onClick={() => back()}
                            startIcon={<ReplyIcon />}
                        >
                            {t("forms:back")}
                        </Button>

                        <Button
                            style={{ color: "#fff" }}
                            onClick={() =>
                                setOpenDw(true)
                            }
                            startIcon={<SearchIcon />}
                        >
                            {t("forms:search")}
                        </Button>
                    </Hidden>

                    <Hidden smUp={["sm"]}>

                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={backHome}
                        >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => back()}
                        >
                            <ReplyIcon />
                        </IconButton>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={(e) => {
                                setOpenDw(true)
                            }}                        >
                            <SearchIcon />
                        </IconButton>

                        <div style={{ alignContent: "right", edge: "end" }}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="new"

                                disabled={loading.new}
                                onClick={(e) => newToI(1000)}
                            >
                                <AddIcon />
                            </IconButton>
                            {loading.new && (
                                <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
                            )}
                        </div>
                    </Hidden>
                    <div className={clasBA.growBA} />

                    <Hidden smUp={["sm"]}>
                        {!prevent? (
                            <div style={{ alignContent: "right" }}>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    aria-label="delete"
                                onClick={(e) => deleteToI(e, rptParams.id, setting.screen)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                {loading.delete && (
                                    <CircularProgress
                                        size={24}
                                        className={clasCAB.buttonProgressCAB}
                                    />
                                )}
                            </div>
                        ) : null}
                        {!prevent&&(
                              <div style={{ alignContent: "right", edge: "end" }}>

                              <IconButton
                                  edge="end"
                                  color="inherit"
                                  aria-label="print"
                                  disabled={loading.save}
                                  onClick={(e) => printHandle()}
                              >
                                  <PrintIcon />
                              </IconButton>
                              {loading.save && (
                                  <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
                              )}
                          </div>
                        )}
                        <div style={{ alignContent: "right", edge: "end" }}></div>

                        <div style={{ alignContent: "left", edge: "end",marginRight:"0.5rem" }}>

                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="save"
                                disabled={loading.save}
                                onClick={(e) => addToBS("save")}
                            >
                                <SaveIcon />
                            </IconButton>
                            {loading.save && (
                                <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
                            )}
                        </div>


                      
                    </Hidden>



                    <Hidden only={["xs"]}>

                        {!prevent ? (

                            <div className={clasCAB.wrapperCAB}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={buttonClassnameDelete}
                                    startIcon={<DeleteIcon />}
                                    style={{ backgroundColor: "#3f51b5", ...stElemnt.all.iconBtn }}
                                    disabled={loading.delete}
                                    onClick={(e) => deleteToI(e, rptParams.id, setting.screen)}
                                >
                                    {t("forms:delete")}
                                </Button>
                                {loading.delete && (
                                    <CircularProgress
                                        size={24}
                                        className={clasCAB.buttonProgressCAB}
                                    />
                                )}
                            </div>
                        ) : null}

                        {estelamAfter && (

                            <div className={clasCAB.wrapperCAB}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={{ backgroundColor: "#3f51b5", ...stElemnt.all.iconBtn }}
                                    className={buttonClassnameSave}
                                    disabled={loading.save}
                                    onClick={printHandle}
                                    startIcon={<PrintIcon />}
                                >
                                    {" "}
                                    {"طباعة"}{" "}
                                </Button>
                                {loading.save && (
                                    <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
                                )}
                            </div>
                        )}
                        <div className={clasCAB.wrapperCAB}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{ backgroundColor: "#3f51b5", ...stElemnt.all.iconBtn }}
                                className={buttonClassnameSave}
                                disabled={loading.save}
                                onClick={() => addToBS("print")}
                                startIcon={<PrintIcon />}
                            >
                                {estelamAfter?"تعديل وطباعة":"حفظ وطباعة"}{" "}
                            </Button>
                            {loading.save && (
                                <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
                            )}
                        </div>
                        <div className={clasCAB.wrapperCAB}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{ backgroundColor: "#3f51b5", ...stElemnt.all.iconBtn }}
                                className={buttonClassnameSave}
                                disabled={loading.save}
                                onClick={() => addToBS("save")}
                                startIcon={<SaveIcon />}
                            >
                                {estelamAfter?"تعديل":"حفظ"}{" "}
                            </Button>
                            {loading.save && (
                                <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
                            )}
                        </div>
                        <div className={clasCAB.wrapperCAB}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{ backgroundColor: "#3f51b5", ...stElemnt.all.iconBtn }}
                                className={buttonClassnameNew}
                                disabled={loading.new}
                                onClick={() => newToI(1000)}
                                startIcon={<AddIcon />}
                            >
                                {" "}
                                {t("forms:new")}{" "}
                            </Button>
                            {loading.new && (
                                <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
                            )}
                        </div>
                    </Hidden>
                </Toolbar>
            </AppBar>
        </React.Fragment >
    );

}