import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
  useStylesUp, useStylesHed, useStylesAuto,useStylesAutoPurch,useStylesTF, useStylesTD, useStylesBA, useStylesLbl
  , useStylesUiElemment,
  useStylesSpDial,useStylesAutoBlack,
} from '../../../../../St/Reports/UseStyle';


export default function RetrictionsSearchForm(props){

  const {
    type,
    rptParams,
   onChangeData,
   onChangeAutoData,
 AutoOptions,
  }=props;

  const clasup = useStylesUp();
  const calsPurch = useStylesAutoPurch();
  const claslbl = useStylesLbl();
  const clasAo = useStylesAutoBlack();
  const clasTF = useStylesTF();
    return (
          <React.Fragment>
              <Grid container style={{padding:5 }} spacing={1}>
              <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="رقم الفيد"
                  type="number" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlackTxt }}
                  InputLabelProps={{className:claslbl.clrBlackLblS}}
                  name="doc_no2" value={rptParams.doc_no2} 
                  onChange={onChangeData("doc_no2")}/>
                </Grid>

                <Grid item lg={2} md={2}  sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                    <TextField  fullWidth variant="outlined" size="small" id="datef" 
                    label="من تاريخ"
                    className={clasTF.rootCust} 
                    InputProps={{ className:claslbl.clrBlackTxt }}
                    InputLabelProps={{className:claslbl.clrBlackLblS}}
                    type="date" format="YYYY-MM-DD"
                    name="dateFrom" value={rptParams.dateFrom} 
                    onChange={onChangeData("dateFrom")}/>
                </Grid>
                <Grid item lg={2} md={2}  sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                    <TextField  fullWidth variant="outlined" size="small" id="datef" 
                    label="الى تاريخ"
                    className={clasTF.rootCust} 
                    InputProps={{ className:claslbl.clrBlackTxt }}
                    InputLabelProps={{className:claslbl.clrBlackLblS}}
                    type="date" format="YYYY-MM-DD"
                    name="dateTo" value={rptParams.dateTo} 
                    onChange={onChangeData("dateTo")}/>
                </Grid>

                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="المبلغ مدين"
                  type="number" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlackTxt }}
                  InputLabelProps={{className:claslbl.clrBlackLblS}}
                  name="deptval" value={rptParams.deptval} 
                  onChange={onChangeData("deptval")}/>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="المبلغ دائن"
                  type="number" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlackTxt }}
                  InputLabelProps={{className:claslbl.clrBlackLblS}}
                  name="creditval" value={rptParams.creditval} 
                  onChange={onChangeData("creditval")}/>
                </Grid>

                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={clasTF}
                        value={rptParams.user}
                        onChange={(event, newValue) => {
                            onChangeAutoData("user", newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={AutoOptions.Users}
                        style={{color:'black',fontWeight:"bold",fontSize:"1rem"}}
                        InputProps={{ className:claslbl.clrBlackTxt }}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                          }
                        renderInput={(params) => (
                            <TextField
                            id="user"
                                {...params}
                                label={"المستخدم"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className:claslbl.clrBlackLblS}}
                            />
                        )}
                    />

                </Grid>                           
               
               
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={clasTF}
                        value={rptParams.center}
                        onChange={(event, newValue) => {
                            onChangeAutoData("center", newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={AutoOptions.costcenters}
                        style={{color:'black',fontWeight:"bold",fontSize:"1rem"}}
                        InputProps={{ className:claslbl.clrBlackTxt }}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                          }
                        renderInput={(params) => (
                            <TextField
                            id="center"
                                {...params}
                                label={"مركز التكلفة"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className:claslbl.clrBlackLblS}}
                            />
                        )}
                    />

                </Grid> 

                <Grid item lg={4} md={4} sm={6} xs={12} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="البيان"
                  type="text" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlackTxt }}
                  InputLabelProps={{className:claslbl.clrBlackLblS}}
                  name="notes" value={rptParams.notes} 
                  onChange={onChangeData("notes")}/>
                </Grid>
              


 
            </Grid>  

          </React.Fragment>
          
    );
 
}