import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";

import HeaderM from "../../Invs/maintaince/A4/HeaderM";
import LoadingPage from "../../../services/LoadingPage";
import BodyInv from "./A4/BodyInv";
import FooterD from "./A4/FooterD";
import UnderHeader from "./A4/UnderHeader"
import * as _ from "lodash";
import { stElemnt } from "../../../St/Reviews/cStyle";
import { ButtonBase, Grid, Typography } from "@material-ui/core";
import { useInvAF } from "../../../St/Reviews/UseStyle";
import { sto } from "../../../Context/InshData";

function BillPage(props) {
  // const { locInv, found, sta, inv, chunk } = props;
  const { locInv, found, sta, inv, chunk, isFirst, isLast, sum } = props;
  const clas = useInvAF();

  return (
    <Grid item container style={{ ...stElemnt.AF.Reviws.pdf_page }}>
      <Fragment>
        <Grid xs={12} item style={{ ...stElemnt.AF.Reviws.pdf_InvD }}>
          {found.header_img != null  ? (
               (
               <Grid item xs={12} justifyContent="center" style={{ minWidth: "20cm", maxWidth: "20cm", minHeight: "3.6cm", maxHeight: "3.6cm", paddingBottom: "0.2cm", paddingTop: "0.1cm" }}>
                <div>
                  <ButtonBase>
                    {" "}
                    <img
                      src={`data:image/jpeg;base64,${found.header_img}`}
                      className={clas.headerImg}
                      alt={found.nameA}
                    />{" "}
                  </ButtonBase>
                </div>
              </Grid>)
          ):
          (<HeaderM locInv={locInv} foundData={found} sta={sta} />) 
            }
          {/* <HeaderM locInv={locInv} foundData={found} sta={sta} /> */}
          <UnderHeader found={found} inv={inv} sta={sta} locInv={locInv} />
          <BodyInv
            data={chunk ?? []}
            isFullPage={!isFirst ? true : false}
            isLast={isLast}
          />
        </Grid>
        <Grid xs={12} item style={{ maxHeight: "5.5cm", minHeight: "5.5cm" }}>
        <FooterD found={found} inv={inv} data={sta ?? {}} />
 
        </Grid>
      </Fragment>
    </Grid>
  );
}
function BillContainer(props) {
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};
  const [found, setFound] = useState({});
  const [sta, setSta] = useState({});
  const [inv, setInv] = useState({});
  const [loading, setLoading] = useState(true);
  const [chunks, setChunks] = useState([]);

  let count_id = 0;
  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      GetFoundation: true,
      Rpt_Inv: true,
      wait: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid:sto.branchi,
        iFoundid: 1,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
      },
      autos: {},
    };
    console.log(tb);
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setSta({ info: res.data.RptInv, tot: res.data.infoBill });
        console.log("res.data.RptInv");
        // console.log();
        // setInfo(res.data);
        setChunks(_.chunk(res.data.RptInv, 12));
        setFound(res.data.Found);
      })
      .catch((error) => {
        console.log("catch");
      });
  };
  const getInv = async () => {
    axios
      .get(uri + `InvForFatora?proc_id=${locInv.proc_id}`)
      .then((res) => {
        console.log("the inve for fatoora", res.data);
        setInv(res.data);
      })
      .catch((error) => { });
  };
  useEffect(() => {
    setTimeout(async () => {
      await getInfo();
      await getInv();
      setLoading(false);
      // onUnmountComplete();
    }, 0);
  }, []);

  if (!found || !sta || loading) return <LoadingPage />;

  // return (
  //   <Fragment>
  //     {chunks.map((page, i) => {
  //       return <BillPage {...{ locInv, found, sta, inv, chunk: page}} />;
  //     })}
  //   </Fragment>


  // );

  return (
    <Typography component="div" style={stElemnt.AF.Reviws.ReviwsJard}>

      {/* <Typography component="div" style={{ ...stElemnt.AF.Reviws.pdf_page }}> */}
      <Typography component="div" >
        {chunks.map((page, i) => {
          return (
            <BillPage
              {...{
                locInv,
                found,
                sta,
                inv,
                chunk: page,
                isFirst: i == 0,
                isLast: i == chunks.length - 1,
              }}
            />
          );
        })}
      </Typography>
    </Typography>
  );
}
export default BillContainer;
