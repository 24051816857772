import { makeStyles, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import { insh } from '../../Context/InshSt';


 // ======== b Rout
// up
const useStylesUp = makeStyles((theme) => ({

  rootup:{
    // border:'solid 0.1px',
    // marginTop:'-1rem',
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(0.5),
    },
  },
  upsub:{
    // border:'solid 0.1px red',
    borderRadius:5,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
       marginLeft:'0rem',marginRight:'0rem'
    },
  },
  upsubR:{
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
    0px 0px 1.5px 0.5px ${insh.colors.secndry}`,

    alignItems: 'center',
    [theme.breakpoints.only('lg')]: {
      padding:'3rem 1rem'
    },
    [theme.breakpoints.only('md')]: {
      padding:'4rem 0.1rem'
    },
  },
  upsubL:{

    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
    0px 0px 1.5px 0.5px ${insh.colors.secndry}`,

    padding: theme.spacing(2),
    // border:'solid 0.1px green',
    // padding: theme.spacing(1),
    // marginTop: theme.spacing(3),
    // border:'solid 0.1px',
      //  direction:"rtl",

      //  borderRadius:5,
      //  margin:'0.5% 0.5% 1% 0.5%',
      //  backgroundColor: '#fff',
      //  width:'99%',
      //  border:'solid'
    // style={{borderRadius:7, margin:'1% 0% 1% 1.5%', backgroundColor: '#fff', width:'97%'}}
  },
 
  rootdown:{
    // border:'solid 0.1px',
    // marginTop:'-1rem',
    [theme.breakpoints.only('lg')]: {
      // padding: theme.spacing(2),
    },
    [theme.breakpoints.only('md')]: {
      // padding: theme.spacing(0.5),
    },
    [theme.breakpoints.only('sm')]: {
      marginBottom:'3.5rem',
      // padding: theme.spacing(0.5),
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom:'3.5rem',
      // padding: theme.spacing(0.5),
    },
  },

  downsub:{
    borderRadius:5,
    margin: theme.spacing(1),
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1), 
      marginLeft:'0rem',marginRight:'0rem',
    },
    // border:'solid 0.1px',
  },
  downsubR:{
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
    0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    // alignItems: 'center',
    // [theme.breakpoints.only('lg')]: {
    //   // padding:'5rem 3rem 5rem 3rem'
    // },
    // [theme.breakpoints.only('md')]: {
    //   // padding:'4rem 0.1rem 4rem 0.1rem'
    // },
      [theme.breakpoints.only('xs')]: {
        // paddingLeft:'0rem',
        // paddingRight:'0rem',
         
       },
  },
  downsubL:{
    // padding: theme.spacing(1),
    // marginTop: theme.spacing(3),
    // border:'solid 0.1px',
      //  direction:"rtl",

      //  borderRadius:5,
      //  margin:'0.5% 0.5% 1% 0.5%',
      //  backgroundColor: '#fff',
      //  width:'99%',
      //  border:'solid'
    // style={{borderRadius:7, margin:'1% 0% 1% 1.5%', backgroundColor: '#fff', width:'97%'}}
  },

  appBarup: {
    // position: 'relative',
    top: 'auto',
    bottom: 0,
  },
  titleup: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const useStylesHed = makeStyles((theme) => ({
  rootHd:{
    display: 'flex',
    textAlign:'center',
  },
  HeaderHd:{
    textAlign:'center',marginBottom: '0%',
  },
  ChipHdSale:{ color: '#fff', backgroundColor: '#319835', borderRadius: '0px 0px 10px 10px', },
  ChipHdPurch:{ color: '#008080', backgroundColor: '#acdada', borderRadius: '0px 0px 10px 10px', },
  ChipHdSafe:{ color: '#000080', backgroundColor: '#b1b1da', borderRadius: '0px 0px 10px 10px', },

  ChipHdRet:{ color: '#ff3e3b', backgroundColor: '#fbd2d0', borderRadius: '0px 0px 10px 10px', },

  ckBoxHd:{ marginTop: -7, },

  [theme.breakpoints.down('xs')]: {
    ckBoxHd:{ color:'#fff',  
    marginRight: 0,
    marginTop: -7,
    '& .MuiTypography-body1': {
    fontSize: '0.7rem',
    marginLeft: '-10px',
    }, },
},

}));

const useStylesAuto = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      // transform: "translate(2px, 2px) scale(1);",
      // transform: "translate(34px, 20px) scale(1);",
      color:'green', fontSize:'0.8rem',
      // fontSize:12,
    }
  },
  inputRoot: {
    color: "green", // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      /* Default left padding is 6px*/ paddingLeft: 0, paddingRight: 0,
      // marginLeft:-5, marginRight:-5,
      textAlign:'center !important', marginLeft: -4, marginRight: -14,
      fontSize: '0.8rem',fontWeight:"bold",
    }, 
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "green" },
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "green" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "green" },
  },
  popupIndicator:{ color: "green", padding: 0, marginRight: -6, top: 3, fontSize: '1rem' },
  option: { "&:hover": {
      backgroundColor: "#b1deb199 !important",
      color:"green",
    },
    color:'#005a01',
    justifyContent: "center",
    fontSize:'0.8rem',
    paddingLeft: 0, paddingRight: 0,
    marginLeft: 0, marginRight: 0,
  },
}));
const useStylesAutoPurch = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color:'black', fontSize:'0.8rem',
    }
  },
  inputRoot: {
    color: "black",
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
    paddingLeft: 0, paddingRight: 0,
      textAlign:'center !important', marginLeft: -4, marginRight: -14,
      fontSize: '0.8rem',fontWeight:"bold"
    }, 
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "blue" },
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "blue" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "blue" },
  },
  popupIndicator:{ color: "blue", padding: 0, marginRight: -6, top: 3, fontSize: '1rem' },
  option: { "&:hover": {
      backgroundColor: "fff !important",
      color:"black",
    },
    color:'black',
    justifyContent: "center",
    fontSize:'0.8rem',
    paddingLeft: 0, paddingRight: 0,
    marginLeft: 0, marginRight: 0,
  },
}));
const useStylesAutoSafe = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color:'black', fontSize:'0.8rem',
    }
  },
  inputRoot: {
    color: "black",
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
    paddingLeft: 0, paddingRight: 0,
      textAlign:'center !important', marginLeft: -4, marginRight: -14,
      fontSize: '0.8rem',fontWeight:"bold",
    }, 
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "navy" },
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "navy" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "navy" },
  },
  popupIndicator:{ color: "navy", padding: 0, marginRight: -6, top: 3, fontSize: '1rem' },
  option: { "&:hover": {
      backgroundColor: "fff !important",
      color:"black",
    },
    color:'black',
    justifyContent: "center",
    fontSize:'0.8rem',
    paddingLeft: 0, paddingRight: 0,
    marginLeft: 0, marginRight: 0,
  },
}));
const useStylesAutoBlack = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color:'black', fontSize:'0.8rem',
    }
  },
  inputRoot: {
    color: "black",
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
    paddingLeft: 0, paddingRight: 0,
      textAlign:'center !important', marginLeft: -4, marginRight: -14,
      fontSize: '0.8rem',fontWeight:"bold",
    }, 
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
  },
  popupIndicator:{ color: "black", padding: 0, marginRight: -6, top: 3, fontSize: '1rem' },
  option: { "&:hover": {
      backgroundColor: "fff !important",
      color:"black",
    },
    color:'black',
    justifyContent: "center",
    fontSize:'0.8rem',
    paddingLeft: 0, paddingRight: 0,
    marginLeft: 0, marginRight: 0,
  },
}));

const useStylesTF = makeStyles((theme) => ({
  rootSale: { "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color:'green', fontSize:'0.8rem', },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": { color:'green', fontSize:'0.8rem',
     lineHeight: '0.6rem',}, "& .MuiOutlinedInput-inputMarginDense": 
    { paddingLeft:4,paddingRight:4, paddingTop:8,paddingBottom:7, textAlign:'center', fontSize: '1rem' }},

    rootPurch: { "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color:'blue', fontSize:'0.8rem', },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": { color:'blue', fontSize:'0.7rem',
     lineHeight: '0.5rem',}, "& .MuiOutlinedInput-inputMarginDense": 
    { paddingLeft:4,paddingRight:4, paddingTop:8,paddingBottom:7, textAlign:'center', fontSize: '1rem' }},

    rootSafe: { "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color:'navy', fontSize:'0.8rem', },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": { color:'navy', fontSize:'0.8rem',
     lineHeight: '0.5rem',}, "& .MuiOutlinedInput-inputMarginDense": 
    { paddingLeft:4,paddingRight:4, paddingTop:8,paddingBottom:7, textAlign:'center', fontSize: '1rem' }},
    
    rootCust: { "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color:'black', fontSize:'0.8rem', },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": { color:'black', fontSize:'0.7rem',
     lineHeight: '0.5rem',}, "& .MuiOutlinedInput-inputMarginDense": 
    { paddingLeft:4,paddingRight:4, paddingTop:8,paddingBottom:7, textAlign:'center', fontSize: '1rem' }},

    rootRet: { "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color:'red', fontSize:'0.8rem', },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": { color:'red', fontSize:'0.7rem',
     lineHeight: '0.5rem',}, "& .MuiOutlinedInput-inputMarginDense": 
    { paddingLeft:4,paddingRight:4, paddingTop:8,paddingBottom:7, textAlign:'center', fontSize: '1rem' }},


  inputSale: {
    color: "green", 
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "green" },
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "green" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "green" },
  //   '& input[type=number]': { '-moz-appearance': 'textfield' },
  '& input[type=number]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0 },
  '& input[type=number]::-webkit-inner-spin-button': { '-webkit-appearance': 'none',  margin: 0}
  },

  inputPurch: {
    color: "#007878", 
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#007878" },
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#007878" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#007878" },
  //   '& input[type=number]': { '-moz-appearance': 'textfield' },
  '& input[type=number]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0 },
  '& input[type=number]::-webkit-inner-spin-button': { '-webkit-appearance': 'none',  margin: 0}
  },

  inputSafe: {
    color: "navy", 
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "navy" },
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "navy" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "navy" },
  //   '& input[type=number]': { '-moz-appearance': 'textfield' },
  '& input[type=number]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0 },
  '& input[type=number]::-webkit-inner-spin-button': { '-webkit-appearance': 'none',  margin: 0}
  },

  inputRet: {
    color: "red", 
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "red" },
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "red" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "red" },
  //   '& input[type=number]': { '-moz-appearance': 'textfield' },
  '& input[type=number]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0 },
  '& input[type=number]::-webkit-inner-spin-button': { '-webkit-appearance': 'none',  margin: 0}
  },
}));



const useStylesTD = makeStyles((theme) => ({

  root: {
    "& ::-webkit-calendar-picker-indicator": {
color:'red'
    }
  },

  input: {
    '&   input[type="date"]::-webkit-calendar-picker-indicator': {
       color: 'red',
      // opacity: 1,
      // display: 'block',
     // background: ' no-repeat',
      // width: '20px',
      // height: '20px',
      // borderWidth: 'thin',
      // border:'solid 1px red',
      color:'green', padding:0, margin:0
  },
      
  '& input[type=date]' :{
    color: 'green'
},

}

//   '& input[type="date"]::-webkit-calendar-picker-indicator': {
//     '-webkit-appearance': 'none',
//     display: 'none',
//     border:'slid'
// },

}));


const useStylesBA = makeStyles((theme) => ({

/* Rpt */
continarAccRpt:{ backgroundColor:'#fff', },

/* Rev */
continarAccRev:{ backgroundColor:'#fff', },

}));


const useStylesLbl = makeStyles((theme) => ({
  clrSale:{ color:'green',fontWeight:"bold",fontSize:"0.9rem"},
  clrPurch:{ color:'#007878',fontWeight:"bold",fontSize:"0.9rem"},
  clrSafe:{ color:'navy',fontWeight:"bold",fontSize:"0.9rem"},
  clrRet:{ color:'red',fontWeight:"bold",fontSize:"0.9rem"},
  clrBlack:{ color:'black',fontWeight:"bold",fontSize:"0.9rem"},
  clrBlackTxt:{ color:'black',fontWeight:"bold",fontSize:"1rem"},
  clrBlackLblS:{ color:'black',fontWeight:"bold",fontSize:"0.9rem",},
  clrBlackLblSal:{ color:'green',fontWeight:"bold",fontSize:"0.9rem"},
  clrBlackLbl:{ color:'black',fontWeight:"bold",fontSize:"0.8rem"},
  inputAuot:{ color:'black'},

  
  /* for SWich */
  colrSw:{ marginRight: '-29px',fontSize:'0.6rem'},

  // [theme.breakpoints.up('lg')]: {
  // },

  [theme.breakpoints.only('md')]: {
    colrSw:{ cofontSize:'0.6rem'},

    clrPurchSw:{ color:'#007878',marginRight: '-29px'},
    clrSafeSw:{ color:'navy',marginRight: '-29px'},
    clrRetSw:{ color:'red',marginRight: '-29px'},
  },

  [theme.breakpoints.down('sm')]: {
},
[theme.breakpoints.down('xs')]: {
},

  colrSw:{color:'#fff', fontSize:'0.6rem'},
  clrPurchSw:{ color:'#007878',},
  clrSafeSw:{ color:'navy',},
  clrRetSw:{ color:'red',},

   bakClrSal:{ 
    boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
     background:'#4fa152',
   '&:hover':{ background:'#0080009c',
    boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
   }
  },
  
  bakClrPurch:{ 
    boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
     background:'#388d8d',
   '&:hover':{ background:'#067474',
    boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
   }
  },

  bakClrSafe:{ 
    boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
     background:'#3f51b5',
   '&:hover':{ background:'#161686',
    boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
   }
  },

  bakClrRet:{ 
    boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
     background:'#ef5350',
   '&:hover':{ background:'#ff3a36',
    boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
   }
  },
}));

const SaleSwitch = withStyles({
  root: {},
  switchBase: { color: 'gray', '&$checked': { color: '#fff', },
  '&$checked + $track': { backgroundColor: '#fff', }, },
  checked: {}, track: {}, })(Switch);

  const SaleCheckbox = withStyles({
    root: { color:'#fff', '&$checked': { color: '#fff', }, },
    label:{ color:'red' },
    checked: {},
  })((props) => <Checkbox {...props} />);
  

  const useStylesDw = makeStyles((theme) => ({
    rootdw:{
      padding: theme.spacing(0.5),
         direction:"rtl",
    },
    appBardw: {
      // position: 'relative',
      top: 'auto', bottom: 0,
    },
    titledw: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    containerDatedw: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textFieldDatedw: {
      // border:'solid',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      paddingRight: theme.spacing(0),
      // width: 200,
    }
  }));

const useStylesUiElemment = makeStyles((theme) => ({

  rootchip:{

  },
chipS:{
    minWidth:'100%',border: '0.1rem solid #fff',
    [theme.breakpoints.only('lg')]: {
      padding: '1.8rem 0rem', fontSize:12, borderRadius:'0.5rem',
    },
    [theme.breakpoints.only('md')]: {
      padding: '1.6rem 0rem', fontSize:11, borderRadius:'0.4rem',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '1.5rem 0rem', fontSize:10, borderRadius:'0.3rem',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '1.3rem 0rem', fontSize:9, borderRadius:'0.2rem',
    },
  },
  chipT:{
    minWidth:'100%',border: '0.1rem solid #fff',
    [theme.breakpoints.only('lg')]: {
      padding: '1.8rem 0rem', fontSize:14, borderRadius:'0.5rem',
    },
    [theme.breakpoints.only('md')]: {
      padding: '1.6rem 0rem', fontSize:13, borderRadius:'0.4rem',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '1.5rem 0rem', fontSize:12, borderRadius:'0.3rem',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '1.3rem 0rem', fontSize:11, borderRadius:'0.2rem',
    },
  },

  chipV:{
      minWidth:'100%', border: '0.1rem solid #fff',
    [theme.breakpoints.only('lg')]: {
      padding:'1.2rem 0rem',  fontSize:15, borderRadius:'0.4rem',
      //  margin:4
    },
    [theme.breakpoints.only('md')]: {
      padding:'1.2rem 0rem', fontSize:12, borderRadius:'0.3rem',
    },
    [theme.breakpoints.only('sm')]: {
      padding:'1.3rem 0rem', fontSize:17, borderRadius:'0.2rem',
    },
    [theme.breakpoints.only('xs')]: {
      padding:'1.1rem 0rem', 
       fontSize:15, borderRadius:'0.1rem',
    },
  },
  chipVR:{
    [theme.breakpoints.only('xs')]: {
      fontSize:12,
    },
  },
  contbnt:{
    justifyContent: 'center',
  },
 button: {
  // margin: theme.spacing(1),
  color: '#fff',
  backgroundColor: '#008081',
  boxShadow:'none',
  direction: 'initial',
  padding: '8px 12px'
 },
 buttonAcc: {
  // margin: theme.spacing(1),
  color: '#fff',
  backgroundColor: '#616161',
  boxShadow:'none',
  direction: 'initial',
  padding: '8px 12px'
 },
}));


const useStylesSpDial = makeStyles((theme) => ({
  rootsd: {
    // flexGrow: 1,
    position: 'relative',
  },
  speedDialsd: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
  upersal:{
    background:'red'
  },
  ico:{
    background: '#fff',
    width: '100%',
    height: '100%',
    borderRadius: 50,
    padding: 7,
    color:'#fff',
    background:insh.colors.secndry,
  },
  clPri:{background:insh.colors.primary},
  clSec:{background:insh.colors.secndry},
  clsal:{color:insh.colors.sal},
  clpur:{color:insh.colors.pur},
  clsaf:{color:insh.colors.saf},
  clacc:{color:insh.colors.acc},
  clcan:{color:'#fff',background:insh.colors.cancl},
}));

 // =========================================== e Rout


export { useStylesTF,useStylesTD, useStylesAuto,useStylesUp,useStylesHed, useStylesBA,useStylesDw,
  useStylesLbl,SaleSwitch, SaleCheckbox,useStylesUiElemment,useStylesSpDial,useStylesAutoPurch,useStylesAutoSafe,useStylesAutoBlack
}
//====================================================== end menu btn
 

