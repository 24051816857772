import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { sto } from "../../../../../Context/InshData";
import Print from "@material-ui/icons/Print";
import { reFormatDate } from "../../../../../utils/utils";
import { uri } from "../../../../../help/Api";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

export default function RetrictionsSearchTable(props) {
    const { data,onRowDataClick,rptParams} = props;

    const stylH = {
        border: "solid 0.6px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 15,
        color: "#fff"
    }
    const stylRo = {
        BorderBottom: "solid 0.5px",
        //borderStyle: "solid",
        borderColor: "#000",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 14,
        color: "#000"
    }

    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    const onRowClicks = async(row) => {
        await axios
        .get(
          uri+
            `AccountReports/RestrictionSubSearch?branch=${row?.branch}
    &&resId=${row?.id}`
        )
        .then((res) => {
          if (res.data) {
            onRowDataClick({...row},res?.data?.restSubData);
          } else {
            onRowDataClick({...row},res?.data?.restSubData);
          }
        
        })
        .catch((err) => {
            console.log(err);
        });
      };
    return (
        <TableContainer component={Paper} >
            <Table
                //className={classes.table} 
                size="small" aria-label="a dense table" >
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor:"#808080",
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                            border: "solid 0.6px"
                        }}
                    >
                        <TableCell style={stylH} align="center">
                            {"رقم الفيد"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"التاريخ"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"اجمالي المدين"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"اجمالي الدائن"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"حالة القيد"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"مركز التكلفة"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"البيان"}
                        </TableCell>
                        
                      
                               
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                border: "solid 0.6px"
                            }}
                           >

                            <TableCell style={stylRo} align="center" onClick={() => { onRowClicks(row); }} >{row?.doc_no2}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClicks(row); }} >{row?.date}</TableCell>

                            <TableCell style={stylRo} align="center" onClick={() => { onRowClicks(row); }} > {row?.total_dept}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClicks(row); }} > {row?.total_credit}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClicks(row); }} > {row?.state==1?"معتمد":"غير معتمد"}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClicks(row); }} > {row?.centerName}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClicks(row); }} > {row?.notes}</TableCell>


                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
