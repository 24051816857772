import React, { useState, useEffect } from "react";
import {
    Button,
    Chip,
    colors,
    Container,
    Grid,
    Hidden,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";

import TotalCard from "../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
    useStylesHed,
    useStylesSpDial,
    useStylesUiElemment,
} from "../../../St/comps/UseStyle";
import { insh as inshst } from "../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import { deptTypes } from "../balanceCustRPT/balanceCustRPT-services";
import { URL } from "../../../api/customer";
import DeptPeriodFilter from "./DeptPeriodFilter";
import DeptPeriodForm from "./DeptPeriodForm";
import DeptPeriodTable from "./DeptPeriodTable";

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#faf1f1",
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
    },
  
    headerTitle: {
      backgroundColor: colors.red[30],
      textAlign: "center",
      fontSize: 20,
      width: "100%",
      fontWeight:"bold"
    },
  
    blCard: {
      backgroundColor: "#fff",
      borderColor: "#81007f",
    },
    root3: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: colors.red[300],
      direction: "rtl",
      marginTop: 5,
      padding: 5,
      // boxShadow:'box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
      boxShadow:
        "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;",
    },
  }));
  

export default function DeptPeriod(props) {
    const { AutoOptions } = props;
    const SearchActivityReports = {
        dateFrom: moment(new Date()).format('YYYY-MM-DD'),
        timeFrom: moment(new Date()).startOf("day").format("HH:mm"),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
        timeTo: moment(new Date()).endOf("day").format("HH:mm"),
        type: deptTypes[0],
        branch: { id: -1, name: "الكل" },
        cust: { id: -1, name: "الكل" },
        act: { id: -1, name: 'الكل'},
        actsup: { id: -1, name: 'الكل'},
        firstPeriod:'30',
        secondPeriod:'60',
        thirdPeriod:'90',
        fourthPeriod:'120',
        sup: {id: -1, name:'الكل'},
        user: {id: -1, name:'الكل'}
    };

    //const [rptParams, setRptParams] = React.useState(SearchActivityReports);
    let from_date=moment(new Date()).subtract().format("YYYY")+"-01-01";
    const [rptParams, setRptParams] = React.useState({
      ...SearchActivityReports,
      dateFrom:from_date,
    });
    const classes = useStyles();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    const { t } = useTranslation(["forms", "translation"]);
    const [style, setStyle] = useState(inshst.custm);

    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [sum, setSum] = useState(false);
    const viewHandle = async () => {
        if (rptParams.type.id==1){
            setData([]);
            setFetching(true);
            await axios
                .get(
                    URL +
                    `/DeptPeriod?fDate=${rptParams.dateFrom}
            &&tDate=${rptParams.dateTo}
        &&firstPeriod=${rptParams?.firstPeriod}
        &&secondPeriod=${rptParams?.secondPeriod}
        &&thirdPeriod=${rptParams?.thirdPeriod}
        &&fourthPeriod=${rptParams?.fourthPeriod}
        &&branch=${rptParams.branch?.id}
        &&cust_id=${rptParams.cust?.id}
        &&act_id=${rptParams.act?.id}
        &&user=${rptParams?.user?.id}`
                )
                .then((res) => {
                    setData(res.data);
                    console.log(" the data is", res.data);
                    setFetching(false);
                })
                .catch((err) => {
                    setFetching(false);
                    console.log(err);
                    // toast.err("يوجد خطأ في الجلب");
                    setData([]);
                });
        }else{
            setData([]);
            setFetching(true);
            await axios
                .get(
                    URL +
                    `/CreditPeriod?fDate=${rptParams.dateFrom}
            &&tDate=${rptParams.dateTo}
        &&firstPeriod=${rptParams?.firstPeriod}
        &&secondPeriod=${rptParams?.secondPeriod}
        &&thirdPeriod=${rptParams?.thirdPeriod}
        &&fourthPeriod=${rptParams?.fourthPeriod}
        &&branch=${rptParams.branch?.id}
        &&cust_id=${rptParams.sup?.id}
        &&act_id=${rptParams.actsup?.id}
        &&user=${rptParams?.user?.id}`
                )
                .then((res) => {
                    setData(res.data);
                    console.log(" the data is", res.data);
                    setFetching(false);
                })
                .catch((err) => {
                    setFetching(false);
                    console.log(err);
                    // toast.err("يوجد خطأ في الجلب");
                    setData([]);
                });
        }
   
    };

    const his = useHistory();
    const printHandle = () => {

        if (data.length == 0) {
            alert("لاتوجد بيانات");
        } else {
            const info = {
                data: data?.agelPeriod,
                tot: data?.totals,
                from: "النشاط التجاري",
                title:rptParams.type.id==1?"تقرير اعمار ديون العملاء":"تقرير اعمار ديون الموردين",
                fDate:  rptParams.dateFrom,
                tDate:  rptParams.dateFrom,
                nDate: moment(new Date()).format("YYYY-MM-DD"),
                branch: rptParams.branch.name,
                cust:rptParams.type.id==1? rptParams.cust.name:rptParams.sup.name,
                act:rptParams.type.id==1? rptParams.act.name:rptParams.actsup.name,
                per1:rptParams.firstPeriod,
                per2:rptParams.secondPeriod,
                per3:rptParams.thirdPeriod,
                per4:rptParams.fourthPeriod,
                col:rptParams.type.id==2 ? "المورد" : "العميل"
            };
            localStorage.setItem("iInfos", JSON.stringify(info));
            his.push({ pathname: "/ReviewAgelPeriod" });
            window.location.reload(true);
        }
    };

    let excel_name ="AgelPeriodReport";


      const excelHeaders = [
        { label:rptParams.type.id==2 ? "رقم المورد" : "رقم العميل", key: "cust_ID" },
        { label: rptParams.type.id==2 ? "اسم المورد" : "اسم العميل", key: "Customer_Name" },
        { label: "إجمالي الرصيد", key: "totalMony" },
        { label: "الفترة الأولى", key: "FirstPeriod" },
        { label:"الفترة الثانية", key: "SecondPeriod" },
        { label: "الفترة الثالثة", key: "ThirdPeriod" },
        { label: "الفترة الرابعة", key: "FourthPeriod" },
        { label: "الفترة الخامسة", key: "FivethPeriod" },
      ];

    const onChangeData = (propName) => (event) => {
        setRptParams({ ...rptParams, [propName]: event.target.value });
    };
    const onChangeAutoData = (propName, newValue) => {
        setRptParams({ ...rptParams, [propName]: newValue });
    };
    return (
        <div className={classes.root}>
            <Grid container item xs={12}>
                <Typography
                    size="small"
                    className={classes.headerTitle}
                    color="primary"
                >
                    {"تقرير أعمار الديون"}
                </Typography>
            </Grid>
            <Typography
                component="div"
                style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
            >
                <Container
                    component={Paper}
                    maxWidth={false}
                    style={{
                        padding: "10px",
                        paddingBottom: "0px",
                        borderBottomColor: inshst.colors.cust,
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                    }}
                >
                   <DeptPeriodFilter {...{rptParams, setRptParams}}/>

                    <DeptPeriodForm
                        {...{
                            AutoOptions,
                            rptParams,
                            onChangeData,
                            onChangeAutoData,
                        }}
                    />
                    <Grid
                        container
                        justifyContent="center"
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        spacing={1}
                    >
                        <Hidden smUp={["sm"]}>
                            <Grid item>
                                <Button
                                    disabled={fetching}
                                    variant="contained"
                                    startIcon={<ScannerIcon />}
                                    size="large"
                                    style={{ ...style.iconBtn, borderRadius: 0 }}
                                    className={[clasui.button]}
                                    onClick={() => viewHandle()}
                                >
                                    {t("forms:show")}
                                </Button>
                            </Grid>
                        </Hidden>
                        <Hidden only={["xs"]}>
                            <Grid item>
                                <Button
                                    disabled={fetching}
                                    variant="contained"
                                    startIcon={<ScannerIcon />}
                                    size="large"
                                    style={{ ...style.iconBtn, borderRadius: 0 }}
                                    className={[clasui.button]}
                                    onClick={() => viewHandle()}
                                >
                                    {t("forms:view")}
                                </Button>
                            </Grid>
                        </Hidden>

                        <Hidden smUp={["sm"]}>
                            <Grid item >
                                {data && !fetching && data.agelPeriod && (
                                    <CSVLink
                                        separator={";"}
                                        filename={excel_name}
                                        data={data.agelPeriod}
                                        headers={excelHeaders}
                                    >
                                        {" "}
                                        <Button
                                            disabled={fetching}
                                            variant="contained"
                                            startIcon={<TableChart />}
                                            size="large"
                                            color="primary"
                                            style={{ ...style.iconBtn, borderRadius: 0 }}
                                            className={[clasui.button]}
                                        >
                                            {"اكسل"}
                                        </Button>
                                    </CSVLink>
                                )}
                            </Grid>
                        </Hidden>
                        <Hidden only={["xs"]}>
                            <Grid item >
                                {data && !fetching && data.agelPeriod && (
                                    <CSVLink
                                        separator={";"}
                                        filename={excel_name}
                                        data={data.agelPeriod}
                                        headers={excelHeaders}
                                    >
                                        {" "}
                                        <Button
                                            disabled={fetching}
                                            variant="contained"
                                            startIcon={<TableChart />}
                                            size="large"
                                            color="primary"
                                            style={{ ...style.iconBtn, borderRadius: 0 }}
                                            className={[clasui.button]}
                                        >
                                            {"تصدير اكسل"}{" "}
                                        </Button>
                                    </CSVLink>
                                )}
                            </Grid>
                        </Hidden>
                        <Grid item>
                            <Button
                                disabled={fetching}
                                variant="contained"
                                startIcon={<PrintIcon />}
                                color="primary"
                                size="large"
                                style={{ ...style.iconBtn, borderRadius: 0 }}
                                className={[clasui.button]}
                                onClick={printHandle}
                            >
                                {t("forms:print")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                startIcon={<KeyboardReturnSharpIcon />}
                                color="secondary"
                                size="large"
                                style={{ ...style.iconBtn, borderRadius: 0 }}
                                className={[clasui.button]}
                                onClick={() => his.goBack()}
                            >
                                {t("forms:exit")}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth={true} style={{ padding: "4px" }}>
                    <Grid container spacing={1} style={{ paddingTop: "10px" }}>
                       
                        <Grid item lg={2} md={2} sm={4} xs={6}>
                            <TotalCard
                                {...{
                                    className: classes.blCard,
                                    title:"إجمالي الفترة الأولى",
                                    value: data?.totals?.fi_Period.toFixed(2),
                                }}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={4} xs={6}>
                            <TotalCard
                                {...{
                                    className: classes.blCard,
                                    title:"إجمالي الفترة الثانية",
                                    value: data?.totals?.se_Period.toFixed(2),
                                }}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={4} xs={6}>
                            <TotalCard
                                {...{
                                    className: classes.blCard,
                                    title: "إجمالي الفترة الثالثة",
                                    value: data?.totals?.th_Period.toFixed(2),
                                }}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={4} xs={6}>
                            <TotalCard
                                {...{
                                    className:classes.blCard,
                                    title: "إجمالي الفترة الرابعة",
                                    value: data?.totals?.fo_Period.toFixed(2),
                                }}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={4} xs={6}>
                            <TotalCard
                                {...{
                                    className: classes.blCard,
                                    title: "إجمالي الفترة الخامسة",
                                    value: data?.totals?.fiv_Period.toFixed(2),
                                }}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={4} xs={6}>
                            <TotalCard
                                {...{
                                    className: classes.blCard,
                                    title: "إجمالي الرصيد",
                                    value: data?.totals?.tot_Period.toFixed(2),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <br />
                <Container
                    component={Paper}
                    maxWidth={false}
                    style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        
                    }}
                >
                    <Grid container justifyContent="center">
                        <Grid item container justifyContent="center" xs={12}>
                            <BeatLoader loading={fetching} color="#81007f" />
                            {!fetching && (
                                <DeptPeriodTable
                                    {...{
                                        data: data?.agelPeriod ? data.agelPeriod : [],
                                        rptParams,
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </Typography>
        </div>
    );
}
