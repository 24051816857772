import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";
import { stElemnt } from "../../../St/Reviews/cStyle";
import LoadingPage from "../../../services/LoadingPage";
import * as _ from "lodash";
import { Grid, Typography } from "@material-ui/core";
import useChunkMyData from "../../../components/Hooks/useChunkMyData";
import { sto } from "../../../Context/InshData";
import HeaderDev from "./A4/HeadDev";
import BodyDev from "./A4/BodyDev";
import FooterDev from "./A4/FooterDev";

function BillPage(props) {
  const { locInv, found, sta, inv, chunk , isFirst , isLast, sum,foundData} = props;

  return (
    <Grid item container style={{ ...stElemnt.AF.Reviws.pdf_page}}>
    <Fragment>

      <HeaderDev found={found} sta={sta} />
       <BodyDev
        data={chunk ?? []}
        isFullPage={!isFirst ? true : false}
        isLast={isLast}
        sum={sum}
      />
      <FooterDev found={found} InvForFatora={inv} data={sta ?? {}} />

    </Fragment>
    </Grid>
  );
}
function DeliveryNotesCon(props) {
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};
  const [found, setFound] = useState({});
  const [sta, setSta] = useState({});
  const [inv, setInv] = useState({});
  const [foundData, setFoundData] = useState({});
  const [loading, setLoading] = useState(true);
  const [chunks, setChunks] = useState([]);

  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      GetFoundation: true,
      Rpt_Inv: true,
      wait: true,
      stor:sto,
      empFound:true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: locInv.branch,
        iFoundid: 1,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
      },
      autos: {},
    };
    console.log(tb);
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setSta({ info: res.data.RptInv, tot: res.data.infoBill });
        console.log("res.data.RptInv");
        // console.log();
        setChunks(_.chunk(res.data.RptInv, 11));
        setFoundData(res.data.FoundData);
        setFound(res.data.Found);
      })
      .catch((error) => {
        console.log("catch");
      });
  };
  const getInv = async () => {
    axios
      .get(uri + `InvForFatora?proc_id=${locInv.proc_id}`)
      .then((res) => {
        console.log("the inve for fatoora", res.data);
        setInv(res.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    setTimeout(async () => {
      await getInfo();
      await getInv();
      setLoading(false);
      // onUnmountComplete();
    }, 0);
  }, []);

  if (!found || !sta || loading) return <LoadingPage />;

return (
    <Typography component="div" style={stElemnt.AF.Reviws.ReviwsJard}>
      
      {/* <Typography component="div" style={{ ...stElemnt.AF.Reviws.pdf_page }}> */}
      <Typography component="div" >
        {chunks.map((page, i) => {
          return (
            <BillPage
              {...{
                locInv,
                found,
                sta, 
               inv, 
                chunk: page,
                isFirst: i == 0,
                isLast: i == chunks.length - 1,
                sum:i+1,
                foundData,

              }}
            />
          );
        })}
      </Typography>
    </Typography>
  );
}
export default DeliveryNotesCon;
