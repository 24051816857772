import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../../services/LoadingPage";
import RetrictionsSearchMain from "./RetrictionsSearch";
const defaultAutos = {
  Branches: [],
};

export default function SearchReaceiptContainer(props) {
  const {
    openSearchMdl,  handCloseDw,
    onRowDataClick ,type,AutoOptions, transition} = props;

  return (

    <RetrictionsSearchMain
      {...{
        openSearchMdl,  handCloseDw,
        onRowDataClick ,type,AutoOptions,transition
          }}
    />
  );
}
