import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";

import Header from "./Header";
import LoadingPage from "../../../services/LoadingPage";
import Body from "./Body";
import Footer from "./Footer";
import * as _ from "lodash";
import { sto } from "../../../Context/InshData";

function BillPage(props) {
  const { locInv, found, sta, inv, chunk } = props;
  return (
    <Fragment>
      <Header locInv={locInv} found={found} sta={sta} />
      <Body data={chunk ?? []} />
      <Footer found={found} InvForFatora={inv} data={sta ?? {}} />
    </Fragment>
  );
}
function BillContainer(props) {
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};

  const [info, setInfo] = useState({});
  const [found, setFound] = useState({});
  const [sta, setSta] = useState({});
  const [inv, setInv] = useState({});
  const [loading, setLoading] = useState(true);
  const [chunks, setChunks] = useState([]);
  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      GetFoundation: true,
      Rpt_Inv: true,
      wait: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: sto.branchi,
        iFoundid: 1,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
      },
      autos: {},
    };
    console.log(tb);
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setSta({ info: res.data.RptInv, tot: res.data.infoBill });
        console.log("res.data.RptInv");
        console.log(res.data);
        // console.log();
        // setInfo(res.data);
        setChunks(_.chunk(res.data.RptInv, 8));
        setFound(res.data.Found);
      })
      .catch((error) => {
        console.log("catch");
      });
  };
  const getInv = async () => {
    axios
      .get(uri + `InvForFatora?proc_id=${locInv.proc_id}`)
      .then((res) => {
        console.log("the inve for fatoora", res.data);
        setInv(res.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    setTimeout(async () => {
      await getInfo();
      await getInv();
      setLoading(false);
      // onUnmountComplete();
    }, 0);
  }, []);

  if (!found || !sta || loading) return <LoadingPage />;

  return (
    <Fragment>
      {chunks.map((page, i) => {
        return <BillPage {...{ locInv, found, sta, inv, chunk: page }} />;
      })}
    </Fragment>
  );
}
export default BillContainer;
