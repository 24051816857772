import React  from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
// Ust
import { useInvAF } from "../../../../St/Reviews/UseStyle";
// csG
import { stElemnt } from "../../../../St/Reviews/cStyle";

import { colors, Container, makeStyles, Typography } from "@material-ui/core";
import { useBody } from "../../invCompany/style";
import { txtA, txtE } from "../../../../Label/Lbl";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
const BodyDev = (props) => {
  const cls = useRptAF();
//   const { onUnmountComplete, data } = props;
  const { onUnmountComplete, data ,isFullPage,isLast} = props;

 
  const clas = useInvAF();
  const calssBody = useBody();
//   const islast=(data?.length )

  const cellS = {

    padding: "0px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    border: "solid 0.1px",
    fontSize: "0.7rem",
    width: "300px",
    fontFamily: "Amiri-Regular",
    fontWeight:"bold",
  };
  var columns = [
   { title: "Sr", field: "id" },
    { title:"Item Code", field: "barcode" },
    { title: "Description", field: "symbol", cellStyle: cellS },
    { title: "Qoantity", field: "val1" },
    { title:"UOM", field: "unit_name" },
  ];
  const data2 = data.map((row,i) => {
    return {
      ...row,
     id: i+1,
      totBill: row.totBill.toFixed(2),
      new_exchange_price: row.new_exchange_price.toFixed(2),
      tot_net_curr: row.tot_net_curr.toFixed(2),
      discount: row.discount.toFixed(2),
    };
  });

 

  return (
    <Grid item container className={cls.rootBdy}>
      <Grid item container //className={cls.rootBdyup}
      style={{paddingTop:"0.5rem" ,direction: "ltr",border: "dotted 1px #393939",
        }}>
        <Grid
          item
          xs={12}
          style={{
 
          }}
        >
          <MaterialTable
            style={{ boxShadow: "none" }}
            // style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              headerStyle: {
                ...stElemnt.AF.header,
               backgroundColor: "#fff",
                fontWeight: "bold",
                paddingTop: "2px",
                paddingBottom:"2px",
                border: "solid 0px",
                border: "solid 0.1px",
              },
              paging: false,
              cellStyle: {
                ...stElemnt.AF.cell,
                padding: "0px 1px",
              },
            }}
            localization={{ body: { emptyDataSourceMessage: <h1></h1> } }}
            columns={columns}
            data={data2}
          />
        </Grid>
      </Grid>
      
    </Grid>
  );
};

export default BodyDev;
