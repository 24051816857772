import React, { useEffect }  from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
// Ust
import { useInvAF } from "../../../../St/Reviews/UseStyle";
// csG
import { stElemnt } from "../../../../St/Reviews/cStyle";

import { colors, Container, makeStyles, Typography } from "@material-ui/core";
import { useBody } from "../../invCompany/style";
import { txtA, txtE } from "../../../../Label/Lbl";
import  InvTotal  from "../../AFReadyDisigen/A4/InvTotal";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
const BodyEn = (props) => {
  const cls = useRptAF();
  const { onUnmountComplete, data ,isFullPage,isLast} = props;

 
  const clas = useInvAF();
  const calssBody = useBody();
//   const islast=(data?.length )
// useEffect(() => {
//   return () => {
//     console.log(data);
//   };
// }, []);

  const cellS = {

    padding: "0px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    border: "solid 0.1px",
    fontSize: "0.9rem",
    width: "300px",
    fontFamily: "Amiri-Regular",
    fontWeight:"bold",
  };
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};

  var columns = [
   { title: "Sr \n م", field: "id" },
    { title:"Item Code \n الصنف", field: "barcode" },
    { title: "Description \n الوصف", field: "symbol", cellStyle: cellS },
    { title: "Qoantity \n الكمية ", field: "val1" },
    { title: "Unit \n الوحدة", field: "unit_name", },

    {
      title: "Unit Price \n السعر",
      field:"exchange_price",
      
    },
    { title: "Total Price \n  الإجمالي", field: "amountAfterDisc" },
  ];
  

  // const data3 = data.map((row, i) => {
  //   return { ...row, id: count_id + 1 };
  // });
  const data2 = data.map((row,i) => {
    return {
      ...row,
     id: i+1,
      totBill: row.totBill.toFixed(2),
      new_exchange_price: row.new_exchange_price.toFixed(2),
      tot_net_curr: row.tot_net_curr.toFixed(2),
      discount: row.discount.toFixed(2),
    };
  });

 

  return (
    <Grid item container className={cls.rootBdy}>
      <Grid item container //className={cls.rootBdyup}
      // style={{paddingTop:"0.5rem" ,direction: "ltr",border: "dotted 1px #393939",

      style={{paddingTop:"0.5rem" ,direction: "ltr",

        }}>
        <Grid
          item
          xs={12}
          style={{
          //  maxHeight: isFullPage ? sum >1 ? isLast?  "38em" :"40em" :"38em" :"38em" ,
           // minHeight: isFullPage ? sum >1 ? isLast?  "35em" :"40em" :"38em" :"38em" ,
          
           
          }}
        >
          <MaterialTable
            style={{ boxShadow: "none" }}
            // style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              headerStyle: {
                ...stElemnt.AF.header,
                backgroundColor: "#fff",
                fontWeight: "bold",
                paddingTop: "2px",
                paddingBottom:"2px",
                border: "solid 0px",
                border: "solid 0.1px",
               
                
              },
              paging: false,
             // headerStyle: { ...stElemnt.AF.header, backgroundColor: "grey" },
              cellStyle: {
                ...stElemnt.AF.cell,
                padding: "0px 1px",
                // fontSize: 8,
              },
              // maxBodyHeight: isFullPage ? "56.3rem" : "35.6rem",
              // minBodyHeight: isFullPage ? "56.3rem" : "35.6rem",
            }}
            localization={{ body: { emptyDataSourceMessage: <h1></h1> } }}
            columns={columns.map(column=>({
              ...column,
              title:column.title.split("\n").map((line,index)=>(
                <div key={index}>{line}</div>
              )),
             
            }))}
            data={data2}
          />
        {/* {isLast && <InvTotal/>} */}
        </Grid>
        {/* <Grid item xs={12}>
        </Grid> */}
      </Grid>
      
    </Grid>
  );
};

export default BodyEn;
