import React, { Fragment, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ScannerIcon from "@material-ui/icons/Scanner";

import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,
} from '../../../../St/Reports/UseStyle';
import { AppBar, Button, colors, Container, CssBaseline, Hidden, Paper, Toolbar } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { insh } from "../../../../Context/InshSt";
import PrintIcon from "@material-ui/icons/Print";
import TransformIcon from "@material-ui/icons/Transform";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import {
    ArrowDownward,
    Delete,
    MoreVert,
    PersonAddRounded,
    RestaurantMenu,
} from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AddBoxIcon from "@material-ui/icons/AddBox";
import StopIcon from "@material-ui/icons/Stop";

export default function InvoicesAppar(props) {
    const { t } = useTranslation(["forms", "translation"]);

    const {
        buttonClassnameSave,
        buttonClassnameDelete,
        clasCAB,
        setting,
        loading,
        backHome,
        uiElement,
        success,
        handOpenDw,
        clickOpenToggle,
        isHedden,
        permistionInv,
        style,
        stElemnt,
        clasBA,
        switchState,
        handCOpenCu,
        TransitionUp,
        toWhere,
        toPrint,
        transForm,
        buttonClassnamePrint,
        elmntBtn,
        or,
        stor,
        setPdfToggle,
        generating,
        StyledMenuItem,
        deleteTos,
        transformDone,
        transformDonePurch,
        setConfirmDialog,
        pdfToggle,
        StyledMenu,
        handOpenDwt,
        handNewInv,
        HomeBcode,
        TransitionDown,
        styBtn,
        clickCloseToggle,
        toggle,
        clickOpenTogglePrint,
        isDialog,
    } = props;
    const clasup = useStylesUp();
    const [styles, setStyles] = useState(insh.bascData);

    const calsPurch = useStylesAutoPurch();
    const claslbl = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar
                position="fixed"
                color="primary"
                // className={clasBA.appBarBASal}
                style={{ ...style.bgImg, ...stElemnt.all.appBarBA }}
            >
                <Toolbar className={clasBA.ToolbarBA} variant="dense">
                    <Hidden smUp={["sm"]}>
                        {isDialog == true ? (
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="backH"
                                onClick={props.onClose}
                            >
                                <CloseIcon />
                            </IconButton>) :
                            (
                                <dinv>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="backH"
                                onClick={backHome}
                            >
                                <CloseIcon />
                            </IconButton>
                            
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="searsh"
                            disabled={!permistionInv.Se}
                            onClick={() => handOpenDwt(TransitionDown)}
                        >
                            <StopIcon />
                        </IconButton>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="searsh"
                            disabled={!permistionInv.Se}
                            onClick={() => handOpenDw(TransitionDown)}
                        >
                            <SearchIcon />
                        </IconButton>

                        <IconButton
                            edge="start"
                            color="inherit"
                            disabled={!permistionInv.Ne}
                            aria-label="handNew"
                            onClick={handNewInv}
                        >
                            <AddBoxIcon />
                        </IconButton>
                            </dinv>)}
  
                    </Hidden>

                    <Hidden only={["xs"]}>
                        {isDialog === true ? (
                            <Button
                                style={styBtn.undis}
                                startIcon={<CloseIcon />}
                                onClick={props.onClose}
                            >

                                خروج
                            </Button>
                        ) : (
                            <div>
                            <Button
                                style={styBtn.undis}
                                onClick={backHome}
                                startIcon={<CloseIcon />}
                            >
                                خروج
                            </Button>
                            
                            <Button
                            style={!permistionInv.Se ? styBtn.dis : styBtn.undis}
                            disabled={!permistionInv.Se}
                            onClick={() => handOpenDwt(TransitionDown)}
                            startIcon={<StopIcon />}
                        >
                            {transForm("out-billing")}
                        </Button>
                        
                        <Button
                            style={!permistionInv.Se ? styBtn.dis : styBtn.undis}
                            disabled={!permistionInv.Se}
                            onClick={() => handOpenDw(TransitionDown)}
                            startIcon={<SearchIcon />}
                        >
                            {transForm("invoice-search")}
                        </Button>
                        

                        <Button
                            style={!permistionInv.Ne ? styBtn.dis : styBtn.undis}
                            disabled={!permistionInv.Ne}
                            onClick={handNewInv}
                            startIcon={<AddBoxIcon />}
                        >
                            {transForm("new")}
                        </Button>
                        </div>)}
                    </Hidden>
                    {/* <Button color="primary">Primary</Button> */}

                    {!isHedden
                        ?
                        switchState.tableOrscanBC ?
                            (
                                <Fab
                                    color="primary"
                                    aria-label="add"
                                    style={{
                                        ...style.fabButtonBA,
                                        ...stElemnt.all.fabButtonBA,
                                        ...{ height: 0, width: 0 },
                                    }}
                                    onClick={() => handCOpenCu(TransitionUp)}
                                >
                                    <HomeBcode />{" "}
                                </Fab>
                            ) :
                            (
                                <Fab
                                    color="primary"
                                    aria-label="add"
                                    style={{ ...style.fabButtonBA, ...stElemnt.all.fabButtonBA }}
                                    onClick={() => handCOpenCu(TransitionUp)}
                                >
                                    <PostAddIcon />{" "}
                                </Fab>
                            )
                        :
                        false
                    }

                    <div className={clasBA.growBA} />
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={pdfToggle}
                        keepMounted
                        open={Boolean(pdfToggle)}
                        onClose={() => setPdfToggle(null)}
                        style={stElemnt.itemHigh}
                    >
                        <div className={clasCAB.wrapperCAB}>
                            <StyledMenuItem
                                disabled={loading.print || loading.save || generating}
                                className={buttonClassnamePrint}
                                onClick={() => toPrint("print", "e", 3)}
                            >
                                <ListItemIcon>
                                    <PrintIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={transForm("مباشر ")} />
                            </StyledMenuItem>
                            {(loading.print || loading.save || generating) && (
                                <CircularProgress
                                    size={24}
                                    className={clasCAB.buttonProgressCAB}
                                />
                            )}
                        </div>

                        <div className={clasCAB.wrapperCAB}>
                            <StyledMenuItem
                                disabled={loading.print || loading.save || generating}
                                className={buttonClassnamePrint}
                                onClick={() => toPrint("print", "e", 4)}
                            >
                                <ListItemIcon>
                                    <ScannerIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={transForm("طباعة كبير")} />
                            </StyledMenuItem>
                            {(loading.print || loading.save || generating) && (
                                <CircularProgress
                                    size={24}
                                    className={clasCAB.buttonProgressCAB}
                                />
                            )}
                        </div>
                    </StyledMenu>

                    <StyledMenu
                        id="customized-menu"
                        anchorEl={toggle}
                        keepMounted
                        open={Boolean(toggle)}
                        onClose={clickCloseToggle}


                        style={stElemnt.itemHigh}
                    >
                        <div className={clasCAB.wrapperCAB}>
                            <StyledMenuItem
                                disabled={loading.print || loading.save}
                                className={buttonClassnamePrint}
                                onClick={() => toPrint("print", "e", 1)}
                            >
                                <ListItemIcon>
                                    <PrintIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={transForm("cashier-view")} />
                            </StyledMenuItem>
                            {(loading.print || loading.save) && (
                                <CircularProgress
                                    size={24}
                                    className={clasCAB.buttonProgressCAB}
                                />
                            )}
                        </div>

                        <div className={clasCAB.wrapperCAB}>
                            <StyledMenuItem
                                disabled={loading.print || loading.save}
                                className={buttonClassnamePrint}
                                onClick={() => toPrint("print", "e", 2)}
                            >
                                <ListItemIcon>
                                    <ScannerIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={transForm("great-view")} />
                            </StyledMenuItem>
                            {(loading.print || loading.save) && (
                                <CircularProgress
                                    size={24}
                                    className={clasCAB.buttonProgressCAB}
                                />
                            )}
                        </div>
                    </StyledMenu>

                    <Hidden smUp={["sm"]}>
                        {
                               stor.invsType === 0 || stor.invsType === 1 ? (null) : (
                                (uiElement.DeleIcon === true ? (
                                    <div className={clasCAB.wrapperCAB}>
                                        {permistionInv.De ? (
                                            <Fab
                                                size="medium"
                                                color="primary"
                                                // disabled
                                                style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                                className={buttonClassnameDelete}
                                                onClick={(e) => {
                                                    setConfirmDialog({
                                                        isOpen: true,
                                                        onConfirm: () => {
                                                            deleteTos(e);

                                                        },
                                                    })
                                                }}
                                            >
                                                {success.delete ? <CheckIcon /> : <DeleteIcon />}
                                            </Fab>
                                        ) : (
                                            //   <Fragment>{elmntIcon(<DeleteIcon />, true)}</Fragment>
                                            <Fab
                                                size="medium"
                                                color="primary"
                                                disabled={true}
                                                style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                            >
                                                <DeleteIcon />
                                            </Fab>
                                        )}
                                        {loading.delete && (
                                            <CircularProgress
                                                size={56}
                                                className={clasCAB.fabProgressCAB}
                                            />
                                        )}
                                    </div>
                                ) : null)
                            )
                        }
  
                        {or.postput === "toPost" ? (
                            <div className={clasCAB.wrapperCAB}>
                                <Fab
                                    size="medium"
                                    color="primary"
                                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                    // onClick={clickOpenToggle}
                                    onClick={(event) => clickOpenTogglePrint(event)}

                                    disabled={
                                        or.postput == "toPost"
                                            ? false
                                            : loading.print || loading.save
                                    }

                                >
                                    <PrintIcon />
                                </Fab>
                            </div>
                        ) : (
                            <div className={clasCAB.wrapperCAB}>

                                <Fab
                                    size="medium"
                                    color="primary"
                                    disabled={
                                        or.postput == "toPost"
                                            ? false
                                            : loading.print || loading.save
                                    }
                                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                    // onClick={clickOpenToggle}
                                    onClick={(event) => clickOpenTogglePrint(event)}

                                >
                                    <PrintIcon />
                                </Fab>
                            </div>
                        )}
                        <div className={clasCAB.wrapperCAB}>
                            {or.postput === "toPost" ? (
                                <Fab
                                    size="medium"
                                    color="primary"
                                    disabled={
                                        or.postput == "toPost"
                                            ? false
                                            : loading.print || loading.save
                                    }
                                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                    className={buttonClassnameSave}
                                    onClick={() => toWhere("save", "e")}
                                >
                                    {" "}
                                    {success.save ? <CheckIcon /> : <SaveIcon />}
                                </Fab>
                            ) : (
                                <Fab
                                    size="medium"
                                    color="primary"
                                    disabled={
                                        permistionInv.Up == true
                                            ? false
                                            : loading.print || loading.save
                                    }
                                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                    className={buttonClassnameSave}
                                    onClick={() => toWhere("save", "e")}
                                >
                                    {" "}
                                    {success.save ? <CheckIcon /> : <SaveIcon />}
                                </Fab>
                            )}

                            {(loading.print || loading.save) && (
                                <CircularProgress
                                    size={56}
                                    className={clasCAB.fabProgressCAB}
                                />
                            )}
                        </div>
                        {stor.invsType == 2 && or.postput !== "toPost" ? (
                            <div className={clasCAB.wrapperCAB}>
                                <Fab
                                    size="medium"
                                    color="primary"
                                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                    onClick={transformDone}
                                >
                                    {" "}
                                    <TransformIcon />
                                </Fab>
                            </div>
                        ) : null}
                        {stor.invsType == 6 && or.postput != "toPost" ? (
                            <div className={clasCAB.wrapperCAB}>
                                <Fab
                                    size="medium"
                                    color="primary"
                                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                    onClick={transformDonePurch}
                                >
                                    {" "}
                                    <TransformIcon />
                                </Fab>
                            </div>
                        ) : null}
                    </Hidden>


                    <Hidden only={["xs"]}>
                        { stor.invsType === 0 || stor.invsType === 1  ? (null) : (
                            (uiElement.DeleIcon === true ? (
                                <Fragment>
                                    <div className={clasCAB.wrapperCAB}>
                                        {/* {!permiElm.De ? ( */}
                                        {permistionInv.De ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={buttonClassnameDelete}
                                                startIcon={<DeleteIcon />}
                                                style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                        // disabled
                                        /*={loading.delete}*/ onClick={(e) => {
                                                    setConfirmDialog({
                                                        isOpen: true,
                                                        onConfirm: () => {
                                                            deleteTos(e);
                                                        },
                                                    })
                                                }}
                                            >
                                                {transForm("delete")}
                                            </Button>
                                        ) : (
                                            //   <Fragment>{elmntBtn("حـذف", <DeleteIcon />)}</Fragment>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                                disabled={true}
                                                startIcon={<DeleteIcon />}
                                            >
                                                {" "}
                                                {"حـذف"}
                                            </Button>
                                        )}
                                        {loading.delete && (
                                            <CircularProgress
                                                size={24}
                                                className={clasCAB.buttonProgressCAB}
                                            />
                                        )}
                                    </div>
                                </Fragment>
                            ) : null)

                        )}


                        {stor.invsType == 2 && or.postput != "toPost" ? (
                            <div className={clasCAB.wrapperCAB}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={<TransformIcon />}
                                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                    onClick={transformDone}
                                >
                                    تحويل لفاتورة مبيعات
                                </Button>
                            </div>
                        ) : null}
                        {stor.invsType == 6 && or.postput != "toPost" ? (
                            <div className={clasCAB.wrapperCAB}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={<TransformIcon />}
                                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                    onClick={transformDonePurch}
                                >
                                    تحويل لفاتورة شراء
                                </Button>
                            </div>
                        ) : null}
                        <div className={clasCAB.wrapperCAB}>
                            {permistionInv.Prv && permistionInv.SaUp ? (
                                <Fab
                                    size="medium"
                                    color="primary"
                                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                    onClick={(e) => clickOpenToggle(e, 2)}
                                >
                                    <MoreVert />
                                </Fab>
                            ) : (
                                <Fab
                                    size="medium"
                                    color="primary"
                                    disabled={true}
                                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                >
                                    <MoreVert />
                                </Fab>
                                // <Fragment>{elmntIcon(<MoreVert />, true)}</Fragment>
                            )}
                        </div>
                        {permistionInv.Up ? (
                            <Fragment>
                                <div className={clasCAB.wrapperCAB}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={buttonClassnamePrint}
                                        startIcon={<ScannerIcon />}
                                        style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                        disabled={loading.print || loading.save}
                                        onClick={() => toPrint("print", "e", 2)}
                                    >
                                        {transForm("great-view")}
                                    </Button>
                                    {(loading.print || loading.save) && (
                                        <CircularProgress
                                            size={24}
                                            className={clasCAB.buttonProgressCAB}
                                        />
                                    )}
                                </div>
                                <div className={clasCAB.wrapperCAB}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={buttonClassnamePrint}
                                        startIcon={<PrintIcon />}
                                        style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                        disabled={loading.print || loading.save}
                                        onClick={() => toPrint("print", "e", 1)}
                                    >
                                        {transForm("cashier-view")}
                                    </Button>
                                    {(loading.print || loading.save) && (
                                        <CircularProgress
                                            size={24}
                                            className={clasCAB.buttonProgressCAB}
                                        />
                                    )}
                                </div>

                            </Fragment>
                        ) : (
                            //   <Fragment>
                            //     {elmntBtn("معاينة كبير", <ScannerIcon />)}
                            //     {elmntBtn("معاينة كاشير", <PrintIcon />)}
                            //   </Fragment>
                            <Fragment>
                                <div className={clasCAB.wrapperCAB}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={buttonClassnamePrint}
                                        startIcon={<ScannerIcon />}
                                        style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                        disabled={loading.print || loading.save}
                                        onClick={() => toPrint("print", "e", 2)}
                                    >
                                        {transForm("great-view")}
                                    </Button>
                                    {(loading.print || loading.save) && (
                                        <CircularProgress
                                            size={24}
                                            className={clasCAB.buttonProgressCAB}
                                        />
                                    )}
                                </div>
                                <div className={clasCAB.wrapperCAB}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={buttonClassnamePrint}
                                        startIcon={<PrintIcon />}
                                        style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                        disabled={loading.print || loading.save}
                                        onClick={() => toPrint("print", "e", 1)}
                                    >
                                        {transForm("cashier-view")}
                                    </Button>
                                    {(loading.print || loading.save) && (
                                        <CircularProgress
                                            size={24}
                                            className={clasCAB.buttonProgressCAB}
                                        />
                                    )}
                                </div>

                            </Fragment>
                        )}
                        <div className={clasCAB.wrapperCAB}>
                            {or.postput == "toPost" ?
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                        className={buttonClassnameSave}
                                        disabled={
                                            loading.print ||
                                            loading.save ||
                                            (or.postput == "toPost" ? false : true)

                                        }
                                        onClick={() => toWhere("save", "e")}
                                        startIcon={<SaveIcon />}
                                    >
                                        {transForm("save-out-print")}
                                    </Button>
                                ) : (
                                    (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                            className={buttonClassnameSave}
                                            disabled={
                                                loading.print ||
                                                loading.save ||
                                                (permistionInv.Up ? false : true)

                                            }
                                            onClick={() => toWhere("save", "e")}
                                            startIcon={<SaveIcon />}
                                        >
                                            {transForm("save-out-print")}
                                        </Button>
                                    )
                                    //   <Fragment>
                                    //     {elmntBtn(transForm("save-out-print"), <SaveIcon />)}
                                    //   </Fragment>
                                )}
                            {(loading.print || loading.save) && (
                                <CircularProgress
                                    size={24}
                                    className={clasCAB.buttonProgressCAB}
                                />
                            )}
                        </div>


                    </Hidden>
                    {/* </div> */}
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}