import React from "react";
import { uri } from "../../../../help/Api";
import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import { insh, sto } from "../../../../Context/InshData";
import BackdropScI from "../../../../services/BackdropScI";

import dataF from "../../../../Server/dataF.json";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { accountsURL } from "../../../../api/EReceipt";
import ORestrictions from "./opening-restrictions";
import LoadingPage from "../../../../services/LoadingPage";
import { getReFormatingAccountOmla } from "../../../../services/global-services";

function ORestItem(props) {
  const tttb = {
    wait: true,
    other: true,
    costcenters: true,
    omlat: true,
    PermissionsElemnts: true,
    /*params*/ // out
    UsersA: true,
    parms: {
      ibranchid: sto.branchi,
      iempid: sto.empi,
      iuserid: sto.idi,
      branch: sto.branchi,
      tname: "rest",
      
      withOmlat: true,
      iformid: '60012',
    },
    autos: {
      costcenters: true,
      omlat: true,
    },
  };

  useEffect(() => {
    console.log("hello ", AutoOptions);
  });

  const url = uri + "Res";

  const {
    object: AutoOptions,
    fetching: fetchinData,
    fetch: fetchURL,
  } = useFetchObjectByPost(url, tttb);
  const {
    data,
    fetching,
    fetchURL: fetchURL1,
  } = useFetchURL({
    ...{
      url: accountsURL,
      mapFunction: getReFormatingAccountOmla,
    },
  });
  if (fetchinData || !AutoOptions?.costcenters || !data || fetching)
    return <LoadingPage />;
  return (
    <ORestrictions
      {...{
        AutoOptions: AutoOptions?.costcenters
          ? { ...AutoOptions, Accounts: data }
          : [],
        fetchURL,
        prmis: AutoOptions?.permisElm ?? insh.permiElm,
        ...props,
      }}
    />
  );
}

export default function ORestrictionsCon() {
  return (
    <ORestItem
      {...{
        isDialog: false,
        isReceipt: true,
      }}
    />
  );
}

export function ORestLink(props) {
  const { data: item, onClose } = props;

  return (
    <ORestItem
      {...{
        isDialog: true,
        isReceipt: true,
        item,
        onClose,
      }}
    />
  );
}
