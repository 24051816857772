import React, { useState, useEffect } from "react";
import axios from "axios";
import { uri } from "../../../../help/Api";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import { sto } from "../../../../Context/InshData";
import { useBody, useHeader } from "../../invCompany/style";
import { txtA } from "../../../../Label/Lbl";
import { Container, Divider, Paper } from "@material-ui/core";
import moment from "moment";

function HeaderEn({ locInv, found, sta, inv }) {

    // const clasH = useInvHeadrAF();
    const classHeader = useHeader();

    const styf = { fontWeight: "bold", color: "#000" };
    const styfC = { fontWeight: "bold", color: "#000" };
    const [info, setInfo] = useState({});
    const getInfo = async () => {
        const tb = {
            GetFoundation: true,
            parms: { iFoundid: 1, branch: sto.branchi },
            autos: {},
        };
        axios
            .post(`${uri}Res`, tb)
            .then((res) => {
                setInfo(res.data.Found);
            })
            .catch((error) => { });
    };
    useEffect(() => {
        getInfo();
    }, []);
    const clasm = useInvMain();
    const clas = useInvAF();
    const calssBody = useBody();
    return (
        <Grid
            item
            container
            // className={classHeader.headerContainer}
            style={{
                fontWeight: "bold"

            }}
        >
            <Grid item container >
                <Grid item container >
                    <Grid item xs={5} className={clasm.minleft}>
                        <div style={styf}>{info.Disc1_header_en}</div>
                        <div style={styf}>{info.Disc2_header_en}</div>
                        <div style={styfC}>{info.Disc3_header_en}</div>{" "}
                        <div style={styfC}>{info.Disc4_header_en}</div>
                    </Grid>

                    <Grid item xs={2} className={clasm.mincntr}>
                        <div>
                            <ButtonBase>
                                {" "}
                                <img
                                    src={`data:image/jpeg;base64,${info.Logo}`}
                                    className={clas.Hedlogo}
                                    alt={info.nameA}
                                />{" "}
                            </ButtonBase>
                        </div>
                    </Grid>

                    <Grid item xs={5} className={clasm.minrit}>
                        <div style={styf}>{info.Disc1_header_ar}</div>{" "}
                        <div style={styf}>{info.Disc2_header_ar}</div>
                        <div style={styfC}>{info.Disc3_header_ar}</div>{" "}
                        <div style={styfC}>{info.Disc4_header_ar}</div>
                    </Grid>
                </Grid>
                <Grid item xs={12} >

                    {/* <Divider style={{ background: "#d3d3d3d", height: 1.5, marginTop: "0.5rem" }} />
                    <Grid style={{ paddingTop: "0.1cm" }}>
                        <Divider
                            style={{ background: "#d3d3d3d", height: 1.5, marginBottom: "0.5rem" }}
                        />
                    </Grid> */}
                    <br/>
                </Grid>

            </Grid>


            <Grid item container >

                <Grid item xs={9} container style={{ alignContent: "right" }}>
                    {locInv.ProcTypeInvProc != "03" && (
                        <Grid item xs={12} container>
                            <Grid item xs={6} container >

                                <Grid item xs={12} style={{ fontWeight: "bold", fontSize: "1.2rem", textAlign: "left" }}>
                                    {locInv.invNEn +
                                        " " +
                                        sta.tot.inv_type_name_en +
                                        " " +
                                        sta.tot.pay_type_name_en}
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.2rem", textAlign: "left" }}>
                                    <div> {sta.tot.tax_no_cust === ""
                                        ? "Simplified Tax Invoice"
                                        : "Tax Invoice"}
                                    </div>
                                    <br />
                                    <div> {"INV-"}{"  "}{sta.tot.id}</div>
                                </Grid>

                            </Grid>
                            {locInv.ProcTypeInvProc != "03" ?
                                (
                                    <Grid item xs={3} container>
                                        <Grid item xs={3}></Grid>


                                        {
                                            <Grid item xs={8}>
                                                <ButtonBase style={{ border: "solid 1px" }}>
                                                    <img
                                                        src={`data:image/jpeg;base64,${inv.qr_image}`}
                                                        style={{ height: 140, width: 140 }}
                                                    // alt={found.nameA}
                                                    />
                                                </ButtonBase>
                                            </Grid>
                                        }

                                        <Grid item xs={1}></Grid>

                                    </Grid>
                                )
                                :
                                (<Grid item xs={2} container>
                                    <Grid item xs={12}></Grid>
                                </Grid>)
                            }
                        </Grid>
                    )}
                    <Grid item xs={12}></Grid>

                </Grid>

            </Grid>
            <Grid item container  >
                <Grid item xs={12} >

                    <Divider style={{ background: "#d3d3d3d", height: 1.5, marginTop: "0.5rem" }} />
                    <Grid style={{ paddingTop: "0.1cm" }}>
                        <Divider
                            style={{ background: "#d3d3d3d", height: 1.5, marginBottom: "0.5rem" }}
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid item container style={{    border: "solid 0.1px"}}>
                <Grid item container xs={12} style={{padding:3}}>
                    <Grid item xs={8} >
                        <div style={{ fontWeight: "bold", padding: 3 }}>{"Name :"} {sta.tot.cust_name} </div>
                    </Grid>
                    <Grid item xs={4} >
                        <div style={{ fontWeight: "bold", padding: 3 }}>{"Invoice Date :"} {moment(sta.tot.date).format("YYYY/MM/DD")} </div>
                    </Grid>
                    </Grid>
                    <Grid item container xs={12} style={{padding:3}}>
                    <Grid item xs={8} >
                        <div style={{ fontWeight: "bold", padding: 3 }}>{"Address :"} {sta.tot.cust_address} </div>
                    </Grid>
                    <Grid item xs={4} >
                        <div style={{ fontWeight: "bold", padding: 3 }}>{"Due Date :"} {moment(sta.tot.date).format("YYYY/MM/DD")} </div>
                    </Grid>
                    </Grid>
                    <Grid item container xs={12} style={{borderTop: "dotted 1px #393939",}}>
                    <Grid item xs={6} >
                        <div style={{ fontWeight: "bold", padding: 3 }}>{"Contact :"} {sta.tot.cust_tel} </div>
                    </Grid>
                    <Grid item xs={6} >
                        <div style={{ fontWeight: "bold", padding: 3 }}>{"Customer Tax Number"} {sta.tot.tax_no_cust}</div>
                    </Grid>
                    </Grid>
                </Grid>


            </Grid>
        </Grid>
    );
}
export default HeaderEn;
