import React, { useState, useEffect, Fragment } from "react";
import { useStyles } from "./account-index-style";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveeIcon from "@material-ui/icons/Save";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { Delete } from "@material-ui/icons";
import { CssBaseline, Grid } from "@material-ui/core";
import {
  accountIndexValidateSchema,
  initFormData,
} from "../../../../services/accountIndex-services";
import toast from "react-hot-toast";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";

export default function AccountsIndexAppBar(props) {
  const clasess = useStyles();
  const {
    newCode,
    prmis,
    submit,
    update,
    remove,
    submitting,
    onShow,
    object,
    formType,
  } = props;
  const { t } = useTranslation(["forms"]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleSubmit = async (data) => {
    let nData = { ...data, Code: newCode };
    accountIndexValidateSchema
      .validate(nData, { abortEarly: false })
      .then(async () => {
        if (formType == "edit") {
          if (data.ParentCode === "121" || data.ParentCode === "122" || data.ParentCode === "123"
            || data.ParentCode === "253" || data.ParentCode === "261"
          ) {
            alert("You Cant  Update This Account From This Screen");
          } else {
            await update(data).then((res) => { });
          }
        } else {
          
          if (nData.ParentCode === 121 || nData.ParentCode === 122 || nData.ParentCode === 123
            || nData.ParentCode === 253 || nData.ParentCode === 261
          ) {
            alert("You Cant Add This Account From This Screen");
          } else {
            await submit(nData).then((res) => { });
          }
        }
      })
      .catch((err) => {
        toast.error(err.errors[0]);
      });

  };
  const his = useHistory();
  const backHome = () => {
    his.push({ pathname: "/" });
    window.location.reload(false);
  };

  return (
    <Fragment>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{
          top: "auto",
          bottom: 0,
          backgroundColor: "#808080",
        }}
      >
        <Toolbar>
          <Grid item lg={2} md={2} sm={3} xs={3}>
            <Button
              className={clasess.buttonss}
              variant="contained"
              color="primary"
              fontSize="large"
              size="medium"
              onClick={backHome}
              startIcon={<CancelIcon />}
            >
              {" "}
              {t("exit")}
            </Button>
          </Grid>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
          ></IconButton>
          <div className={clasess.grow} />
          <IconButton color="inherit">
            <Button
              className={clasess.buttonss}
              variant="contained"
              color="secondary"
              fontSize="large"
              size="medium"
              startIcon={<Delete />}
              disabled={submitting || formType == "new" || prmis?.De}
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "؟هل تريد الحذف بالتأكيد",
                  subTitle: "",
                  onConfirm: () => {
                    remove(object.Code);
                  },
                });
              }}
            >
              {" "}
              {t("delete")}
            </Button>
          </IconButton>

          <Button
            className={clasess.buttonss}
            variant="contained"
            color="primary"
            fontSize="large"
            size="medium"
            onClick={() => handleSubmit(object)}
            startIcon={<SaveeIcon />}
            disabled={submitting || (formType == "new" ? prmis?.Sa : prmis?.Up)}
          >
            {formType == "new" ? t("save") : t("save-update")}
          </Button>

          <Button
            variant="contained"
            edge="start"
            color="primary"
            fontSize="large"
            className={clasess.buttonss}
            startIcon={<PostAddIcon />}
            onClick={() => onShow(initFormData, "new")}
          >
            {t("new")}
          </Button>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}
