import React, { useState } from "react";
import { colors, Grid, Typography } from "@material-ui/core";
import Barcode from "react-barcode/lib/react-barcode";
import { sto } from "../../../../../Context/InshData";

function BarcodeGeneratorTemplate02(props) {
  const { barcode, itemName, price, prody_date, expiry_date ,maqas} = props;
  const width = 2;
  const hight = 30;

  return (
    <div  justifyContent="center"  style={{
        minWidth: `${maqas.wisthPaper}mm`,
        // minWidth: '50mm',
        maxWidth:  `${maqas.wisthPaper}mm`,
        minHeight: '25mm',
        textAlign: "center",
        backgroundColor: "#fff",
        alignContent:"center",
        paddingTop:`${maqas?.margnTop}mm`, 
        paddingBottom:`${maqas?.margnBottom}mm`,   
    }}>
       <div justifyContent="center"  style={{ direction: "rtl", textAlign: "center" ,fontWeight:"bold",maxLines:1,marginLeft:"0.1rem"
        ,marginRight:"0.1rem" ,fontSize:"0.8rem",marginTop:"0.1rem" ,alignContent:"center"
       }}
       > {sto?.found}</div>
        <div
            style={{
                minWidth: '50mm',
                // height: '25mm',
                // border:'0.5px solid #000'
                paddingRight: '1mm',
                paddingLeft: '1mm',
                // padding: '1mm',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '12px',
                // textAlign:'center',
                backgroundColor: '#fff',

            }}
        >
            <Barcode value={barcode} width={width} height={hight} />



            {/* <div style={{ direction: "rtl", textAlign: "center" }}>
    <Grid container xs={12} style={{ fontSize: "9px" }}>
      <Grid item xs={8}  style={{ fontSize: "9px" }}>
        {itemName}
      </Grid>
      <Grid item xs={4}  style={{ fontSize: "9px" }}>
        {" "}
        {price}
        {"رس  "}
      </Grid>
    </Grid>
    <Grid
      container
      xs={12}
      style={{
        fontSize: "8px",
      }}
    >
      <Grid item xs={6}  style={{ fontSize: "9px" }}>
        {prody_date}
      </Grid>
      <Grid item xs={6}  style={{ fontSize: "9px" }}>
        {" "}
        {expiry_date}
      </Grid>
    </Grid>
  </div> */}

        </div>
        {/* <div style={{ direction: "rtl", textAlign: "center" ,fontWeight:"500",maxLines:1}}> {itemName}</div> */}
        <div style={{ direction: "rtl",alignContent:"center", textAlign: "center" ,fontWeight:"bold",maxLines:1}}>{price}{""}{"رس  "}</div>
        <Grid container item xs={12}  style={{ fontSize: "9px" }}>
        {/* <Grid item xs={12}  style={{ fontSize: "0.8rem" ,fontWeight:"bold"}}>
        {prody_date} {"    ."}        {expiry_date}

      </Grid> */}
      {/* <Grid item xs={6} style={{ fontSize: "0.8rem" ,fontWeight:"bold"}}>
        {" "}
        {expiry_date}
        </Grid> */}
      </Grid>
    </div>
);
}

export default BarcodeGeneratorTemplate02;
