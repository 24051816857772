import React, { useEffect, useState, Fragment, useRef } from "react";
import axios from "axios";
import { uri } from "../../../../help/Api";
import { insh, sto, premiA } from "../../../../Context/InshData";
import { locSto as locStos } from "../../../../Context/Locstor";
import { getLS } from "../../../../utils/useLS";
import { transToPurch, transToSal } from "../../../../Context/Local";
import { inshF } from "../../../../Context/InshFront";
import moment from "moment";
import PropTypes from "prop-types";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";

import { useHistory, useLocation } from "react-router-dom";
import HomeBcode from "../../../../barcode/HomeBcode";
import dataF from "../../../../Server/dataF.json";
// f d
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// f e
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import MaterialTable, { MTableToolbar } from "material-table";
import { TableIcons as tableIcons } from "../../../../Elemnts/TableIcons";
import {Delete} from "@material-ui/icons";

// ===================================================== btn load
import clsx from "clsx";
import {
  useStylesUp,
  useStylesHed,
  useStylesAuto,
  useStylesTF,
  useStylesTD,
  useStylesBA,
  useStylesFU,
  useStylesCAB,
  useStylesLbl,
  SaleCheckbox,
  useStylesDSM,
  useStylesDPM,
  StyledMenuItem,
  StyledMenu,
  useStylesSN,
  SaleSwitch,
} from "../../../../St/comps/UseStyle";

import { cellS, headerS, stElemnt } from "../../../../St/comps/cStyle";
import { Mtb } from "../../../../St/comps/vstyle";

import "../../../../St/comps/csStyle.css";
import BackdropScI from "../../../../services/BackdropScI";

import FavCard from "../../Elemnt/FavCard";
import ReConnect from "../../../../Start/ReConnect";
import OutstandingInvoiceModal from "../models/outstanding-invoice-modal";
import { getInveTypeIndex } from "../../../../modules/utils";
import { getDefaultOmla } from "../../../../services/omlat-services";
import CustomersDialog from "../../../Customers/customers/customersDialog";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import toast, { Toaster } from "react-hot-toast";
import {
  handleDirectPrintTemp2,
  PrintInvoiceDirectly,
} from "../components/printSerivce";
import ExtVoucherDialog from "../components/ExtVoucherDialog";
import DeleteConfirmDialog from "../../../../components/DeleteConfirmDialog";
import PaidWindowForm from "../components/paidwidow/paidwindowform";
import { useLocalStorage } from "react-use";
import InvoicesHead from "./InvoiceHead";
import InvoiceItemsModals from "../models/InvoiceItemsModel";
import SearchInvoicesMains from "../models/search-model/SearchInvMains";
import ItemsDialog from "../../../bascDatas/Tab1/items/AddItems/ItamsDialog";
import InvoiceFooter from "./invoiceFooter";
import InvoicesAppar from "./InvoicesAppar";
import CustomerDialogData from "../../../Customers/customers/CustomerDialogData";


//=====================================================================
let nevEve = "always",
  nl = null;

const useStylestolb = makeStyles((theme) => ({
  toolbarWrapper: {
    "& .MuiToolbar-gutters": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let LetvalueCurrUnits = null;

export default function InvoicesFormMain(props) {
  const { AutoOptions, isPurches, isStock,isDialog } = props;
  const cl = (c) => {
    console.log(c);
  };
  const hasRun = useRef("paper");

  const [showSc, setShowSc] = useState(false);

  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasSM = useStylesDSM();
  const clasPM = useStylesDPM();
  const clasBA = useStylesBA();
  const clasFu = useStylesFU();
  const clasCAB = useStylesCAB();
  const clasSN = useStylesSN();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const claslbl = useStylesLbl();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const clstoolbar = useStylestolb();
  const [gets, setGets] = useState(insh.tbname);
  const [maxShifts, setMaxShifts] = useState(AutoOptions.maxShift.id);
  const [currentUserName, setCurrentUserName] = useState(AutoOptions.maxShift.name);

  const [idata, setIData] = useState(() => {
    return { ...insh.IdataInvs, date: moment(new Date()).format("YYYY-MM-DD") };
  }); //is data in ta
  //is data in ta
  const [allow, setAllow] = useState({ c: false, s: false, it: false, cud: false });
  const [allowPaid, setAllowPaid] = useState({ c: false });
  /* Anemation */
  const [transition, setTransition] = React.useState(undefined);
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
  }
  function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }

  const [isOpenSy, setIsOpenSy] = useState(false);

  const handleOpenSy = () => {
    setAllow({ ...allow, s: true });
    setIsOpenSy(!isOpenSy);
  }; // E rsa

  const filter = createFilterOptions();

  //rsa
  const [isOpenCustomer, setIsOpenCustomer] = useState(false);
  const [isOpenCustomerData, setIsOpenCustomerData] = useState(false);

  const [isOpenItems, setIsOpenItems] = useState(false);
  const [openPaidWindows, setOpenPaidWindows] = useState(false);

  const handleOpenCustomerData = () => {
    setAllow({ ...allow, cud: !isOpenCustomerData });
    setIsOpenCustomerData(!isOpenCustomerData);
  }; // E rsa
  const handleOpenCustomer = () => {
    setAllow({ ...allow, c: !isOpenCustomer });
    setIsOpenCustomer(!isOpenCustomer);
  }; // E rsa
  const handleOpenItems = () => {
    setAllow({ ...allow, it: !isOpenItems });
    setIsOpenItems(!isOpenItems);
  }; // E rsa
  // s Dup
  const backHome = () => {
    his.push({ pathname: "/" });
    window.location.reload(false);
  };

  // s DDown
  const [openDw, setOpenDw] = useState(false);

  const handOpenDw = (Transition) => {
    setTransition(() => Transition);
    // getInvs(
    //   dateFrom + " " + timeFrom,
    //   dateTo + " " + timeTo,
    //   null,
    //   sto.User_typei
    // );
    setOpenDw(true);
  };
  const handCloseDw = () => {
    setOpenDw(false);
  };

  const [subInfo, setSubInfo] = useState(insh.InfoSub);
  const [idSearch, setIdSearch] = useState(false);

  const [counts, setCounts] = useState({ curr: 0 });
  const [showPaid, setShowPaid] = useLocalStorage("show-paid-window", false);
  /* s DcuD  */
  const [openCuD, setOpenCuD] = React.useState(false);

  const [dialog, setDialog] = React.useState(inshF.dia);
  useEffect(() => {
    window.onbeforeunload = function (e) {
      e.preventDefault();
      return "you can not refresh the page";
    };
  
  }, []);

  const handleDia = (Transition, prop, state) => {
    setTransition(() => Transition);
    setDialog({ ...dialog, [prop]: state });
  };
  const handleClDia = (prop, state) => setDialog({ ...dialog, [prop]: state });
  const [openDwt, setOpenDwt] = useState(false);

  const handOpenDwt = (Transition) => {
    setTransition(() => Transition);
    getInvst(
      sto.User_typei
    );
    setOpenDwt(true);
  };
  const handCloseDwt = () => {
    setOpenDwt(false);
  };


  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  const getInvst = async (utype) => {
    let today = moment(new Date()).format("YYYY-MM-DD");
    console.log("the data is", today)
    await axios
      .get(
        uri +
        "InvDatet" +
        `?id=${sto.empi}&&dateF=${today}&&dateT=${today}&&PcIdIvPc=${setting.PrTy_InPr}&&uType=${utype}&&bran=${sto.branchi}`
      )
      .then((res) => {
        cl("===res.data");
        cl(res.data);
        setInvsShowt(res.data);
        setInvsShowFltr(res.data);
      })
      .catch((error) => { });
  };
  /* s DcuD and McuD */
  const handCOpenCu = (Transition) => {
    setBarcodes("");
    // loadGetCountSafe();
    loadGetCountSafeByGroup();
    setTransition(() => Transition);
    setOpenCuD(true);
  };

  /* s PMD */
  const [openPMD, setOpenPMD] = useState(false);
  //const [typePress, setTypePress] = useState("save");
  let typePress = "save";
  let openPay = false;


  const handleClickOpenPMD = (Transition) => {
    setTransition(() => Transition);
    setOpenPMD(true);
  };
  const handleClickOpenPaid = (type) => {
    typePress = type;
    // setValueBankPMD

    setOpenPMD(true);
  };
  const handClosePMD = async (state) => {
    if (state === "cancle") await restPM(state);
    await setOpenPMD(false);
  };
  /* e PMD */

  /* s BCSD */

  const [barcodes, setBarcodes] = useState("");
  const { t: transForm } = useTranslation(["forms"]);
  const _onDetected = async (result) => {
    const res = [];
    const item = currencies[barcodes_eds.indexOf(result)];
    res.push(item.units);

    if (res.length > 0) {
      await BefBarcodedataCurrencie(item.id, item.units);
      setIsBarcode(true);
    }
  };
  /* e BCSD */

  const [lbl, setLbl] = React.useState(insh.Lbl);
  const [switchState, setSwitchState] = React.useState(insh.SwitchState);


  const chashVSnetwork = (valChecked) => {

    if (valChecked == true && valueType.id !== 1) {
      const text = transForm("network").toString();
      setLbl({ ...lbl, chashNetwork: text });
      setValueBank(AutoOptions.Banks[0]); //getAutobanks(0);
      setUiElement({ ...uiElement, bank: false });
      setRemains({ ...remains, cashPart: 0, agelPart: payment.stay, bankPart: 0, networkPart: payment.paidup });

      setIData({ ...idata, pay_type: 2, bank: AutoOptions.Banks[0].id });
    } else if (valChecked == false && valueType.id !== 1) {
      setValueBank(insh.AutoBank); //setOptionsBank(insh.AutoBankOp);
      setLbl({ ...lbl, chashNetwork: transForm("cash") });
      setUiElement({ ...uiElement, bank: true, stock: false });
      setIData({ ...idata, pay_type: 1, bank: 0,stock:AutoOptions.Stocks[0].id });
      setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });

    } else if (parseFloat(payment.paidup) > 0 && valChecked === true && valueType.id === 1) {
      const text = transForm("network").toString();
      setLbl({ ...lbl, chashNetwork: text });
      setValueBank(AutoOptions.Banks[0]); //getAutobanks(0);
      setUiElement({ ...uiElement, bank: false });
      setRemains({ ...remains, cashPart: 0, agelPart: payment.stay, bankPart: 0, networkPart: payment.paidup });
      setIData({ ...idata, pay_type: -1, bank: AutoOptions.Banks[0].id, cash_part: payment.paidup, agel_part: payment.stay, network_part: 0 });

    }
    else if (parseFloat(payment.paidup) > 0 && valChecked == false && valueType.id == 1) {
      setValueBank(insh.AutoBank); //setOptionsBank(insh.AutoBankOp);
      setLbl({ ...lbl, chashNetwork: transForm("cash") });
      setUiElement({ ...uiElement, bank: true });
      setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });
      setIData({ ...idata, pay_type: -1, bank: AutoOptions.Banks[0].id,stock:AutoOptions.Stocks[0].id, cash_part: payment.paidup, agel_part: payment.stay, network_part: 0 });

    }
  };
  const storgeingprem = async (info) => {
    const tb = {
      PermissionApp: true,
      wait: true,
      /*params*/
      parms: {
        iuserid: premiA.user_id,
      },
      autos: {},
    };
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        localStorage.setItem("premiApp", JSON.stringify(res.data.permisApp));
        setSwitchState({
          ...switchState,
          stayInDCurr: res.data.permisApp.stayInDCurr,
        });
      })
      .catch((error) => { });
  };

  const updatePrem = async (event) => {
    premiA.stayInDCurr = event;
    await axios
      .put(uri + "PermissionApp", premiA)
      .then((res) => {
        storgeingprem(res.data);
      })
      .catch((error) => { });
  };

  const changClick = (prop, ev) => {
    if (prop == "minus") setUiElement({ ...uiElement, [prop]: ev, rate: true });
    else if (prop == "rate")
      setUiElement({ ...uiElement, [prop]: ev, minus: true });
  };

  const changSwitch = (name) => (event) => {
    setSwitchState({ ...switchState, [name]: event.target.checked });

    if (name == "stayInDCurr") updatePrem(event.target.checked);
    else if (name == "cashORnetworksw") chashVSnetwork(event.target.checked);
  };

  const handleCloseCuD = () => {
    setCurrUnit([]);
    setOpenCuD(false);
    setSubInfo(insh.InfoSub);
  };

  const handleCloseCuDWithSWState = () => {
    switchState.stayInDCurr ? setOpenCuD(true) : setOpenCuD(false);
    setSubInfo(insh.InfoSub);
  };

  const changChckedPM = (network, agel, bank, emp, onlyCheked) => {
    onlyCheked === ""
      ? setChecked({
        ...checked,
        network_partck: network,
        agel_partck: agel,
        bank_partck: bank,
        emp_partck: emp,
      })
      : setUiElement({ ...uiElement, [onlyCheked]: false });
  };

  const changAutosPM = (bank, bank2, emp, onlyCheked) => {
    if (onlyCheked === "") {
      if (bank === false) setValueBankPMD(insh.AutoBank);
      if (bank2 === false) setValueBankTransPMD(insh.AutoBank);
      if (emp === false) setValueEmployePMD(insh.Auto);
      setUiElement({
        ...uiElement,
        bankPM: !bank,
        bankPM2: !bank2,
        empPM: !bank,
      });
    } else {
      if (onlyCheked === "bankPM") setValueBankPMD(insh.AutoBank);
      else if (onlyCheked === "bankPM2") setValueBankTransPMD(insh.AutoBank);
      else if (onlyCheked === "empPM") setValueEmployePMD(insh.Auto);
      setUiElement({ ...uiElement, [onlyCheked]: true });
    }
  };

  const restPM = (from) => {
    let ck = { network: true, agel: true, bank: true, emp: true };
    if (from === "rest") {
      if (idata.network_part === "") ck.network = false;
      if (idata.agel_part === "") ck.agel = false;
      if (idata.bank_part === "") ck.bank = false;
      if (idata.emp_part === "") ck.emp = false;
    } else {
      ck.network = false;
      ck.agel = false;
      ck.bank = false;
      ck.emp = false;
    }
    changChckedPM(ck.network, ck.agel, ck.bank, ck.emp, "");
    changAutosPM(ck.network, ck.bank, ck.emp, "");
  };
  const [or, setOr] = useState(insh.Or);
  const [ished, setIshed] = useState(insh.ished);


  const [permiElm, setPermiElm] = useState(insh.permiElm);
  const [permiCuCo, setPrmiCuCo] = useState({});

  const getPrmetions = (resDataElm, permiCuCo) => {
    setPermiElm(resDataElm);
    getAutoPricePays(permiCuCo.TSP);
    if (resDataElm.USP) {
      nevEve = "never";
    }

    setPrmiCuCo(permiCuCo);
  };

  const [uiElement, setUiElement] = useState({});
  const [permistionInv, setPermistionInv] = useState(AutoOptions.permsInvs);


  // >>>>> datas
  const [currencies, setCurrencies] = useState([]);
  const [invsShow, setInvsShow] = useState([]);
  const [invsShowt, setInvsShowt] = useState([]);
  const [exsub, setExSub] = useState(0);
  const [invsShowFltr, setInvsShowFltr] = useState([]);

  const [infoInv, setInfoInv] = useState({ id: "", InvId_Return: "" });

  const [isdata, setIsData] = useState([]); //is data in ta

  const location = useLocation();

  const [stor, setStor] = useState({}); //is data in ta
  const [storS, setStorS] = useState({}); //is data in ta

  const [favGroups, setFavGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsSub, setGroupsSub] = useState([]);

  // Auto

  const [auto, setAuto] = useState(insh.auto);

  const [valueType, setValueType] = useState(insh.Auto);

  //TypeInvs
  const [valueTypeFltr, setValueTypeFltr] = useState(insh.Auto);
  //Safes
  const [valueSafes, setValueSafes] = useState(AutoOptions.Safes[0]);
  //Customers
  const [optionsCustomers, setOptionsCustomers] = useState(AutoOptions.Customers);

  const [valueCustomers, setValueCustomers] = useState(AutoOptions.Customers[0]);
  //Salesmen
  const [valueSalesmen, setValueSalesmen] = useState(AutoOptions?.salesuser);
  //Employee
  const [valueEmployePMD, setValueEmployePMD] = useState(insh.Auto);
  //Costcenter
  const [valueCostcenter, setValueCostcenter] = useState(insh.Auto);
  //Stock
  const [valueStock, setValueStock] = useState(AutoOptions.Stocks[0]);
  const [omlaValue, setOmlaValue] = useState(insh.Auto);
  // network
  const [valueBank, setValueBank] = useState(insh.AutoBank);
  // network PMD
  const [valueBankPMD, setValueBankPMD] = useState(insh.AutoBank);
  //Bank Trans PMD
  const [valueBankTransPMD, setValueBankTransPMD] = useState(insh.AutoBank);
  //Units

  const [countCurrBySafe, setCountCurrBySafe] = useState([]);
  const [currenciesToInv, setCurrenciesToInv] = useState([]);
  const [currUnit, setCurrUnit] = useState([]);
  const [currUnitsAll, setCurrUnitsAll] = useState([]);
  // const [optionsCurrUnits, setOptionsCurrUnits] = useState(insh.AutoOp);
  const [optionsCurrUnits, setOptionsCurrUnits] = useState(insh.AutoOp);
  const [optionsCurrUnitsTable, setOptionsCurrUnitsTable] = useState(insh.AutoOp);



  const [valueCurrUnits, setValueCurrUnits] = useState(insh.Auto);
  const [valueItemUnits, setValueItemsUnits] = useState(insh.AutoUits);

  const [indexUnit, setIndexUnit] = useState(0);
  //PricePay
  const [optionsPricePay, setOptionsPricePay] = useState(insh.AutoOp);
  const [valuePricePay, setValuePricePay] = useState(insh.Auto);
  // edrees changes
  const [itemId, setItemId] = useState("1");
  const [barcodes_eds, setBarcodes_eds] = useState();

  const handleFocus = (e) => e.target.select();
  const getAutoPricePays = (i) => {
    setOptionsPricePay(insh.optionPricePay);
    setValuePricePay(insh.optionPricePay[i]);
  };

  const addChangds = () => {

    idata.date =
      moment(idata.date).format("YYYY-MM-DD") +
      moment(new Date()).format(" h:mm A");
    idata.InvId_Return = infoInv.InvId_Return;
  };



  const fltringCurrencieToinv = async (res, pricePayId, changtaxperc) => {
    console.log("the response data is", res);
    if (setting.PrTy_InPr == "22") res.tax = 0;
    let sORp = 0;
    if (setting.cusType == 1) sORp = res.sale;
    else if (setting.cusType == 2) sORp = res.purch;
    if (changtaxperc == false) {
      res.tax = subInfo.taxperc;
      res.val1 = subInfo.val1;
    }
    if (pricePayId == 2) sORp = res.dPrice;
    else if (pricePayId == 3) sORp = res.jPrice;

    if (checked.autoOrStaticck === false) {
      setSubInfo({
        ...subInfo,
        hdshw: true,
        val1: res.val1,
        is_tab3_curr: res?.is_tab3_curr,
        id: res.id,
        minPrice: res.mPrice,
        Price: sORp,
        /*PricePu:res.purch,*/ barcode: res.barcode,
        symbol: res.symbol,
        type: res.type,
        perc: res.perc,
        taxperc: res.tax,
        totJust: (sORp * res.val1).toFixed(2),
        discount: "",
        DiscPers: "",
        taxval: ((sORp * res.tax) / 100).toFixed(2),
      });
    } else {
      subInfo.hdshw = true;
      subInfo.val1 = res.val1;
      subInfo.id = res.id;
      subInfo.Price = sORp;
      subInfo.minPrice = res.mPrice;
      subInfo.barcode = res.barcode;
      subInfo.symbol = res.symbol;
      subInfo.perc = res.perc;
      subInfo.taxperc = res.tax;
      subInfo.totJust = (sORp * res.val1).toFixed(2);
      subInfo.discount = "";
      subInfo.DiscPers = "";
      subInfo.type = res.type;
      subInfo.taxval = ((sORp * res.tax) / 100).toFixed(2);
      subInfo.is_tab3_curr = res.is_tab3_curr;
    }
  };


  const getSubGroups = async (id) => {
    // alert(id)
    const res = [];
    locStos.groupsSub.map((x) => {
      if (x.i == id) res.push(x);
    });
    setGroupsSub(res);
  };

  const reLoadLastInv = async () => {
    const tb = {
      other: true,
      parms: {
        sProcTypeInvProc: setting.PrTy_InPr,
        branch: sto.branchi,
        tname: "invs",
      },
      autos: {},
    };
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        setInfoInv({ id: res.data.other.lastid, InvId_Return: "" });
      })
      .catch((error) => { });
  };

  const loadSto = async (cusType) => {
    const locSto = getLS("sto", {});
    setGets(locSto);
    console.log("the log sto is", locSto);
    // eds changed
    setBarcodes_eds(locSto.Currencies.map((c) => c.barcode));
    /* go Func we need check Condsh */
    let valCust = cusType == 1 ? locSto.CustomersA[0] : locSto.CustomersM[0];

    loadIdata(AutoOptions.Customers[0], AutoOptions.types, AutoOptions?.Banks);

    // const safe = getSafeDefault(locSto.permisApp.user_id, locSto.Users);
    setPermistionInv(AutoOptions.permsInvs);

    setValueSafes(AutoOptions.Safes[0]);
    setValueStock(AutoOptions.Stocks[0]);
    const defOmla = getDefaultOmla(locSto.omlats);
    setOmlaValue(defOmla);
    // setOptionsCustomers(cusType == 1 ? locSto.CustomersA : locSto.CustomersM);

    setOptionsCustomers(AutoOptions.Customers);

    setValueCustomers(AutoOptions.Customers[0]);
    setCurrencies(AutoOptions.Items);

    setcuRows(
      AutoOptions.Items?.map((c) => {
        return { ...c, sale_price: c.sale_price * defOmla.exchange };
      })
    );
    setGroups(AutoOptions.groups);
    setFavGroups(locSto.CurrenciesFav);
    setCurrenciesToInv(locSto.CurrenciesToInv);
    const inveTypeIndex = getInveTypeIndex(valCust.Cust_inv_type);

    //setValueType(locSto.types[inveTypeIndex]);
    setValueTypeFltr(locSto.typesFltr[0]);
     getCustInvType(AutoOptions.Customers[0]);
  };

  const loadGet = async (branch, pTpI, empid, formid, invType) => {
    setInfoInv({ ...infoInv, id: AutoOptions.other?.lastid });
    getPrmetions(AutoOptions.permisElm, AutoOptions.permiCuCo);
    setShowSc(AutoOptions.wait?.state);
    setPermistionInv(AutoOptions.permsInvs);
    setCurrencies(AutoOptions.Items);
    setGroups(AutoOptions.groups);
    const locSto = getLS("sto", {});
    setGets(locSto);
    const defOmla = getDefaultOmla(locSto.omlats);
    setOmlaValue(defOmla);
    setOptionsCustomers(AutoOptions.Customers);
    setcuRows(
      AutoOptions.Items?.map((c) => {
        return { ...c, sale_price: c.sale_price * defOmla.exchange };
      })
    );
  };
  const GetConvertingInv = async (formid, PrType) => {
    const tb = {
      other: true,
      PermissionsInvs: true,
      PermissionsElemnts: true,
      PermissionsCuCo: true,
      parms: {
        sProcTypeInvProc: PrType,
        branch: sto.branchi,
        tname: "invs",
        iempid: sto.empi,
        iformid: formid,
        iformidCuCo: dataF[3].id /* for curr and yashml */,
      },
      autos: {},
    };
    await axios
      .post(uri + "GetD", tb)
      .then((res) => {
        setInfoInv({ ...infoInv, id: res.data.other.lastid });
        setPermistionInv(res.data.permsInvs);
        getPrmetions(res.data.permisElm, res.data.permiCuCo);
        //  setShowSc(res.data?.wait.state);
      })
      .catch((error) => { });
  };
  const reLoadCust = async (newCustData) => {
    if (newCustData != null) {
      let newCust = { id: newCustData.id, name: newCustData.name, Cust_inv_type: newCustData.Cust_inv_type, acc_no: newCustData.acc_no, 
        salesmen: newCustData.salesmen ,CustandSup:newCustData.CustandSup  ,maxdepit:newCustData.maxdepit};
      setOptionsCustomers([newCust, ...optionsCustomers]);
      setValueCustomers(newCust);
      changAutoCustomers(newCust);
    }
  };
  const reUpdateCust =  (updateData) => {
    if (updateData != null) {
   
      var otherOptions = optionsCustomers.filter((obj) => obj.id != updateData.id);
      let newCust = { id: updateData.id, name: updateData.name, Cust_inv_type: updateData.Cust_inv_type, acc_no: updateData.acc_no, 
        salesmen: updateData.salesmen ,CustandSup:updateData.CustandSup  ,maxdepit:updateData.maxdepit};
      setOptionsCustomers([newCust, ...otherOptions]);
      setValueCustomers(newCust);
      changAutoCustomers(newCust);
    }
    console.log("the oldCurr is ", updateData);
  };



  const loadGetCountSafeByGroup = async () => {
    const urlts =
      uri +
      `TaswyaBasicData/countCurrBySafe?safe=${valueSafes.id}
      &&branch=${sto.branchi}`;
    await axios
      .get(urlts)
      .then((res) => {
        setCountCurrBySafe(res.data);
        if (!permiElm.hasOwnProperty("SIM"))
          setPermiElm({ ...permiElm, SIM: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const reGet = async (takei, igroupid, sSearch) => {
    if (sSearch != "" || igroupid != -1 || takei == -2) {
      takei != -1
        ? setAuto({ ...auto, group: "", groupSub: "" })
        : setSearchedCurrShow(sSearch);
      const tb = {
        Currencies: true, // wait:true,
        parms: {
          scurrenciestype: "I",
          iempid: sto.empi,
          safe: valueSafes.id,
          currShow: "M",
          currType: stor.invsType > 2 ? -1 : 2,
          igroupid: igroupid,
          takei: -1,
          sSearch: sSearch,
        },
        autos: {},
      };
      await axios
        .post(uri + "Res", tb)
        .then((res) => {
          setcuRows(res.data.Currencies);
        })
        .catch((error) => {
          /*console.log('no');*/
        });
    }
  };

  const [setting, setSetting] = useState({});
  const [style, setStyle] = useState({});

  const loadInsh = async () => {
    setSetting(location.state.setting);
    setStor(location.state.stor);
    loadGet(
      location.state.idata.branch,
      location.state.setting.PrTy_InPr,
      location.state.idata.create_user,
      location.state.setting.iformid,
      location.state.stor.invsType
    );
    loadSto(location.state.setting.cusType);
    setStorS(location.state.setting.stors);

    setStyle(location.state.style);

  };


  const loadInshDialog = async () => {
    setSetting(props.settingInv);
    setStor(props.invStor);
    loadGet(
      props.invData.branch,
      props.invData.ProcType_InvProc,
     props. invData.create_user,
      props.formid,
     props.invsType,
    );
    // loadSto(props.cusType);
    setStyle(props.invStyle);

   setStorS(props.settingInv.stors);
  };
  let setupReload = function () {
    // for update date in midnight
    const timeUntilMidnight = moment()
      .endOf("day")
      .add(10, "ms")
      .diff(moment(), "ms");
    return setTimeout(function () {
      setIData({ ...idata, trans_no: AutoOptions.maxShift?.id, date: moment(new Date()).format("YYYY-MM-DD") });

      setupReload();
    }, timeUntilMidnight);
  };

  /* =============================== */
  useEffect(() => {
    if(props.isDialog==false){
    loadInsh();
    setupReload();
    }else{
      loadInshDialog();
    }
  }, []);

  /* =============================== */

  const toDoIdata = (prop, value) => {
    setIData({ ...idata, [prop]: value });
  };
  const reInshAuto = (id, name) => {
    if (id == null && name == "") return insh.Auto;
    else return { id: id, name: name };
  };

  const loadIdata = (valCust, locStoTypes, Banks) => {
    let locR = getLS("DataRem", {});
    const inveTypeIndex = getInveTypeIndex(valCust.Cust_inv_type);

    setIData({
      ...location.state.idata,
      branch: locR.branchi,
      sales_emp: locR.empi,
      create_user: locR.idi,
      customer_name: valCust.name,
      cust_id: valCust.id,
      inv_type: AutoOptions.types[0].id,
      trans_no: AutoOptions.maxShift.id,
      date: moment(new Date()).format("YYYY-MM-DD"),
    });
    // eds change
  };

  const getCustInvType = (valCust) => {
    setUiElement(insh.UiElement);

    let kind_inv = 2;
    let locR = getLS("DataRem", {});

    const locSto = getLS("sto", {});



    if (AutoOptions.Customers[0].Cust_inv_type != null) {
      if (AutoOptions.Customers[0].Cust_inv_type == 0 || AutoOptions.Customers[0].Cust_inv_type == 2) {
        setUiElement({
          ...uiElement,
          stock: false,
          payMethod: false,
          bank: true,
        });
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);

        setPayment({ ...payment, paidup: 0, stay: 0 });

        setValueStock(AutoOptions.Stocks[0]);
        setSwitchState({ ...switchState, cashORnetworksw: false });
        setLbl({ ...lbl, chashNetwork: transForm("cash") });
        setIData({
          ...location.state.idata, inv_type: 2,
          pay_type: 1,
          bank: 0, cust_id: valCust.id,
          stock: AutoOptions.Stocks[0].id,
          customer_name: valCust.name,
          branch: sto.branchi,
          sales_emp: sto.empi,
          create_user: sto.idi,
          date: moment(new Date()).format("YYYY-MM-DD")
        });
        //setOptionsBank(insh.AutoBankOp);
        setValueType(AutoOptions.types[1]);


      } else if (AutoOptions.Customers[0].Cust_inv_type === 1) {
        setUiElement({
          ...uiElement,
          stock: false,
          payMethod: true,
          bank: true,
        });
        const reetS = reInshAuto(AutoOptions.Stocks[0].id, "");
        setValueStock(reetS);
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);
        setIData({
          ...location.state.idata,
          inv_type: 1, pay_type: -1, bank: -1, 
          cust_id: valCust.id,
          customer_name: valCust.name,
          branch: sto.branchi,
          sales_emp: sto.empi,
          create_user: sto.idi,
          date: moment(new Date()).format("YYYY-MM-DD")
        });
        setValueType(AutoOptions.types[0]);

      }
      else if (AutoOptions.Customers[0].Cust_inv_type === 3 || AutoOptions.Customers[0].Cust_inv_type === 4) {
        setUiElement({
          ...uiElement,
          stock: false,
          payMethod: false,
          bank: false,
        });
        setValueStock(AutoOptions.Stocks[0]);
        setValueBank(AutoOptions.Banks[0]); //getAutobanks(0);
        setPayment({ ...payment, paidup: 0, stay: 0 });
        setRemains({ ...remains, cashPart: 0, agelPart: 0, bankPart: 0, networkPart: 0 });
        setSwitchState({ ...switchState, cashORnetworksw: true });
        const text = transForm("network").toString();
        setLbl({ ...lbl, chashNetwork: text });


        setIData({
          ...location.state.idata, 
          inv_type: 2, 
          pay_type: 2,
          branch: sto.branchi,
          sales_emp: sto.empi,
          create_user: sto.idi,
          bank: AutoOptions.Banks[0].id, 
          cust_id: AutoOptions.Customers[0].id,         
          customer_name: valCust.name, date: moment(new Date()).format("YYYY-MM-DD")
        });
        setValueType(AutoOptions.types[1]); //getAutobanks(0);
      }
    } else {
      setValueStock(AutoOptions.Stocks[0]); //setOptionsBank(insh.AutoBankOp);
      setLbl({ ...lbl, chashNetwork: transForm("cash") });
      setUiElement({
        ...uiElement,
        stock: false,
        payMethod: false,
        bank: true,
      });
      setSwitchState({ ...switchState, cashORnetworksw: false });
      setIData({
        ...location.state.idata, inv_type: 2,
        branch: locR.branchi,
        stock: AutoOptions.Stocks[0].id,
        sales_emp: sto.empi,
        create_user: locR.idi,
        pay_type: 1, bank: 0, cust_id: valCust.id,
        customer_name: valCust.name, date: moment(new Date()).format("YYYY-MM-DD")
      });

      setValueType(AutoOptions.types[1]);

    }
  }




  const changAutoType = (newValue) => {

    console.log(newValue);
    setSwitchState({ ...switchState, cashORnetworksw: false });
    setLbl({ ...lbl, chashNetwork: transForm("cash") });
    setValueType(newValue);

    let invT = newValue.id;
    if ( newValue.id === 2) {
      if (switchState.cashORnetworksw ===false) {
        setUiElement({
          ...uiElement,
          stock: false,
          payMethod: false,
          bank: true,
        });

        setPayment({ ...payment, paidup: idata.netAmount, stay: 0 });
        setRemains({ ...remains, cashPart: idata.netAmount, agelPart: 0, bankPart: 0, networkPart: 0 });

        const reet = reInshAuto(0, "");
        setValueBank(reet);
        setValueStock(AutoOptions.Stocks[0]); // getAutoStocks(0);
        setSwitchState({ ...switchState, cashORnetworksw: false });
        setLbl({ ...lbl, chashNetwork: transForm("cash") });

        setIData({ ...idata, pay_type: 1, bank: 0,stock:AutoOptions.Stocks[0].id, cust_id: valueCustomers.id, customer_name: valueCustomers.name, inv_type: invT });
      }
      else if (switchState.cashORnetworksw == true) {
        setUiElement({ ...uiElement, bank: false });
        setPayment({ ...payment, paidup: idata.netAmount, stay: 0 });
        setRemains({ ...remains, cashPart: 0, agelPart: 0, bankPart: 0, networkPart: idata.netAmount });

        setValueStock(AutoOptions.Stocks[0]); // getAutoStocks(0);
        setSwitchState({ ...switchState, cashORnetworksw: true });
        setLbl({ ...lbl, chashNetwork: transForm("network") });
        setValueBank(AutoOptions.Banks[0]); //getAutobanks(0);
        setIData({
          ...idata,
          pay_type: 2,
          bank: AutoOptions.Banks[0].id,
          cust_id: valueCustomers.id, customer_name: valueCustomers.name,
          inv_type: invT,
        });
      }else{
        setUiElement({
          ...uiElement,
          stock: false,
          payMethod: false,
          bank: true,
        });

        setPayment({ ...payment, paidup: idata.netAmount, stay: 0 });
        setRemains({ ...remains, cashPart: idata.netAmount, agelPart: 0, bankPart: 0, networkPart: 0 });

        const reet = reInshAuto(0, "");
        setValueBank(reet);
        setValueStock(AutoOptions.Stocks[0]); // getAutoStocks(0);
        setSwitchState({ ...switchState, cashORnetworksw: false });
        setLbl({ ...lbl, chashNetwork: transForm("cash") });

        setIData({ ...idata, pay_type: 1, bank: 0,stock:AutoOptions.Stocks[0].id, cust_id: valueCustomers.id, customer_name: valueCustomers.name, inv_type: invT });
      }
    }
    else if ( newValue.id === 1) {
      if (parseFloat(payment.paidup)  > 0 && parseFloat(payment.stay) > 0) {
        if (switchState.cashORnetworksw === false) {
          setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });

          setIData({
            ...idata,
            pay_type: -1,
            bank: -1,
            stock:AutoOptions.Stocks[0].id,
            cash_part: payment.paidup,
            Pay_mouny: payment.paidup,
            Remaining: payment.stay,
            network_part: 0,
            agel_part: payment.stay,
            emp_part: 0,
            bank_part: 0,
            check_refno: "",
            netAmountPMDcn: idata.netAmount,
            cust_id: valueCustomers.id, customer_name: valueCustomers.name
          });
        }
        else if (switchState.cashORnetworksw === true) {
          remains.cashPart = 0;
          remains.agelPart = payment.stay;
          remains.networkPart = payment.paidup;
          remains.bankPart = 0;
          setRemains({ ...remains, cashPart: 0, agelPart: payment.stay, bankPart: 0, networkPart: payment.paidup });
          setIData({
            ...idata,
            pay_type: -1,
            bank: valueBank.id,
            cash_part: 0,
            Pay_mouny: payment.paidup,
            Remaining: payment.stay,
            network_part: payment.paidup,
            agel_part: payment.stay,
            emp_part: 0,
            bank_part: 0,
            check_refno: "",
            netAmountPMDcn: idata.netAmount,
            inv_type: invT,
            cust_id: valueCustomers.id, customer_name: valueCustomers.name
          });
          
        }else{
          setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });
          setIData({
            ...idata,
            pay_type: -1,
            bank: -1,
            stock:AutoOptions.Stocks[0].id,
            cash_part: payment.paidup,
            Pay_mouny: payment.paidup,
            Remaining: payment.stay,
            network_part: 0,
            agel_part: payment.stay,
            emp_part: 0,
            bank_part: 0,
            check_refno: "",
            netAmountPMDcn: idata.netAmount,
            cust_id: valueCustomers.id, customer_name: valueCustomers.name
          });
        }

      } else if (parseFloat(payment.paidup) > 0 && parseFloat(payment.stay) === 0) {
        setUiElement({ ...uiElement, stock: false, payMethod: true, bank: true });
        setPayment({ ...payment, paidup: 0, stay: idata.netAmount });

        setRemains({ ...remains, cashPart: 0, agelPart: idata.netAmount, bankPart: 0, networkPart: 0 });


        const reetS = reInshAuto(AutoOptions.Stocks[0].id, "");
        setValueStock(reetS);
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);
        setIData({
          ...idata,
          pay_type: -1,
          bank: -1,
          Remaining:idata.netAmount,
          Pay_mouny:0,
          agel_part:idata.netAmount,
          inv_type: invT,
        });
      }
      else {
        setUiElement({ ...uiElement, stock: false, payMethod: true, bank: true });
        setPayment({ ...payment, paidup: 0, stay: idata.netAmount });

        setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });
        const reetS = reInshAuto(AutoOptions.Stocks[0].id, "");
        setValueStock(reetS);
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);
        setIData({
          ...idata, pay_type: -1, bank: -1, inv_type: invT
          , agel_part: payment.stay, Remaining: payment.stay,
          cash_part: payment.paidup
        });
  
      }
    }
    console.log(" the inv_type is", invT);
  };

  
  const changAutoss = async (e, prop) => {
    switch (prop) {
      case "group":
        await reGet(-1, e, "");
        setAuto({ ...auto, [prop]: e, groupSub: "" });
        getSubGroups(e);
        break;
      case "groupSub":
        reGet(-1, e, "");
      default:
        setAuto({ ...auto, [prop]: e });
    }
  };

  
  const changAutoCustomers = (newValue) => {

    console.log(newValue);
    setSwitchState({ ...switchState, cashORnetworksw: false });
    setLbl({ ...lbl, chashNetwork: transForm("cash") });

    let invT = null;
    setValueCustomers(newValue);
    newValue.Cust_inv_type == 1
      ? setValueType(AutoOptions.types[0])
      : setValueType(AutoOptions.types[1]);
    newValue.Cust_inv_type == 1
      ? (invT = AutoOptions.types[0].id)
      : (invT = AutoOptions.types[1].id);

    if (invT === 2) {
      
      if (newValue.Cust_inv_type != null) {
        if (newValue.Cust_inv_type == 0 || newValue.Cust_inv_type == 2 || newValue.Cust_inv_type == 5) {
          setUiElement({
            ...uiElement,
            stock: false,
            payMethod: false,
            bank: true,
          });
          remains.cashPart = idata.netAmount;
          remains.agelPart = 0;
          remains.networkPart = 0;
          remains.bankPart = 0;
          setPayment({ ...payment, paidup: idata.netAmount, stay: 0 });
          setRemains({ ...remains, cashPart: idata.netAmount, agelPart: 0, bankPart: 0, networkPart: 0 });


          const reet = reInshAuto(0, "");
          setValueBank(reet);
          setValueStock(AutoOptions.Stocks[0]); // getAutoStocks(0);
          setSwitchState({ ...switchState, cashORnetworksw: false });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });

          setIData({
            ...idata,
            pay_type: 1,
            bank: 0,
            inv_type: invT,
            customer_name: newValue.name,
            cust_id: newValue.id,
          });
        } else if (newValue.Cust_inv_type == 3 || newValue.Cust_inv_type == 4) {

          const text = transForm("network").toString();
          setLbl({ ...lbl, chashNetwork: text });
          setValueBank(AutoOptions.Banks[0]); //getAutobanks(0);
          setUiElement({ ...uiElement, bank: false });
          setSwitchState({ ...switchState, cashORnetworksw: true });
          setRemains({ ...remains, cashPart: 0, agelPart: 0, bankPart: 0, networkPart: idata.netAmount });
          setPayment({ ...payment, paidup: idata.netAmount, stay: 0 });
          setIData({
            ...idata,
            pay_type: 2,
            bank: AutoOptions.Banks[0].id,
            inv_type: invT,
            customer_name: newValue.name,
            cust_id: newValue.id,
          });
        }
      }
      else {
        setUiElement({
          ...uiElement,
          stock: false,
          payMethod: false,
          bank: true,
        });

        setPayment({ ...payment, paidup: idata.netAmount, stay: 0 });
        setRemains({ ...remains, cashPart: idata.netAmount, agelPart: 0, bankPart: 0, networkPart: 0 });

        const reet = reInshAuto(0, "");
        setValueBank(reet);
        setValueStock(AutoOptions.Stocks[0]); // getAutoStocks(0);
        setSwitchState({ ...switchState, cashORnetworksw: false });
        setLbl({ ...lbl, chashNetwork: transForm("cash") });

        setIData({
          ...idata,
          pay_type: 1,
          bank: 0,
          inv_type: invT,
          customer_name: newValue.name,
          cust_id: newValue.id,
        });
      }
    }
    else if (invT === 1) {

      if (parseFloat(payment.paidup)  > 0 && parseFloat(payment.stay) > 0) {


        if (switchState.cashORnetworksw === false) {
          setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });

          setIData({
            ...idata,
            pay_type: -1,
            bank: -1,
            stock: valueStock.id,
            cash_part: payment.paidup,
            Pay_mouny: payment.paidup,
            Remaining: payment.stay,
            network_part: 0,
            agel_part: payment.stay,
            emp_part: 0,
            bank_part: 0,
            check_refno: "",
            netAmountPMDcn: idata.netAmount,
            customer_name: newValue.name, cust_id: newValue.id, inv_type: invT,
          });
        }
        else if (switchState.cashORnetworksw === true) {
          remains.cashPart = 0;
          remains.agelPart = payment.stay;
          remains.networkPart = payment.paidup;
          remains.bankPart = 0;
          setRemains({ ...remains, cashPart: 0, agelPart: payment.stay, bankPart: 0, networkPart: payment.paidup });

          setIData({
            ...idata,
            pay_type: -1,
            bank: valueBank.id,
            cash_part: 0,
            Pay_mouny: payment.paidup,
            Remaining: payment.stay,
            network_part: payment.paidup,
            agel_part: payment.stay,
            emp_part: 0,
            bank_part: 0,
            check_refno: "",
            netAmountPMDcn: idata.netAmount,
            customer_name: newValue.name, cust_id: newValue.id, inv_type: invT,
          });
        }
        else{
          setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });

          setIData({
            ...idata,
            pay_type: -1,
            bank: -1,
            stock: valueStock.id,
            cash_part: payment.paidup,
            Pay_mouny: payment.paidup,
            Remaining: payment.stay,
            network_part: 0,
            agel_part: payment.stay,
            emp_part: 0,
            bank_part: 0,
            check_refno: "",
            netAmountPMDcn: idata.netAmount,
            customer_name: newValue.name, cust_id: newValue.id, inv_type: invT,
          });
        }

      } else if (parseFloat(payment.paidup) > 0 && parseFloat(payment.stay) === 0) {
        setUiElement({ ...uiElement, stock: false, payMethod: true, bank: true });
        setPayment({ ...payment, paidup: 0, stay: idata.netAmount });

        setRemains({ ...remains, cashPart: 0, agelPart: idata.netAmount, bankPart: 0, networkPart: 0 });


        const reetS = reInshAuto(AutoOptions.Stocks[0].id, "");
        setValueStock(reetS);
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);
        setIData({
          ...idata,
          pay_type: -1,
          bank: -1,
          Remaining:idata.netAmount,
          Pay_mouny:0,
          agel_part:idata.netAmount,
          inv_type: invT,
          customer_name: newValue.name,
          cust_id: newValue.id,
        });
      }
      else {
        setUiElement({ ...uiElement, stock: false, payMethod: true, bank: true });
        setPayment({ ...payment, paidup: 0, stay: idata.netAmount });

        setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });
        const reetS = reInshAuto(AutoOptions.Stocks[0].id, "");
        setValueStock(reetS);
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);
        setIData({
          ...idata,
          pay_type: -1,
          bank: -1,
          inv_type: invT,
          customer_name: newValue.name,
          cust_id: newValue.id,
          agel_part: payment.stay,
          Remaining: payment.stay,
          cash_part: payment.paidup
        });
      }
    }

    // else if (invT == 1) {

    // }

    console.log(" the inv_type is", invT);
  };




  const changAutoSalesMen = (newValue) => {
    setValueSalesmen(newValue);
    toDoIdata("salesman", newValue.id);
  };

  const changAutoEmployee = (newValue) => {
    setValueEmployePMD(newValue);
    toDoIdata("emp", newValue.id);
  };

  const changAutoSafes = (newValue) => {
    setValueSafes(newValue);
    toDoIdata("safe", newValue.id);
  };
  const changAutoStock = (newValue) => {
    setValueStock(newValue);
    toDoIdata("stock", newValue.id);
  };
  const changAutoOmla = (newValue) => {
    setOmlaValue(newValue);
    toDoIdata("omla", newValue.id);
    // setcuRows(gets.Currencies?.map(c=>{
    //   return {...c, sale_price: c.sale_price * newValue.exchange }
    // }));
  };
  const changAutoCostcenter = (newValue) => {
    setValueCostcenter(newValue);
    toDoIdata("center", newValue.id);
  };
  const changAutoBank = (newValue) => {
    setValueBank(newValue);
    toDoIdata("bank", newValue.id); /*setValueBankPMD(newValue);*/
  };
  const changAutoBankPMD = (newValue) => {
    setValueBankPMD(newValue);
    toDoIdata("bank2", newValue.id);
  };
  const changAutoBank2 = (newValue) => {
    setValueBankTransPMD(newValue);
    toDoIdata("bank2", newValue.id);
  };

  const changAutoPricePay = (newValue) => {

    setValuePricePay(newValue);

     if (valueItemUnits.unit ===1) { getTypePrice(newValue);}
    
    // currUnit.length > 0 &&
    //   changCurrencieToinv(newValue.id, insh.stateChang.taxperc);
  };
  const getTypePrice=(newVal)=>{
    let newPrice=parseFloat(subInfo.Price);
    let prc = setting.cusType == 1 ? subInfo.salePrice : subInfo.PricePu;
    if (newVal.id===1){newPrice=parseFloat(prc);}
    else if (newVal.id===2 ){newPrice=parseFloat(subInfo.dPrice);}
    else if (newVal.id===3){newPrice=parseFloat(subInfo.jPrice);}
    else{newPrice=parseFloat(prc);}

    let totbef = (parseFloat(newPrice) * parseFloat(subInfo.val1));
    let tot = (parseFloat(totbef) - parseFloat(subInfo.discount));

    let restaxvalA = maths(
      parseFloat(tot),
      parseFloat(subInfo.taxperc),
      "taxval"
    );
    setSubInfo({
      ...subInfo,
      Price:newPrice,
      totJust: tot,
      taxval: restaxvalA,
    });
  };

  const changAutoCurrUnits = (op, newValue) => {
    setIndexUnit(op.nativeEvent.target.dataset.optionIndex);
    setValueItemsUnits(newValue);
    setValueCurrUnits(newValue);
    LetvalueCurrUnits = newValue;
    insh.stateChang.taxperc = false;

    setValuePricePay(insh.optionPricePay[0]);
    let prc = setting.cusType == 1 ? newValue.sale : newValue.purch;
    let totbef = (parseFloat(prc) * parseFloat(subInfo.val1));
    let tot = (parseFloat(totbef) - parseFloat(subInfo.discount));

    let restaxvalA = maths(
      parseFloat(tot),
      parseFloat(subInfo.taxperc),
      "taxval"
    );
    setSubInfo({
      ...subInfo,
      Price: setting.cusType == 1 ? newValue.sale : newValue.purch,
      perc: newValue.perc,
      totJust: tot,
      taxval: restaxvalA,
      PricePu: newValue.purch,
      salePrice:newValue.sale,
    });
  };
  const [cuRows, setcuRows] = useState({});

  const currShowSearch = (e) => {
    setSearchedCurrShow(e.target.value);

    if (e.target.value == "") {
      const locSto = JSON.parse(localStorage.getItem("sto")) || {};
      setcuRows(locSto.Currencies);
      setAuto({ ...auto, group: "", groupSub: "" });
      setGroupsSub([]);
    }
  };
  const [searchedInvShow, setSearchedInvShow] = useState("");
  const [searchedCurrShow, setSearchedCurrShow] = useState("");

  // fillter groups

  const changFavSlides = (val) => {
    const res = gets.CurrenciesFav.filter((x) => x.favorite === val);
    setFavGroups(res);
  };

  const changSlides = (e, t) => {
    if (t === "Gmain") getSubGroups(e);

    const filteredCuRows = gets.Currencies.filter((row) => {
      return (
        row.symbol.toLowerCase().includes(e) ||
        row.nameG.toLowerCase().includes(e) ||
        row.barcode.toLowerCase().includes(e) ||
        row.sale_price.toString().toLowerCase().includes(e)
      );
    });
    setcuRows(filteredCuRows);
  };
  const [checked, setChecked] = useState(insh.Checked);
  const RestAfterGetCurrMathRate = () => {
    setInvDisc(insh.InvDisc);
    afterGetCurrMathRate("");
  };
  const changChcked = (event) => {
    if (
      event.target.name === "discMinusck" ||
      event.target.name === "discRateck"
    ) {
      RestAfterGetCurrMathRate();

      if (event.target.name === "discMinusck") {
        setChecked({
          ...checked,
          discMinusck: event.target.checked,
          discRateck: false,
        });
        setUiElement({ ...uiElement, rate: true, minus: false });
      } else if (event.target.name === "discRateck") {
        setChecked({
          ...checked,
          discMinusck: false,
          discRateck: event.target.checked,
        });
        setUiElement({ ...uiElement, rate: false, minus: true });
      }
    }

    if (event.target.name === "networkck" || event.target.name === "cashck") {
      setValueBank(insh.AutoBank); //setOptionsBank(insh.AutoBankOp);
      setValueBankPMD(insh.AutoBank); //setOptionsBankPMD(insh.AutoBankOp);

      if (event.target.name === "networkck") {
        setChecked({ ...checked, networkck: true, cashck: false });
        setUiElement({ ...uiElement, bank: false });
        setValueBank(AutoOptions.Banks[0]); //getAutobanks(0);
        setValueBankPMD(AutoOptions.Banks[0]); //getAutobanksPMD(0);
      } else if (event.target.name === "cashck") {
        setChecked({ ...checked, networkck: false, cashck: true });
        setUiElement({ ...uiElement, bank: true });
        // setI_Data({...idata, pay_type:1 });
      }
    }

    /* ======== for payment Diloag ======== */
    if (event.target.name == "paymentDiloagck") {
      setChecked({ ...checked, paymentDiloagck: event.target.checked });
    } else if (event.target.name == "network_partck") {
      setChecked({
        ...checked,
        network_partck: event.target.checked,
        cash_partck: true,
      });
      if (event.target.checked === true) {
        setValueBankPMD(AutoOptions.Banks[0]);
        /*getAutobanksPMD(0);*/ changChckedPM("", "", "", "", "bankPM");
        chashVSnetwork(true);
        setUiElement({ ...uiElement, bankPM: false });
      } else if (event.target.checked === false) {
        MathChckedIdata("network_part", idata.network_part);
        changAutosPM("", "", "", "bankPM");
        chashVSnetwork(false);
        setUiElement({ ...uiElement, bankPM: true });
      }
    } else if (event.target.name === "agel_partck") {
      setChecked({
        ...checked,
        agel_partck: event.target.checked,
        cash_partck: true,
      });
      if (event.target.checked === false) {
        MathChckedIdata("agel_part", idata.agel_part);
      }
    } else if (event.target.name === "bank_partck") {
      setChecked({ ...checked, bank_partck: event.target.checked });
      if (event.target.checked === true) {
        setValueBankTransPMD(AutoOptions.Banks[0]);
        /*getAutobanks2PMD(0);*/ changChckedPM("", "", "", "", "bankPM2");
      } else if (event.target.checked === false) {
        MathChckedIdata("bank_part", idata.bank_part);
        changAutosPM("", "", "", "bankPM2");
      }
    } else if (event.target.name === "emp_partck") {
      setChecked({ ...checked, emp_partck: event.target.checked });
      if (event.target.checked === true) {
        setValueEmployePMD(gets.Employees[0]);
        /*getAutoEmployePMD(0);*/ changChckedPM("", "", "", "", "empPM");
      } else if (event.target.checked === false) {
        MathChckedIdata("emp_part", idata.emp_part);
        changAutosPM("", "", "", "empPM");
      }
    } else if (event.target.name === "autoOrStaticck") {
      setChecked({ ...checked, autoOrStaticck: event.target.checked });
    }
  }; // end checked

  const fltringAutoCurrUnits = (chitem) => {
    setIndexUnit(0);
    const res = [];
    setCurrUnitsAll(chitem);
    if (checked.autoOrStaticck == false) {
      setOptionsCurrUnits(chitem);
      setValueCurrUnits(chitem[0]);
      LetvalueCurrUnits = chitem[0];
    } else LetvalueCurrUnits = chitem[0];
  };
  const BefBarcodedataCurrencie = async (id, ious) => {
    setItemId(id);
    setCurrUnit(ious);
    // permiCuCo.DTSP = false;
    await fltringAutoCurrUnits(ious);
    insh.stateChang.taxperc = true;
    await fltringCurrencieToinv(
      ious[0],
      valuePricePay.id,
      insh.stateChang.taxperc
    );
    // eds change
    await changeCountCurr(ious[0].barcode);
  };

  const onRowClicks = (rowData) => {
    if (checked.autoOrStaticck == true) {

      getBarcodeMulti(rowData?.barcode);
    } else {
      rowClickNormal(rowData?.barcode);
    }
  };
  const rowClickNormal = async (codeItem) => {

    let item = null;
    let unitF = null;
    let ibance = null;
    if (codeItem != null) {
      await axios
        .get(
          uri +
          `RptSafes/CurrCodesUnitsSub?currCode=${codeItem}
        &&emp=${sto.empi}
        &&safe=${valueSafes.id}`
        )
        .then((res) => {

          item = res.data?.barcodes[0];
          unitF = res.data?.unitsSub[0];
          ibance = res.data?.balance[0];
          setValueItemsUnits(res.data?.unitsSub[0]);
          setValueCurrUnits(res.data?.unitsSub[0]);
          setOptionsCurrUnits(res.data?.unitsSub);
          setOptionsCurrUnitsTable(res.data?.unitsSub);
         setCurrUnit(res.data?.unitsSub);
        })
        .catch((err) => {
          toast.error("لا يوجد صنف لهذا الباركود");
          console.log(err);
        });
      if (item != null && ibance == null) {
        await getInData(item, unitF, 0);
      } else if ((item != null && ibance != null)) {
        await getInData(item, unitF, ibance?.Qtys);
      }
      else {
        toast.error("لا يوجد صنف لهذا الباركود");
      }
    }

  };

  const rowClick = async (codeItem) => {

    let item = null;
    let unitF = null;
    let ibance = null;
    if (codeItem != null) {
      await axios
        .get(
          uri +
          `RptSafes/CurrCodesUnitsSub?currCode=${codeItem}
        &&emp=${sto.empi}
        &&safe=${valueSafes.id}`
        )
        .then((res) => {

          item = res.data?.barcodes[0];
          unitF = res.data?.unitsSub[0];
          ibance = res.data?.balance[0];
          setValueItemsUnits(res.data?.unitsSub[0]);
          setValueCurrUnits(res.data?.unitsSub[0]);
          setOptionsCurrUnits(res.data?.unitsSub);
          setOptionsCurrUnitsTable(res.data?.unitsSub);
         setCurrUnit(res.data?.unitsSub);
        })
        .catch((err) => {
          toast.error("لا يوجد صنف لهذا الباركود");
          console.log(err);
        });
      if (item != null && ibance == null) {
        await getInData(item, unitF, 0);
      } else if ((item != null && ibance != null)) {
        await getInData(item, unitF, ibance?.Qtys);
      }
      else {
        toast.error("لا يوجد صنف لهذا الباركود");
      }
    }

  };
  const getInData = (dataCode, unitF, ibance) => {
    setItemId(dataCode.curr);

    setCurrUnit(unitF);
    let pricei = 0.0;
    let totJust = 0.0;
    let taxval = 0.0;
    let taxperc = 0.0;
    let diaperc = 0.0;
    let dismount = 0.0;
    let valperc = 0.0;
    if (setting.PrTy_InPr === "22") {
      taxperc = 0.0;
      pricei = parseFloat(dataCode?.purch).toFixed(2);
    } else if (setting.PrTy_InPr === "02" || setting.PrTy_InPr === "12" || setting.PrTy_InPr === "03") {
      pricei = parseFloat(dataCode?.sale);
      taxperc = (parseFloat(dataCode.tax)).toFixed(2);
    }
    else {
      pricei = parseFloat(dataCode?.purch);
      taxperc = (parseFloat(dataCode.tax)).toFixed(2);
    };

    if (dataCode.discount == null) { dataCode.discount = 0; };
    if (dataCode.amount_disc == null) { dataCode.amount_disc = 0; };

    taxval = ((parseFloat(pricei) * parseFloat(dataCode.tax)) / 100).toFixed(2);
    totJust = (parseFloat(pricei)).toFixed(2);
    diaperc = (parseFloat(dataCode.discount)).toFixed(2);
    dismount = (parseFloat(dataCode.amount_disc)).toFixed(2);
    valperc = (parseFloat(dataCode.perc)).toFixed(2);

    setSubInfo({
      ...subInfo,
      val1: 1,
      is_tab3_curr: dataCode?.is_tab3_curr,
      id: dataCode.curr,
      minPrice: dataCode.mPrice,
      Price: pricei,
      val: dataCode.perc,
      barcode: dataCode.barcode,
      symbol: dataCode.symbol,
      type: dataCode.ctype,
      perc: dataCode.perc,
      taxperc: taxperc,
      totJust: parseFloat(pricei).toFixed(2),
      discount: 0,
      DiscPers: 0,
      taxval: ((pricei * taxperc) / 100).toFixed(2),
      unitame: unitF.unitName,
      jPrice:parseFloat(dataCode.jPrice),
      dPrice:parseFloat(dataCode.dPrice),
      salePrice:parseFloat(dataCode.sale),
      PricePu:parseFloat(dataCode.purch),
    });
    setCounts({ curr: ibance });
  };

  const BefrowDataCurrencie = async (id, ious) => {
    setItemId(id);

    setCurrUnit(ious);
    // permiCuCo.DTSP = false;
    await fltringAutoCurrUnits(ious);
    insh.stateChang.taxperc = true;
    await fltringCurrencieToinv(
      ious[0],
      valuePricePay.id,
      insh.stateChang.taxperc
    );
    await changeCountCurr(ious[0].barcode);
  };

  const changeCountCurr = (bc) => {
    try {
      const resCurr = [];
      countCurrBySafe.map((x) => {
        if (x.barcode == bc && x.safe == valueSafes.id) resCurr.push(x);
      });
      if (checked.autoOrStaticck === false) {
        resCurr.length != 0
          ? setCounts({ curr: resCurr[0].Qtys })
          : setCounts({ curr: 0 });
      } else {
        resCurr.length != 0
          ? (counts.curr = resCurr[0].Qtys)
          : (counts.curr = 0);
      }
    } catch (error) {
      alert("هناك خطاء في الصنف قم برماجعة الدعم الفني");
    }
  };

  const retPrmiSDPandSDV = (val, prmPandV, f) => {
    if (val > prmPandV && f === "DiscP") {
      alert(`
قيمة الخصم المدخله أكبر من قيمة الخصم المسموح
قيمة الخصم الممنوحه: ${prmPandV}
سيتم تصفير قيمة الخصم .. قم بإدخال القيمة المسموح بها مره أخرى والتي أعلى قيمه لها : ${prmPandV}
      `);
      return "";
    } else if (val > prmPandV && f === "DiscC") {
      alert(`
قيمة الخصم المدخله أكبر من قيمة الخصم المسموح
قيمة الخصم الممنوحه: ${prmPandV}
سيتم تصفير قيمة الخصم .. قم بإدخال القيمة المسموح بها مره أخرى والتي أعلى قيمه لها : ${prmPandV}
      `);
      return "";
    }
  };


  const changInfo = (prop) => (event) => {
    if (prop === "discount") {
      subInfo.DiscPers = (
        (event.target.value * 100) /
        parseFloat(subInfo.val1 * subInfo.Price)
      ).toFixed(2);
      let ret = null;
      if (permistionInv.SDV !== 0)
        ret = retPrmiSDPandSDV(
          parseFloat(event.target.value),
          permistionInv.SDV,
          "DiscC"
        );
      else ret = retPrmiSDPandSDV(subInfo.DiscPers, permistionInv.SDP, "DiscP");
      if (ret === "") {
        event.target.value = "";
        subInfo.DiscPers = "";
      }
    } else if (prop === "DiscPers") {
      subInfo.discount = (
        parseFloat(subInfo.val1 * subInfo.Price) *
        (event.target.value / 100)
      ).toFixed(2);
      let ret = null;
      if (permistionInv.SDP !== 0)
        ret = retPrmiSDPandSDV(
          parseFloat(event.target.value),
          permistionInv.SDP,
          "DiscP"
        );
      else ret = retPrmiSDPandSDV(subInfo.discount, permistionInv.SDV, "DiscC");
      if (ret === "") {
        event.target.value = "";
        subInfo.discount = "";
      }
    } else if (prop === "Price") {
      subInfo.discount = (
        parseFloat(subInfo.val1 * event.target.value) *
        (subInfo.DiscPers / 100)
      ).toFixed(2);
      subInfo.totJust = parseFloat(subInfo.val1 * event.target.value).toFixed(2);
      subInfo.taxval = parseFloat(
        (subInfo.totJust * subInfo.taxperc) / 100
      ).toFixed(2);
    } else if (prop === "totJust") {
      let prce = parseFloat(event.target.value / subInfo.val1).toFixed(2);
      subInfo.Price = prce;
      subInfo.discount = (
        parseFloat(subInfo.val1 * prce) *
        (subInfo.DiscPers / 100)
      ).toFixed(2);
      subInfo.taxval = parseFloat(
        (subInfo.totJust * subInfo.taxperc) / 100
      ).toFixed(2);
    } else if (prop === "val1") {
      subInfo.discount = (
        parseFloat(event.target.value * subInfo.Price) *
        (subInfo.DiscPers / 100)
      ).toFixed(2);
      subInfo.totJust = parseFloat(event.target.value * subInfo.Price).toFixed(
        2
      );
      subInfo.taxval = parseFloat(
        (subInfo.totJust * subInfo.taxperc) / 100
      ).toFixed(2);
    } else if (prop === "taxperc") {
      subInfo.taxval = parseFloat(
        (subInfo.totJust * event.target.value) / 100
      ).toFixed(2);
    }

    setSubInfo({ ...subInfo, [prop]: event.target.value });
  };

  const changInvs = (prop) => (event) => {
    setInfoInv({ ...infoInv, [prop]: event.target.value });
    setIData({ ...idata, InvId_Return: event.target.value });
  };

  const handClickInvs = async () => {
    if (infoInv.InvId_Return === "") {
      handOSnack({
        vertical: "top",
        horizontal: "right",
        severit: "warning",
        text: "أدخل رقم المرتجع",
        time: 2000,
      });
    } else {
      let isExsist = false,
        pipt = setting.PrTy_InPr == "12" ? "02" : "01",
        piptName = setting.PrTy_InPr == "12" ? "مبيعات" : "مشتريات";
      await axios
        .get(
          uri + `InvRet?PcIdIvPc=${setting.PrTy_InPr}&&branch=${sto.branchi}`
        )
        .then((res) => {
          console.log("res.data");
          console.log(res.data);
          for (let i = 0; i < res.data.length; i++) {
            if (
              parseInt(res.data[i].InvId_Return) ==
              parseInt(infoInv.InvId_Return)
            )
              isExsist = true;
          }
        })
        .catch((error) => { });

      // alert(isExsist)
      if (isExsist == true) {
        handOSnack({
          vertical: "top",
          horizontal: "right",
          severit: "warning",
          text: `فاتورة ${piptName} المدخلة تم إرجاعها مسبقا`,
          time: 4000,
        });
      } else {
        await axios
          .get(
            uri +
            `InvRet?id=${infoInv.InvId_Return}&&PcIdIvPc=${pipt}&&branch=${sto.branchi}`
          )
          .then((res) => {
            showChosedInv(false, res.data.proc_id);

            setOr({ ...or, postput: "toPost" });
          })
          .catch((error) => {
            handOSnack({
              vertical: "top",
              horizontal: "right",
              severit: "warning",
              text: "رقم المرتجع غير موجود",
              time: 2000,
            });
          });
      } // else c
    } // else m

    // eds changes
    if (!stor.hasOwnProperty("comt")) setStor({ ...stor, comt: -1 });
  };
  const beZero = () => {
    setStor({ ...stor, comt: -1 });
    if (subInfo.discount === "") subInfo.discount = 0;
    if (subInfo.DiscPers === "") subInfo.DiscPers = 0;
    if (subInfo.Price === "") subInfo.Price = 0;
    if (subInfo.val1 === "") subInfo.val1 = 0;
    if (subInfo.perc === "") subInfo.perc = 0;
    if (subInfo.taxperc === "") subInfo.taxperc = 0;
    if (subInfo.taxval === "") subInfo.taxval = 0;
    if (subInfo.totJust === "") subInfo.totJust = 0;
  };

  const getrowDataCurrencie = async () => {
    beZero();
    or.cros = "newRow";
    or.PrAC = 0;

    if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" || setting.PrTy_InPr == "03") {
      if (subInfo.barcode != "") {
        if (parseFloat(subInfo.val1) != 0) {
          let val1InSub = 0,
            countsCurr = 0,
            minCurrPrice = 0,
            subInfoVal1_perc = subInfo.val1 * subInfo.perc;
          minCurrPrice = (parseFloat(subInfo.minPrice) * parseFloat(subInfo.perc)).toFixed(2);
          for (let m = 0; m < isdata.length; m++) {
            if (subInfo.barcode == isdata[m].barcode)
              val1InSub = isdata[m].val1;
          }
          countsCurr = parseInt(counts.curr) - parseInt(val1InSub);


          if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12") {

            if (permistionInv.SNOP === true && subInfo != null && parseFloat(subInfo.Price) < parseFloat(subInfo.PricePu)) {
              alert(
                `لا يمكن البيع اقل من سعر الشراء للصنف سعر الشراء المسجل للصنف هو : ${subInfo.PricePu}`
              );
            } else if (permistionInv.SNOMIN === 1 && parseFloat(subInfo.Price) < parseFloat(minCurrPrice)) {
              alert(
                `لا يمكن البيع اقل من السعر الادنى للصنف السعر الادنى المسجل للصنف هو : ${minCurrPrice}`
              );
            } else if (permistionInv.ASPZ === false && subInfo.Price <= 0) {
              alert(
                `لا يوجد لديك صلاحية البيع بسعر صفر `
              );
            }
            else if (permistionInv.SIM == false &&
              countsCurr <= subInfoVal1_perc &&
              (subInfo?.type == 1 || subInfo?.type == 3)) {
              alert(
                `
              الكمية المدخلة للصنف أكبر من رصيد المخزن
              الكمية الاجمالية المدخلة : ${parseFloat(subInfo.val1) * parseFloat(subInfo.perc)
                }
              رصيد المخزن : ${valueSafes.name} = ${parseInt(counts.curr) - parseInt(val1InSub)
                }
              `
              );
            }
            else if (permistionInv.APDC === 1 && permistionInv.DSOI == 1 && parseFloat(subInfo.DiscPers) > parseFloat(permistionInv.SDP)) {
              alert(
                `
              لقد تجاوزت نسبة الخصم المسموح بها
              نسبة الخصم المسموح بها : ${parseFloat(permistionInv.SDP)
                }
              `
              );
            }
            else if (permistionInv.APDC === 2 && permistionInv.DSOI == 1 && parseFloat(subInfo.discount) > parseFloat(permistionInv.SDV)) {
              alert(
                `
              لقد تجاوزت مبلغ الخصم المسموح بها
              مبلغ الخصم المسموح بة : ${parseFloat(permistionInv.SDV)
                }
              `
              );
            }
            else if (permistionInv.APDC === 3 && permistionInv.DSOI == 1 &&
              ((parseFloat(permistionInv.SDV) != 0 && subInfo.discount > permistionInv.SDV) ||
                (parseFloat(permistionInv.SDP) != 0 && parseFloat(subInfo.DiscPers) > parseFloat(permistionInv.SDP)))) {
              alert(
                `
              لقد تجاوزت حد الخصم المسموح بها
                }
              `
              );
            }
            else {
              for (let m = 0; m < isdata.length; m++) {
                if (
                  parseInt(subInfo.Price) ==
                  parseInt(isdata[m].new_exchange_price) &&
                  subInfo.barcode == isdata[m].barcode
                ) {
                  or.cros = "rnewRow";
                  or.i = m;
                  break;
                } else {
                  or.cros = "newRow";
                }
              }
              await handRowAdd(or);

              // await reCountCurr();
              await handleCloseCuDWithSWState();
              await handleClDia("fav", false);
              await setSubInfo(insh.InfoSub);



              setCurrUnit([]);
            }
          }
          else {
            for (let m = 0; m < isdata.length; m++) {
              if (
                parseInt(subInfo.Price) ==
                parseInt(isdata[m].new_exchange_price) &&
                subInfo.barcode == isdata[m].barcode
              ) {
                or.cros = "rnewRow";
                or.i = m;
                break;
              } else {
                or.cros = "newRow";
              }
            }

            await handRowAdd(or);
            await handleCloseCuDWithSWState();
            await handleClDia("fav", false);
            await setSubInfo(insh.InfoSub);

            setCurrUnit([]);
          }
        } /* end Val1 */ else {
          alert("لا يمكنك التنزيل الكمية 0");
        }
      } /* end barcode */ else {
        alert("لا يوجد باركود للصنف تحقق من البيانات");
      }
    } else {
      if (subInfo.barcode !== "") {
        if (parseFloat(subInfo.val1) !== 0) {
          let val1InSub = 0;
          for (let m = 0; m < isdata.length; m++) {
            if (subInfo.barcode == isdata[m].barcode)
              val1InSub = isdata[m].val1;
          }

          if (permistionInv.ASPZ === 1 || subInfo.Price > 0) {
            for (let m = 0; m < isdata.length; m++) {
              if (
                parseInt(subInfo.Price) ==
                parseInt(isdata[m].new_exchange_price) &&
                subInfo.barcode == isdata[m].barcode
              ) {
                or.cros = "rnewRow";
                or.i = m;
                break;
              } else {
                or.cros = "newRow";
              }
            }

            await handRowAdd(or);
            await handleCloseCuDWithSWState();
            await setSubInfo(insh.InfoSub);
            setCurrUnit([]);
          } /* price > 0 */ else alert("السعر اقل من صفر");
        } /* end Val1 */ else {
          alert("لا يمكنك التنزيل الكمية 0");
        }
      } /* end barcode */ else {
        alert("لا يوجد باركود للصنف تحقق من البيانات");
      }
    }
  };

  // const noGetThings = () => { return null;}
  const noGetThings = () => { };

  const handNewInv = () => {
    // alert('hand New Inv');
    setIshedden(false);
    setPdfToggle(null);
    reLoadLastInv();
    setIsData([]);
    setValueCustomers(AutoOptions.Customers[0]); //getAutoCustomers(0);
    setValueSalesmen(AutoOptions?.salesuser);
    setValueCostcenter(insh.Auto);
    // condshSet_ValueType
    const inveTypeIndex = getInveTypeIndex(optionsCustomers[0].Cust_inv_type);

    // changAutoType(AutoOptions.types[inveTypeIndex], false); //chang_AutoType(optionsType[1]);
    setValueStock(AutoOptions.Stocks[0]); //getAutoStocks(0);
    setOmlaValue(getDefaultOmla(gets.omlats));
    // const safe = getSafeDefault(gets.permisApp.user_id, gets.Users);
    let invKind = null;



    if (optionsCustomers[0].Cust_inv_type != null) {
      if (optionsCustomers[0].Cust_inv_type == 0 || optionsCustomers[0].Cust_inv_type == 2) {
        setValueBank(insh.AutoBank); //setOptionsBank(insh.AutoBankOp);
        setUiElement({ ...uiElement, bank: true });

        setSwitchState({ ...switchState, cashORnetworksw: false });
        setLbl({ ...lbl, chashNetwork: transForm("cash") });
        setUiElement({ ...uiElement, bank: true });
        setIData({
          ...location.state.idata, inv_type: 2, pay_type: 1, bank: 0, cust_id: optionsCustomers[0].id,
          customer_name: optionsCustomers[0].name, date: moment(new Date()).format("YYYY-MM-DD"), trans_no: AutoOptions.maxShift.id,
        });
        setValueStock(AutoOptions.Stocks[0]); //setOptionsBank(insh.AutoBankOp);
        setValueType(AutoOptions.types[1]);
        invKind = AutoOptions.types[1].id;
      } else if (optionsCustomers[0].Cust_inv_type == 1) {
        const reetS = reInshAuto(AutoOptions.Stocks[0].id, "");
        setValueStock(reetS);
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);
        setIData({
          ...location.state.idata, inv_type: 1, pay_type: -1, bank: -1, cust_id: optionsCustomers[0].id,
          customer_name: optionsCustomers[0].name, date: moment(new Date()).format("YYYY-MM-DD"), trans_no: AutoOptions.maxShift.id,
        });
        setValueType(AutoOptions.types[0]);
        invKind = AutoOptions.types[0].id;
      } else if (optionsCustomers[0].Cust_inv_type == 3 || optionsCustomers[0].Cust_inv_type == 4) {

        const text = transForm("network").toString();
        setLbl({ ...lbl, chashNetwork: text });
        setValueBank(AutoOptions.Banks[0]); //getAutobanks(0);
        setUiElement({ ...uiElement, bank: false });

        setSwitchState({ ...switchState, cashORnetworksw: true });
        setValueType(AutoOptions.types[1]); //getAutobanks(0);
        invKind = AutoOptions.types[1].id;
        setIData({
          ...location.state.idata, inv_type: 2, pay_type: 2, bank: AutoOptions.Banks[0].id, cust_id: optionsCustomers[0].id,
          customer_name: optionsCustomers[0].name, date: moment(new Date()).format("YYYY-MM-DD"), trans_no: AutoOptions.maxShift.id,
        });
      }
    } else {
      setValueBank(insh.AutoBank); //setOptionsBank(insh.AutoBankOp);
      setLbl({ ...lbl, chashNetwork: transForm("cash") });
      setUiElement({ ...uiElement, bank: true });
      setSwitchState({ ...switchState, cashORnetworksw: false });
      setIData({
        ...location.state.idata, inv_type: 2, pay_type: 1, bank: 0, cust_id: optionsCustomers[0].id,
        customer_name: optionsCustomers[0].name, date: moment(new Date()).format("YYYY-MM-DD"), trans_no: AutoOptions.maxShift.id,
      });
      setValueStock(AutoOptions.Stocks[0]); //setOptionsBank(insh.AutoBankOp);
      setValueType(AutoOptions.types[1]);
      setRemains(insh.Remains);
      invKind = AutoOptions.types[1].id;
    }
    const custType = optionsCustomers[0].Cust_inv_type == 3;

    setValueSafes(AutoOptions.Safes[0]);

    setSums(insh.Sums);
    setStor({ ...stor, comt: -1 });
    setExSub(0);
    setPayment(insh.Payment);
    setIsClick(insh.ished);
    setRemains(insh.Remains);


    setChecked({
      ...checked,
      costWithVat: true,
      discMinusck: false,
      discRateck: false,
      networkck: false,
      cashck: true,
      network_partck: false,
      cash_partck: false,
      agel_partck: false,
      bank_partck: false,
      emp_partck: false,
    });
    setOr(insh.Or);
    setIshed(insh.ished);
    setPermiElm({ ...permiElm, SaUp: permistionInv.Sa });
  };

  const goAlert = () => {
    handOSnack({
      vertical: "top",
      horizontal: "center",
      severit: "warning",
      // background: isPurches ? "#008080" : isStock ? "#3f51b5" : colors.green[500],
      text: `
      لقد تجاوزت نسبة الخصم المسموح بها
      نسبة الخصم المسموح بها : ${parseFloat(permistionInv.SDP)
        }`,
      time: 2000,
    });
    return 0;
  };
  const goAlertV = () => {
    alert(`
    لقد تجاوزت مبلغ الخصم المسموح به
    مبلغ الخصم المسموح بها : ${parseFloat(permistionInv.SDV)
      }`
    );

    return 0;
  };
  const goAlertVal = () => {

    return 1;
  };
  const goAlertPrice = () => {

    return 0;
  };
  var columnsInvSub = [
    { title: "المعرف", field: "id", hidden: true, rowStyle: stElemnt.SubR },
    {
      title: "رقم الصنف",
      field: "currency_from",
      hidden: true,
      rowStyle: stElemnt.SubR,
    },
    {
      title: "رقم الباركود",
      field: "barcode",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الصنف",
      field: "symbol",
      editable: "never",
      rowStyle: stElemnt.SubMu,
    },
    {
      title: "الوحدة",
      field: "unitame",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الكمية",
      field: "val1",
      editComponent: (props) => (
        <TextField
          variant="outlined"
          size="small"
          className={[clasTF.rootmt, clasTF.mtTval1]}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) =>
            props.onChange(
              e.target.value === "" ? goAlertVal() : e.target.value == 0 ? goAlertVal() : e.target.value
            )
          }
        //   onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      rowStyle: stElemnt.SubR,
    },
    {
      title: "العبوة",
      field: "val",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "السعر",
      field: "new_exchange_price",
      type: "numeric",
      editable: "always",
      editComponent: (props) => (
        <TextField
          disabled={!permistionInv.USP}
          variant="outlined"
          size="small"
          className={[clasTF.rootmt, clasTF.mtTpric]}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) =>
            props.onChange(
              e.target.value === "" ? goAlertPrice() : e.target.value
            )
          }
        //  onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الإجمالي",
      field: "totJust",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },

    {
      title: "قيمة الخصم",
      field: "discount",
      type: "numeric",
      editable: "always",
      editComponent: (props) => (
        <TextField
          id="discount"
          name="discount"
          disabled={
            (permistionInv.APDC === 2 || permistionInv.APDC === 3) && permistionInv.DSOI == 1
              ? false
              : true
          }
          variant="outlined"
          size="small"
          className={clasTF.rootmt}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) =>
            props.onChange(
              e.target.value > permistionInv.SDV ? goAlertV() : e.target.value === "" ? goAlertVal() : e.target.value
            )
          }
        />
      ),
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الخصم%",
      field: "DiscPers",
      type: "numeric",
      //editable:nevEve,
      editable: "always",
      editComponent: (props) => (
        <TextField
          disabled={
            (permistionInv.APDC === 1 || permistionInv.APDC === 3) && permistionInv.DSOI == 1
              ? false
              : true
          }
          variant="outlined"
          size="small"
          className={[clasTF.rootmt, clasTF.mtTdisc]}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) =>
            props.onChange(
              e.target.value > permistionInv.SDP ? goAlert() : e.target.value === "" ? goAlertVal() : e.target.value
            )
          }
        />
      ),
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الإجمالي بعد الخصم",
      field: "totAfterDisc",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "ضريبة التبغ",
      field: "tab3Val",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "القيمة المضافة%",
      field: "taxperc",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "إجمالي الضريبة",
      // title: "إجمالي القيمة المضافة",
      field: "taxval",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الصافي",
      field: "netAmountSub",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "ملاحظات",
      field: "",
      hidden: true,
      editComponent: (props) => (
        <TextField
          variant="outlined"
          size="small"
          className={clasTF.rootmt}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      hidden: true,
      rowStyle: stElemnt.SubR,
    },
    {
      title: "البيان",
      field: "notes",
      editComponent: (props) => (
        <TextField
          variant="outlined"
          size="small"
          className={[clasTF.rootmt, clasTF.mtTnote]}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      rowStyle: stElemnt.SubR,
    },
    {
      title: setting.priceNM,
      field: "sale_price",
      hidden: true,
      editComponent: (props) => (
        <TextField
          variant="outlined"
          size="small"
          className={[clasTF.rootmt, clasTF.mtTpric]}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      rowStyle: stElemnt.SubR,
    },
  ];

  // func m t
  const befPushTos = async () => {
    addChangds();
  };

  let his = useHistory();

  const toPrint = async (type, e, hist) => {

    or.PrAC = hist;
    if (isdata.length == 0) {
      handOSnack({
        vertical: "top",
        horizontal: "right",
        severit: "warning",
        text: "يجب تنزيل اصناف",
        time: 2000,
      });
    } else {
      await toWhere(type, e);
    }

  };


  const handPrint = async (procid) => {
    const serializedinv = JSON.stringify({
      proc_id: procid,
      invN: setting.titleHed,
      invNEn: setting.titleHedEn,
      salesman: valueSalesmen.name,
      backInv: "InvSO",
      branch: sto.branchi,
      ProcTypeInvProc: setting.PrTy_InPr,
    });
    await localStorage.setItem("datainv", serializedinv);
    const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};
    if (or.PrAC === 1) {
      if (premiApp?.cashirTemplate == 3) {
        his.push({ pathname: "/RestCasher" });
      } else if (premiApp?.cashirTemplate == 2) {
        his.push({ pathname: "/CashairNoTax" });
      } else {
        his.push({ pathname: "/C" });
      }
    } else if (or.PrAC === 2) {
      if (premiApp?.billTemplate == 4) {
        his.push({ pathname: "/bill4" });
      } else if (premiApp?.billTemplate == 3) {
        his.push({ pathname: "/gold-bill" });
      } else if (premiApp?.billTemplate == 5) {
        his.push({ pathname: "/RevCompany" });
      } else if (premiApp?.billTemplate == 6) {
        his.push({ pathname: "/RevInv" });
      } else if (premiApp?.billTemplate == 7) {
        his.push({ pathname: "/bill2" });
      }
      else if (premiApp?.billTemplate == 9) {
        his.push({ pathname: "/RevCompany" });
      }
      else if (premiApp?.billTemplate == 8) {
        his.push({ pathname: "/bill4" });
      }
      else if (premiApp?.billTemplate == 2) {

        his.push({ pathname: "/bill2" });
        // window.open( "/bill2");
      } else his.push({ pathname: "/A" });
    } else if (or.PrAC === 3) {
      setGenerating(true);
      await handleDirectPrintTemp2(
        JSON.parse(serializedinv),
        isdata.length,
        handNewInv
      );
      // await PrintInvoiceDirectly(JSON.parse(serializedinv), handNewInv);
      setGenerating(false);
    } else if (or.PrAC === 4) {
      handNewInv();

      setGenerating(true);
      await PrintInvoiceDirectly(JSON.parse(serializedinv), true, handNewInv);
      setGenerating(false);
    } else if (or.PrAC === 5) {
      await PrintInvoiceDirectly(
        JSON.parse(serializedinv),
        true,
        handNewInv
      );
    }
  };

  const handPrintOnly = async (procid, num) => {
    const serializedinv = JSON.stringify({
      proc_id: procid,
      invN: setting.titleHed,
      invNEn: setting.titleHedEn,
      salesman: valueSalesmen.name,
      backInv: "InvSO",
      branch: sto.branchi,
      ProcTypeInvProc: setting.PrTy_InPr,
    });
    await localStorage.setItem("datainv", serializedinv);
    const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};
    if (num === 1) {
      if (premiApp?.cashirTemplate == 3) {
        his.push({ pathname: "/RestCasher" });
      } else if (premiApp?.cashirTemplate == 2) {
        his.push({ pathname: "/CashairNoTax" });
      } else {
        his.push({ pathname: "/C" });
      }
    } else if (num === 2) {
      if (premiApp?.billTemplate == 4) {
        his.push({ pathname: "/bill4" });
      } else if (premiApp?.billTemplate == 3) {
        his.push({ pathname: "/gold-bill" });
      } else if (premiApp?.billTemplate == 5) {
        his.push({ pathname: "/RevCompany" });
      } else if (premiApp?.billTemplate == 6) {
        his.push({ pathname: "/RevInv" });
      } else if (premiApp?.billTemplate == 7) {
        his.push({ pathname: "/bill2" });
      }
      else if (premiApp?.billTemplate == 8) {
        his.push({ pathname: "/bill4" });
      }
      else if (premiApp?.billTemplate == 2) {

        his.push({ pathname: "/bill2" });
        // window.open( "/bill2");
      } else his.push({ pathname: "/A" });
    } else if (num === 3) {
      setGenerating(true);
      await handleDirectPrintTemp2(
        JSON.parse(serializedinv),
        isdata.length,
        handNewInv
      );
      // await PrintInvoiceDirectly(JSON.parse(serializedinv), handNewInv);
      setGenerating(false);
    } else if (num === 4) {
      handNewInv();

      setGenerating(true);
      await PrintInvoiceDirectly(JSON.parse(serializedinv), true, handNewInv);
      setGenerating(false);
    }
  };

  const [generating, setGenerating] = useState(false);

  const PushTos = async (t) => {
    befPushTos();
    let Sa_or_Pa_rest_id = null;
    console.log("the data is", remains);
    await ButtonLoading(t);




    const model = {

      inv: {
        ...idata,
        Pay_mouny: payment.paidup ? payment.paidup : 0,
        Remaining: payment.stay,
        agel_part: remains.agelPart,
        cash_part: remains.cashPart,
        network_part: remains.networkPart,
        bank_part: remains.bankPart,
        emp_part: remains.empPart,
        trans_no: maxShifts,
      },
      stor: stor,

      parms: {
        omlaId: omlaValue?.id,
        tobaccoFees: premiA.tobaccoFees,
        omlaExchange: omlaValue?.exchange,
        iuserid: idata.create_user,
        iformidCuCo: dataF[3].id,
        proc_type: setting.routIS == "Inv_Sub_ToS/" ? 2 : 1,
        yashml: permiCuCo.yashml == "W",
        isAgel: remains?.isAgel,
        isCash: remains?.isCash,
        isNetwork: remains?.isNetwork,
        isBank: remains?.isBank,
        isEmp: remains?.isEmp,
        isWindow: remains?.isWindow,
        empId: remains?.empId,
        bankId: remains?.bankId,
        networkId: remains?.networkId,
      },
      State: "Post",
      _Data: isdata,
    };

    if (or.postput == "toPost") {
      await axios
        .post(uri + "_HInv", model)
        .then((resI) => {
          /*console.log('done I_po');*/
          console.log(" the  data is", model);

          if (or.PrAC !== 0) handPrint(resI.data.proc_id);
        if(isDialog==true){props.onClose();}else{  ButtonSuccess(t, true);}
        })
        .catch((resI) => {
          handOSnack({
            vertical: "top",
            horizontal: "right",
            severit: "warning",
            text:
              "لم يتم اضافة الفاتورة" + "بسبب " + resI.response?.data?.Message,
            time: 2000,
          });

          ButtonSuccess(t, false);
        });
    } else if (or.postput == "toPut") {
      await ButtonLoading(t);
      // I_Pu
      console.log(" the  data is", model);


      // if (permistionInv.IPU) {
      if (permistionInv.Up) {

        await axios
          .put(uri + "_HInv", model)
          .then((resI) => {
            /*console.log('done I_po');*/
            console.log(" the  data is", model);

            if (or.PrAC !== 0) handPrint(resI.data.proc_id);
            if(isDialog==true){props.onClose();}else{  ButtonSuccess(t, true);}

           // ButtonSuccess(t, true);
          })
          .catch((resI) => {
            handOSnack({
              vertical: "top",
              horizontal: "center",
              severit: "warning",
              text:
                "لم يتم تعديل الفاتورة" +
                "  " +
                "بسبب " +
                resI.response?.data?.Message,
              time: 2000,
            });
            ButtonSuccess(t, false);
          });
      } else {

        const tb = { inv: idata, stor: stor };

        await axios
          .put(uri + "Inv_R_RS_", tb)
          .then((resI_Pu) => {
            /*console.log('done I_Pu');*/

            if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12")
              Sa_or_Pa_rest_id = resI_Pu.data.sale_rest_id;
            else Sa_or_Pa_rest_id = resI_Pu.data.purch_rest_id;


            if (or.PrAC !== 0) handPrint(resI_Pu.data.proc_id);
            ButtonSuccess(t, true);
          })
          .catch((resI_Pu) => {
            ButtonSuccess(t, false); /*console.log('no I_Pu');*/
          });
      }
    }
  };

  const PushTost = async (t) => {
    befPushTos();
    let Sa_or_Pa_rest_id = null;
    if (or.postput == "toPost") {
      await ButtonLoading(t);
      const tb = {
        invtemp: idata,
        stor: stor,
        parms: {
          iuserid: idata.create_user,
          iformidCuCo: dataF[3].id,
        },
      };

      await axios
        .post(uri + "Inv_R_RS__", tb)
        .then((resI) => {
          /*console.log('done I_po');*/
          axios
            .post(
              uri +
              setting.routIS +
              permiCuCo.yashml +
              "?pcid=" +
              resI.data.proc_id +
              "&&State=Postt",
              isdata
            )
            .then((resS) => {
              /*console.log('done IS_po');*/
              if (or.PrAC !== 0) handPrint(resI.data.proc_id);
              ButtonSuccess(t, true);
            })
            .catch((resS) => {
              /*console.log('no IS_po');*/
            });
        })
        .catch((resI) => {
          ButtonSuccess(t, false);
        });
    }
    if (or.postput == "toPut") {
      await ButtonLoading("t");
      // I_Pu
      const tb = { inv: idata, stor: stor };

      await axios
        .put(uri + "Inv_R_RS", tb)
        .then((resI_Pu) => {
          /*console.log('done I_Pu');*/

          if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12")
            Sa_or_Pa_rest_id = resI_Pu.data.sale_rest_id;
          else Sa_or_Pa_rest_id = resI_Pu.data.purch_rest_id;

          // R_Pu
          axios
            .put(uri + "Restrictions" + "?pcid=" + resI_Pu.data.proc_id)
            .then((retR_Pu) => {
              /*console.log('done R_Pu');*/
            })
            .catch((retR_Pu) => {
              /*console.log('no R_Pu');*/
            });

          // IS_De&Po
          axios
            .post(
              uri +
              setting.routIS +
              permiCuCo.yashml +
              "?pcid=" +
              resI_Pu.data.proc_id +
              "&&State=rePost",
              isdata
            )
            .then((resIS_Po) => {
              /*console.log('done resIS_Po');*/
            })
            .catch((resIS_Po) => {
              /*console.log('no resIS_Po');*/
            });

          // RS_De&Po
          axios
            .post(uri + "Restrictions_Sub" + "?idR=" + Sa_or_Pa_rest_id, idata)
            .then((resRS_Po) => {
              /*console.log('done RS_Po');*/
            })
            .catch((resRS_Po) => {
              /*console.log('no RS_Po');*/
            });

          /* E inner */
          if (or.PrAC !== 0) handPrint(resI_Pu.data.proc_id);
          ButtonSuccess("t", true);
        })
        .catch((resI_Pu) => {
          ButtonSuccess(t, false); /*console.log('no I_Pu');*/
        });
    }
  };
  const deleteTos = async (e) => {



    await e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    ButtonLoading("delete");

    await axios.delete(uri + `_HInv?id=${idata.proc_id}`).then(res => {
      toast.success("invoice has deleted");
      ButtonSuccess("delete", true);

    }).catch(err => {
      console.log("eror", err)
      ButtonSuccess("delete", false);
      toast.error("not delete ")
    })
    // await axios
    //   .delete(uri + setting.routI_R_RS + "?pcid=" + parseInt(idata.proc_id))
    //   .then((resIsRs) => {
    //     ButtonSuccess("delete", true);
    //   })
    //   .catch((resIsRs) => {
    //     ButtonSuccess("delete", false);
    //   });
  };

  const loadePayMents = () => {
    //  alert(idata.netAmount)
    setPayment({
      ...payment,
      paidupPMDcn: payment.paidup,
      stayPMDcn: payment.stay,
    });

    if (switchState.cashORnetworksw == false && valueType.id == 2) {
      setChecked({
        ...checked,
        cash_partck: true,
        network_partck: false,
        agel_partck: false,
      });
      setIData({
        ...idata,
        cash_part: idata.netAmount,
        network_part: "",
        agel_part: "",
        emp_part: "",
        bank_part: "",
        check_refno: "",
        netAmountPMDcn: idata.netAmount,
      });
    } else if (switchState.cashORnetworksw == true && valueType.id == 2) {
      setChecked({
        ...checked,
        cash_partck: false,
        network_partck: true,
        agel_partck: false,
      });
      setIData({
        ...idata,
        cash_part: "",
        network_part: idata.netAmount,
        agel_part: "",
        emp_part: "",
        bank_part: "",
        check_refno: "",
        netAmountPMDcn: idata.netAmount,
      });


      setValueBankPMD(valueBank); //setOptionsBankPMD(optionsBank);
    }
    // else if (valueType.id === 1) {
    //   setChecked({
    //     ...checked,
    //     network_partck: false,
    //     cash_partck: false,
    //     agel_partck: true,
    //   });
    //   setIData({
    //     ...idata,
    //     cash_part: "",
    //     network_part: "",
    //     agel_part: idata.netAmount,
    //     emp_part: "",
    //     bank_part: "",
    //     check_refno: "",
    //     netAmountPMDcn: idata.netAmount,
    //   });
    // }
    else if (valueType.id === 1) {

      if (payment.paidup > 0) {

        alert(payment.paidup)
        if (switchState.cashORnetworksw == false) {
          // setChecked({
          //   ...checked,
          //   cash_partck: false,
          //   network_partck: false,
          //   agel_partck: true,
          // });
          setIData({
            ...idata,
            cash_part: payment.paidup,
            network_part: "",
            agel_part: payment.stay,
            emp_part: 0,
            bank_part: 0,
            check_refno: "",
            netAmountPMDcn: idata.netAmount,
          });
        } else if (switchState.cashORnetworksw == true) {
          setChecked({
            ...checked,
            cash_partck: false,
            network_partck: true,
            agel_partck: true,
          });
          setIData({
            ...idata,
            cash_part: "",
            network_part: payment.paidup,
            agel_part: payment.stay,
            emp_part: "",
            bank_part: "",
            check_refno: "",
            netAmountPMDcn: idata.netAmount,
          });
          setValueBankPMD(valueBank); //setOptionsBankPMD(optionsBank);

        }
      } else {
        setChecked({
          ...checked,
          cash_partck: false,
          network_partck: false,
          agel_partck: true,
        });
        setIData({
          ...idata,
          cash_part: payment.paidup,
          network_part: "",
          agel_part: payment.stay,
          emp_part: "",
          bank_part: "",
          check_refno: "",
          netAmountPMDcn: idata.netAmount,
        });
      }


    }


  };

  const usedPayedWindow = (v) => {
    if (v === 0) idata.used_payed_window = v;
    else idata.used_payed_window = v;
  };

  // ============================================ btn load
  const [loading, setLoading] = useState(insh.LoadAndSucc);
  const [success, setSuccess] = useState(insh.LoadAndSucc);
  const timer = React.useRef();

  const buttonClassnameSave = clsx({
    [clasCAB.buttonSuccessCAB]: success.save,
  });
  const buttonClassnameSavetmp = clsx({
    [clasCAB.buttonSuccessCAB]: success.savetmp,
  });

  const buttonClassnameSavePMD = clsx({
    [clasCAB.buttonSuccessCAB]: success.savePMD,
  });

  const buttonClassnameDelete = clsx({
    [clasCAB.buttonSuccessCAB]: success.delete,
  });

  const buttonClassnamePrint = clsx({
    [clasCAB.buttonSuccessCAB]: success.print,
  });
  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const ButtonLoading = (prop) => {
    setLoading({ ...loading, [prop]: true });
  };
  const ButtonSuccess = (prop, state) => {
    timer.current = window.setTimeout(() => {
      setLoading({ ...loading, [prop]: false });
      setSuccess({ ...success, [prop]: true });
      if (
        prop == "delete" ||
        prop == "save" ||
        prop == "savetmp" ||
        prop == "savePMD"
      ) {
        if (state) handNewInv();
      }
      if (prop == "savePMD") {
        handClosePMD("done");
      }

      timer.current = window.setTimeout(() => {
        setSuccess({ ...success, [prop]: false });
      }, 500);
    }, 2000);
  };
  // ========================================== btn load

  const toDB = async (type, e) => {
    // if (checked.paymentDiloagck === true) {
    if (showPaid === true) {
      if (parseFloat(idata.netAmount) !== parseFloat(remains.allpart)) {
        alert("المبلغ غير متساوي");
      } else if (isdata.length == 0) {
        alert(" لا يوجد اصناف في الجدول ");
      } else if (parseFloat(idata.netAmount) == 0) {
        alert(" لا يمكن حفظ الفاتورة باجمالي صفر    ");
      }
      else {
        handClosePMD("cancle");
        await PushTos(type);
      }
    } else {

      usedPayedWindow(0);

      if (type == "savetmp") {
        await PushTost(type);
      } else {
        await PushTos(type);
      }
    }
  };

  const paidFun = (type) => {


    if (valueType.id == 2) {
      setRemains({
        ...remains,
        cashPart: switchState.cashORnetworksw == false ? idata.netAmount : 0,
        agelPart: 0,
        bankPart: 0,
        networkPart: switchState.cashORnetworksw == true ? idata.netAmount : 0,
        empPart: 0,
        isCash: switchState.cashORnetworksw == false,
        isWindow: true,
        isNetwork: switchState.cashORnetworksw == true,
        isAgel: false,
        isEmp: false,
        allpart: idata.netAmount,
        typePress: type,
      });
      // setValueBank(gets.Banks[0]); //getAutobanks(0);

    } else if (valueType.id == 1 && parseFloat(payment.paidup) > 0) {
      setRemains({
        ...remains,
        cashPart: switchState.cashORnetworksw == false ? payment.paidup : 0,
        agelPart: payment.stay,
        bankPart: 0,
        networkPart: switchState.cashORnetworksw == true ? payment.paidup : 0,
        empPart: 0,
        isCash: switchState.cashORnetworksw == false,
        isWindow: true,
        isAgel: true,
        isEmp: false,
        isNetwork: switchState.cashORnetworksw == true,
        allpart: idata.netAmount,
        typePress: type,
      });

    } else {
      setRemains({
        ...remains,
        cashPart: 0,
        agelPart: idata.netAmount,
        bankPart: 0,
        networkPart: 0,
        empPart: 0,
        isCash: false,
        isWindow: true,
        isAgel: true,
        isEmp: false,
        isNetwork: false,
        allpart: idata.netAmount,
        typePress: type,
      });
    }

    setValueBankPMD(switchState.cashORnetworksw ? valueBank : AutoOptions.Banks[0]);
    setValueEmployePMD(gets.Employees[0]);
    setPayment({ ...payment, paidupPMDcn: payment.paidup, stayPMDcn: payment?.stay });
  }


  const toWhere = (type, e) => {
    if (parseFloat(payment.paidup) > parseFloat(idata.netAmount)) {
      if ((setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12")) {
        if (switchState.cashORnetworksw == true) {
          setValueType(AutoOptions.types[1]);

          remains.networkPart = idata.netAmount;
          remains.agelPart = 0;
          remains.bankPart = 0;
          remains.cashPart = 0;


          idata.pay_type = 2;
          idata.inv_type = 2;
          idata.cash_part = 0;
          idata.agel_part = 0;
          idata.bank_part = 0;
          idata.network_part = idata.netAmount;

          setChecked({
            ...checked,
            cash_partck: false,
            network_partck: true,
            agel_partck: false,
          });
          blurPaid2(payment.paidup);

        }
        else {
          setValueType(AutoOptions.types[1]);
          remains.cashPart = idata.netAmount;
          remains.agelPart = 0;
          remains.bankPart = 0;
          remains.networkPart = 0;


          idata.pay_type = 1;
          idata.inv_type = 2;
          idata.cash_part = idata.netAmount;
          idata.agel_part = 0;
          idata.bank_part = 0;
          idata.network_part = 0;

          setChecked({
            ...checked,
            cash_partck: true,
            network_partck: false,
            agel_partck: false,
          });
          blurPaid2(payment.paidup);

        }
      }
      else {

        if (switchState.cashORnetworksw == true) {
          setValueType(AutoOptions.types[1]);

          remains.networkPart = idata.netAmount;
          remains.agelPart = 0;
          remains.bankPart = 0;
          remains.cashPart = 0;
          remains.empPart = 0;
          payment.paidup = idata.netAmount;
          payment.stay = 0;

          idata.pay_type = 2;
          idata.inv_type = 2;
          idata.cash_part = 0;
          idata.agel_part = 0;
          idata.bank_part = 0;
          idata.network_part = idata.netAmount;

          setChecked({
            ...checked,
            cash_partck: false,
            network_partck: true,
            agel_partck: false,
          });
          if (isdata.length > 0) {
            if (showPaid === true) {
              // loadePayMents();
              ///handleClickOpenPMD(TransitionRight);
              // loadePayMents();
              paidFun(type);
              handleClickOpenPaid(type);

            } else

              toDB(type, e);
          }
          else {
            // handOSnack({ vertical: 'top', horizontal: 'center' });
            handOSnack({
              vertical: "top",
              horizontal: "right",
              severit: "warning",
              text: "يجب تنزيل اصناف",
              time: 2000,
            });
          }


        }
        else {
          setValueType(AutoOptions.types[1]);
          remains.cashPart = idata.netAmount;
          remains.agelPart = 0;
          remains.bankPart = 0;
          remains.networkPart = 0;


          payment.paidup = idata.netAmount;
          payment.stay = 0;
          idata.pay_type = 1;
          idata.inv_type = 2;
          idata.cash_part = idata.netAmount;
          idata.agel_part = 0;
          idata.bank_part = 0;
          idata.network_part = 0;

          setChecked({
            ...checked,
            cash_partck: true,
            network_partck: false,
            agel_partck: false,
          });
          if (isdata.length > 0) {
            if (checked.paymentDiloagck === true) {
              loadePayMents();
              handleClickOpenPMD(TransitionRight);
            } else

              toDB(type, e);
          }
          else {
            // handOSnack({ vertical: 'top', horizontal: 'center' });
            handOSnack({
              vertical: "top",
              horizontal: "right",
              severit: "warning",
              text: "يجب تنزيل اصناف",
              time: 2000,
            });
          }


        }
      }

    }
    else {
      if (isdata.length > 0 && parseFloat(idata.netAmount) > 0) {
        if (showPaid === true) {
          // loadePayMents();
          paidFun(type);
          handleClickOpenPaid(type);
        } else

          toDB(type, e);
      } else if (isdata.length === 0) {
        // alert(" لا يوجد اصناف في الجدول ");
        handOSnack({
          vertical: "top",
          horizontal: "center",
          severit: "warning",
          text: "لا يوجد اصناف في الجدول ... برجاء تنزيل الاصناف",
          time: 2000,
        });
      } else if (parseFloat(idata.netAmount) === 0) {
        handOSnack({
          vertical: "top",
          horizontal: "center",
          severit: "warning",
          text: "لا يمكن حفظ الفاتورة باجمالي صفر ...برجاء تعديل الاسعار",
          time: 2000,
        });
      }
      else {
        // handOSnack({ vertical: 'top', horizontal: 'center' });
        handOSnack({
          vertical: "top",
          horizontal: "right",
          severit: "warning",
          text: "يجب تنزيل اصناف",
          time: 2000,
        });
      }
    }
  };

  const hRowrAdd = (newData, resolve) => {
    let restaxvalrA = 0,
      resDiscPerA = 0,
      restotJustSumrA = 0,
      restotJustAfterDiscSumrA = 0,
      resDisCountrA = 0,
      resVal1SumrA = 0,
      resNetrA = 0,
      tot_befor_vat_after_disc = 0;

    resVal1SumrA = parseFloat(subInfo.val1) + parseFloat(isdata[or.i].val1);
    restotJustSumrA =
      parseFloat(isdata[or.i].new_exchange_price) * resVal1SumrA;
    resDisCountrA = (
      restotJustSumrA * parseFloat(subInfo.DiscPers / 100)
    ).toFixed(2);
    restotJustAfterDiscSumrA =
      parseFloat(restotJustSumrA) - parseFloat(resDisCountrA);

    restaxvalrA = maths(
      parseFloat(restotJustAfterDiscSumrA),
      parseFloat(subInfo.taxperc),
      "taxval"
    );
    resNetrA = maths(
      parseFloat(restotJustAfterDiscSumrA),
      parseFloat(subInfo.taxperc),
      "Net"
    );
    tot_befor_vat_after_disc = maths(
      parseFloat(restotJustAfterDiscSumrA),
      parseFloat(subInfo.taxperc),
      "before_vat"
    );



    newData = {
      /* to show */
      minPrice: subInfo.minPrice,
      PricePu: subInfo.PricePu,
      unitame: valueItemUnits.unitName /*  - الـوحدة name */,
      symbol: subInfo.symbol /*  - إسم الصنف  */,

      /* to maaths */
      /* int - الإجمالي */
      totJust: restotJustSumrA,
      /* int - إجمالي الخصم بعد الخصم */
      totAfterDisc: tot_befor_vat_after_disc,

      tot_before_vat_after_d: tot_befor_vat_after_disc,


      tab3Val: subInfo?.is_tab3_curr == 1 ? restotJustAfterDiscSumrA : 0,
      /* to go */
      currency_from: parseInt(itemId) /* float - id الصنف */,
      unit: parseInt(valueItemUnits.unit) /* int - الـوحدة id */,
      /* float - الكمية */
      val1: resVal1SumrA,
      /* float - العبوة */
      val: parseFloat(subInfo.perc),
      sale_price:parseFloat(valueItemUnits?.sale),

      /* float - الـسعر */
      new_exchange_price: parseFloat(isdata[or.i].new_exchange_price),
      /* float - الخصم  مبلغ*/
      discount: resDisCountrA,
      /* float - %  خصم نسبة */

      DiscPers: subInfo.DiscPers,
      /* float -  ضريبة القيمة المضافة نسبة */
      taxperc: parseFloat(subInfo.taxperc),

      taxval: restaxvalrA,


      /* float - الصافي */
      netAmountSub: resNetrA,
      barcode: subInfo.barcode /*  - الباركود */,
      sub_center: 0 /* int -  */,
      sub_branch: sto.branchi /* int - الفرع */,
    };

    //  alert(newData.netAmountSub)
    const isDataUpdate = [...isdata];
    const index = or.i;
    isDataUpdate[index] = newData;
    setExSub(isDataUpdate.length);
    handSums(isDataUpdate);

    setIsData([...isDataUpdate]);
  };

  const maths = (rTotAfterDic, taxperc, ty) => {
    if (permiCuCo.CWVat == 0 && taxperc != 0) {
      if (ty === "taxval") return ((rTotAfterDic * taxperc) / 100).toFixed(2);
      if (ty === "Net")
        return (rTotAfterDic + (rTotAfterDic * taxperc) / 100).toFixed(2);
      if (ty === "before_vat") return rTotAfterDic.toFixed(2);
    } else if (permiCuCo.CWVat == 1 && taxperc != 0) {
      if (ty === "taxval")
        return (
          parseFloat(rTotAfterDic) -
          rTotAfterDic / (1 + taxperc / 100)
        ).toFixed(2);
      if (ty === "Net") return rTotAfterDic.toFixed(2);

      if (ty === "before_vat") return (rTotAfterDic - (rTotAfterDic / ((100 + taxperc) / taxperc))).toFixed(2);

    } else if (taxperc == 0) {
      if (ty === "taxval") return (0).toFixed(2);
      if (ty === "Net")
        return (rTotAfterDic).toFixed(2);
      if (ty === "before_vat") return rTotAfterDic.toFixed(2);
    }
  };

  const hRowAdd = (newData, resolve) => {
    let errorList = [];
    let restaxvalA = 0,
      resNetA = 0,
      restotJustSumA = 0,
      restotJustAfterDiscSumA = 0,
      resDiscPerA = 0,
      resDisCountA = 0,
      tot_befor_vat_after_disc = 0;


    restotJustSumA = parseFloat(subInfo.totJust);
    restotJustAfterDiscSumA =
      parseFloat(subInfo.totJust) - parseFloat(subInfo.discount);

    if (checked.discRateck === true) {
      //  alert('in if')
      resDiscPerA = parseFloat(invDisc.rate);
      resDisCountA = (
        parseFloat(invDisc.rate / 100) * parseFloat(subInfo.totJust)
      ).toFixed(2);

      restotJustAfterDiscSumA =
        parseFloat(subInfo.totJust) - parseFloat(resDisCountA);
    } else if (checked.discMinusck === true) {
      if (subInfo.totJust == 0) {
        resDiscPerA = (
          (parseFloat(idata.minus) / 1) * 100).toFixed(2);
        resDisCountA = (
          parseFloat(resDiscPerA / 100) * 1
        ).toFixed(2);
      } else {
        resDiscPerA = (
          (parseFloat(idata.minus) / parseFloat(subInfo.totJust)) * 100).toFixed(2);
        resDisCountA = (
          parseFloat(resDiscPerA / 100) * parseFloat(subInfo.totJust)
        ).toFixed(2);
      }

    }
    // disc sub level
    else {
      // alert('in else')
      resDiscPerA = parseFloat(subInfo.DiscPers);
      resDisCountA = (
        parseFloat(subInfo.DiscPers / 100) * parseFloat(subInfo.totJust)
      ).toFixed(2);
    }

    restaxvalA = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(subInfo.taxperc),
      "taxval"
    );

    tot_befor_vat_after_disc = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(subInfo.taxperc),
      "before_vat"
    );


    resNetA = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(subInfo.taxperc),
      "Net"
    );
    newData = {
      /* to show */
      minPrice: subInfo.minPrice,
      PricePu: subInfo.PricePu,
      unitame: valueItemUnits.unitName /*  - الـوحدة name */,
      symbol: subInfo.symbol /*  - إسم الصنف  */,
      /* to maaths */
      /* int -  الإجمالي */
      totJust: restotJustSumA,
      /* int - إجمالي الخصم بعد الخصم */
      totAfterDisc: tot_befor_vat_after_disc,

      tot_before_vat_after_d: tot_befor_vat_after_disc,



      tab3Val: subInfo?.is_tab3_curr == 1 ? restotJustAfterDiscSumA : 0,

      /* to go */
      // currency_from: parseInt(subInfo.id), /* float - id الصنف */
      currency_from: parseInt(itemId) /* float - id الصنف */,
      unit: parseInt(valueItemUnits.unit) /* int - الـوحدة id */,
      sale_price:parseFloat(valueItemUnits?.sale),
      /* float - الكمية */
      val1: parseFloat(subInfo.val1),
      // new _val1:parseFloat(subInfo.new _val1),
      /* float - العبوة */
      val: parseFloat(subInfo.perc),
      // new _val:parseFloat(subInfo.perc),
      /* float - الـسعر */
      new_exchange_price: parseFloat(subInfo.Price),
      // all_new_exchange_price: parseFloat(subInfo.Price),
      /* float - الخصم  مبلغ*/
      discount: resDisCountA,
      // new _discount: parseFloat(subInfo.discount),
      /* float - %  خصم نسبة */
      DiscPers: resDiscPerA,
      // new _DiscPers: parseFloat(subInfo.DiscPers),
      /* float -  ضريبة القيمة المضافة نسبة */
      taxperc: parseFloat(subInfo.taxperc),
      // new _taxperc: parseFloat(subInfo.taxperc),
      /* float - إجمالي قيمة المضافة  */
      // taxval: subInfo?.is_tab3_curr == 1 ? 2 * restaxvalA : restaxvalA,
      taxval: restaxvalA,

      /* float - الصافي */
      // netAmountSub: resNetA,

      netAmountSub:  resNetA,


      // netAmo untSub: parseFloat(subInfo.totJust-subInfo.discount) + parseFloat((subInfo.totJust-subInfo.discount)*subInfo.taxperc/100),
      barcode: subInfo.barcode /*  - الباركود */,
      sub_center: 0 /* int -  */,
      sub_branch: sto.branchi /* int - الفرع */,
    };
    if (errorList.length < 1) {
      //no error
      let dataToAdd = [...isdata];
      dataToAdd.push(newData);
      setIsData(dataToAdd);
      handSums(dataToAdd);
      setExSub(dataToAdd.length);
    } else {
      resolve();
    }
  };

  const [sums, setSums] = useState(insh.Sums);

  // الدفع المبقي
  const [payment, setPayment] = useState(insh.Payment);
  const [remains, setRemains] = useState(insh.Remains);
  const [isHedden, setIshedden] = useState(false);
  const [isClick, setIsClick] = useState(insh.ished);
  const [invDisc, setInvDisc] = useState(insh.InvDisc);


  const handSums = (data) => {
    let val1s = 0,
      tot_sale_or_purch = 0,
      /*tot_sales=0,tot_purchs=0,*/ amountOfVats = 0,
      netAmounts = 0,
      amountAfterDiscs = 0,
      amount_after_disc_before_vat = 0,
      discounts = 0,
      amountWithVats = 0,
      invTab3Val = 0,
      taxpercs;

    for (var m = 0; m < data.length; m++) {
      val1s += parseFloat(data[m].val1);
      tot_sale_or_purch += parseFloat(data[m].totJust);
      taxpercs = data[m].taxperc;
      /* جمالي القيمة المضافة */
      amountOfVats += parseFloat(data[m].taxval);
      netAmounts += parseFloat(data[m].netAmountSub);
      /* إجمالي قبل الضريبة */
      amountAfterDiscs += parseFloat(data[m].totAfterDisc);
      discounts += parseFloat(data[m].discount);

      // tobacco tax
      invTab3Val += parseFloat(data[m].tab3Val);
      amount_after_disc_before_vat += parseFloat(data[m].totAfterDisc);
    }

    /* في الحالتين */
    /* إجمالي بعد الخصم */
    amountWithVats = (
      parseFloat(tot_sale_or_purch) - parseFloat(discounts)
    ).toFixed(2);
    /* يشمل */


    if (permiCuCo.CWVat == 1) {
      amountAfterDiscs = (
        parseFloat(amount_after_disc_before_vat)
      ).toFixed(2);

      amountOfVats = (
        parseFloat(amountWithVats) - parseFloat(amountAfterDiscs)
      ).toFixed(2);

      netAmounts = (
        parseFloat(amountWithVats)
      ).toFixed(2);

    } else {
      /*لا يشمل */
      amountAfterDiscs = (
        parseFloat(amount_after_disc_before_vat)
      ).toFixed(2);

      netAmounts = (
        parseFloat(amountAfterDiscs) + parseFloat(amountOfVats)
      ).toFixed(2);

      amountWithVats = (
        parseFloat(amountAfterDiscs) + parseFloat(amountOfVats)
      ).toFixed(2);


    }



    setSums({ val1: val1s });
    setIData({
      ...idata,
      amountAfterDisc: amountAfterDiscs,
      amountWithVat: amountWithVats,
      tot_sale: tot_sale_or_purch,
      tot_purch: tot_sale_or_purch,
      netAmount: netAmounts,
      amountBefordisc: tot_sale_or_purch,
      amountOfVat: amountOfVats,
      minus: discounts,
      tab3Val: invTab3Val,
    });



    fcHandSumsToPayment(netAmounts);

    fcHandSumsToinvDisc(tot_sale_or_purch, discounts);
  };

  const fcHandSumsToPayment = (newExPrice) => {
    // screan payment
    if (idata.inv_type == 1) {
      setPayment({ ...payment, stay: newExPrice, paidup: 0 });
      setRemains({ ...remains, cashPart: 0, agelPart: newExPrice, bankPart: 0, networkPart: 0 });
    }
    else {
      setPayment({ ...payment, paidup: newExPrice, stay: 0 });
      if (switchState.cashORnetworksw == false) {
        setRemains({ ...remains, cashPart: newExPrice, agelPart: 0, bankPart: 0, networkPart: 0 });
      } else if (switchState.cashORnetworksw == true) {
        setRemains({ ...remains, cashPart: 0, agelPart: 0, bankPart: 0, networkPart: newExPrice });
      }
    }
  };

  const fcHandSumsToinvDisc = (totj, discs) => {
    // قيمة الخصم  مبلغ
    if (totj == 0 || totj == "") totj = 1;
    setInvDisc({
      minus: discs,
      rate: (parseFloat(parseFloat(discs) / totj) * 100).toFixed(2),
    });

  };

  const handRowAdd = (or) => {
    if (or.cros === "newRow") {
      hRowAdd();
    } else if (or.cros === "rnewRow") {
      hRowrAdd();
    }
    // }
  };
  const hRowUpdate = (newData, oldData, resolve) => {
    // alert(resolve)
    if (newData.new_exchange_price === "") { newData.new_exchange_price = 0; setInvDisc({ ...invDisc, minus: 0, rate: 0 }); };
    if (newData.discount === "") { newData.discount = 0; setInvDisc({ ...invDisc, minus: 0, rate: 0 }); };
    if (newData.val1 === "") { newData.val1 = 0; setInvDisc({ ...invDisc, minus: 0, rate: 0 }); };

    if (newData.DiscPers === "") { newData.DiscPers = 0; setInvDisc({ ...invDisc, minus: 0, rate: 0 }); };
    if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" || setting.PrTy_InPr == "01" || setting.PrTy_InPr == "11") {


      if ((setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12") &&
        permistionInv.SNOP === true && newData != null && parseFloat(newData.Price) < parseFloat(newData.PricePu)) {
        alert(
          `لا يمكن البيع اقل من سعر الشراء للصنف سعر الشراء المسجل للصنف هو : ${newData.PricePu}`
        );
        const dataUpdate = [...oldData];
        const index = oldData.tableData.id;
        dataUpdate[index] = oldData;
        setIsData([...dataUpdate]);
        resolve();
      } else if ((setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12") && permistionInv.SNOMIN === 1 &&
        parseFloat(newData.new_exchange_price) < parseFloat(newData.minPrice)) {
        alert(
          `لا يمكن البيع اقل من السعر الادنى للصنف السعر الادنى المسجل للصنف هو : ${newData.minPrice}`
        );
        const dataUpdate = [...oldData];
        const index = oldData.tableData.id;
        dataUpdate[index] = oldData;
        setIsData([...dataUpdate]);
        resolve();
      } else if ((setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12") && permistionInv.ASPZ === false && newData.new_exchange_price <= 0) {

        alert(
          `لا يوجد لديك صلاحية البيع بسعر صفر `
        );
        const dataUpdate = [...oldData];
        const index = oldData.tableData.id;
        dataUpdate[index] = oldData;
        setIsData([...dataUpdate]);
        resolve();

      }
      else if ((setting.PrTy_InPr == "01" || setting.PrTy_InPr == "11") && permistionInv.ASPZ === true && newData.new_exchange_price <= 0) {

        alert(
          `لا يوجد لديك صلاحية الشراء بسعر صفر `
        );
        const dataUpdate = [...oldData];
        const index = oldData.tableData.id;
        dataUpdate[index] = oldData;
        setIsData([...dataUpdate]);
        resolve();

      }
      else if (permistionInv.APDC === 1 && permistionInv.DSOI == 1 && parseFloat(newData.DiscPers) > parseFloat(permistionInv.SDP)) {
        alert(
          `
        لقد تجاوزت نسبة الخصم المسموح بها
        نسبة الخصم المسموح بها : ${parseFloat(permistionInv.SDP)
          }
        `
        );
      }
      else if (permistionInv.APDC === 2 && permistionInv.DSOI == 1 && parseFloat(newData.discount) > parseFloat(permistionInv.SDV)) {
        alert(
          `
        لقد تجاوزت مبلغ الخصم المسموح بها
        مبلغ الخصم المسموح بة : ${parseFloat(permistionInv.SDV)
          }
        `
        );
      }
      else if (permistionInv.APDC === 3 && permistionInv.DSOI == 1 &&
        ((parseFloat(permistionInv.SDV) != 0 && newData.discount > permistionInv.SDV) ||
          (parseFloat(permistionInv.SDP) != 0 && parseFloat(newData.DiscPers) > parseFloat(permistionInv.SDP)))) {
        alert(
          `
        لقد تجاوزت حد الخصم المسموح بها
          }
        `
        );
      }
      else {
        TableRowUpdate(newData, oldData, resolve);

      }
    } else {
      TableRowUpdate(newData, oldData, resolve);

    }
  };

  const TableRowUpdate = (newData, oldData, resolve) => {
    let resDisCountU = 0,
      restotJustSumU = 0,
      restaxvalU = 0,
      resNetU = 0,
      decPerces = 0,
      tot_before_vat_after_desc = 0,
      newtotJust;

    // let i = {
    //   newPrice: Number(newData.new_exchange_price),
    //   newDiscount: Number(newData.discount),
    //   newVal: Number(newData.val1),
    //   newDisc: Number(newData.DiscPers),

    // };

    if (newData.new_exchange_price === "") newData.new_exchange_price = 1;
    if (newData.discount === "") newData.discount = 0;
    if (newData.val1 === "") newData.val1 = 1;
    if (newData.DiscPers === "") newData.DiscPers = 0;

    newtotJust=(parseFloat(newData.new_exchange_price) * parseFloat(newData.val1)).toFixed(2);

    if (parseFloat(newData.DiscPers) == 0 && parseFloat(newData.discount) == 0) {

      resDisCountU = (

        parseFloat(newData.DiscPers/ 100) *
        parseFloat(newData.new_exchange_price) *
        parseFloat(newData.val1)
      ).toFixed(2);

    } else if (parseFloat(newData.DiscPers) !== 0 && parseFloat(newData.discount) == 0) {

      resDisCountU = (

        parseFloat(newData.DiscPers/ 100) *
        parseFloat(newData.new_exchange_price) *
        parseFloat(newData.val1)
      ).toFixed(2);

    } else if (parseFloat(newData.DiscPers) == 0 && parseFloat(newData.discount) !== 0) {

      resDisCountU = parseFloat(newData.discount).toFixed(2);
    }
    else {
      resDisCountU = parseFloat(newData.discount).toFixed(2);
    }

    restotJustSumU = parseFloat(
      (parseFloat(newData.new_exchange_price) * parseFloat(newData.val1)) - resDisCountU
    );
    decPerces = (
      (parseFloat(newData.totJust) - parseFloat(restotJustSumU)) / (parseFloat(newData.totJust) * 100)

    ).toFixed(2)
    restaxvalU = maths(
      parseFloat(restotJustSumU),
      parseFloat(newData.taxperc),
      "taxval"
    );
    resNetU = maths(
      parseFloat(restotJustSumU),
      parseFloat(newData.taxperc),
      "Net"
    );

    tot_before_vat_after_desc = maths(
      parseFloat(restotJustSumU),
      parseFloat(newData.taxperc),
      "before_vat"

    );

    console.log("the old data is", oldData);
    console.log("the new data is", newData);
    newData = {
      /* to show */
      // unitame: LetvalueCurrUnits.name, /*  - الـوحدة name */
      unitame: newData.unitame /*  - الـوحدة name */,
      symbol: newData.symbol /*  - إسم الصنف  */,
      /* to maaths */
      /* float - الإجمالي */
      // totJust: newData.new_exchange_price * newData.val1,
      totJust: newtotJust,
      /* float - الإجمالي بعد الخصم */
      totAfterDisc: tot_before_vat_after_desc,

      tot_before_vat_after_d: tot_before_vat_after_desc,

      tab3Val:
        oldData?.tab3Val == null || oldData?.tab3Val == 0 ? 0 : restotJustSumU,
      // totAfterDisc: newData.val1 * newData.new_exchange_price - newData.discount,
      /* to go */

      currency_from: newData.currency_from /* float - id الصنف */,
      unit: newData.unit /* int - الـوحدة id */,
      /* float - الكمية */
      val1: newData.val1,
      // new _val1:newData.new _val1,
      /* float - العبوة */
      val: newData.val,
      // new _val:newData.val,
      /* float - الـسعر */
      new_exchange_price: newData.new_exchange_price,
      // all_new_exchange_price: newData.new_exchange_price,
      /* float - الخصم مبلغ _ قيمة الخصم  مبلغ*/
      discount: resDisCountU,
      DiscPers: newData.DiscPers == "" ? 0 : newData.DiscPers,
      // new _DiscPers: newData.DiscPers,
      /* float -  ضريبة القيمة المضافة نسبة */
      taxperc: newData.taxperc,
      // new _taxperc: newData.taxperc,
      /* float - إجمالي قيمة المضافة  */


          taxval:restaxvalU,
      // new _taxval: (newData.totJust-newData.discount)*newData.taxperc/100,
      /* float - الصافي */

      // netAmountSub: resNetU,
      netAmountSub:
        oldData?.tab3Val == null || oldData?.tab3Val == 0
          ? resNetU
          : 2 * resNetU,
      // netAmo untSub: ((restotJustSumU - resDisCountU)+((restotJustSumU-resDisCountU)*newData.taxperc/100)).toFixed(2),
      barcode: newData.barcode /*  - الباركود */,
      sub_center: 0 /* int -  */,
      sub_branch: sto.branchi /* int - الفرع */,
      notes: newData.notes /* string - ملاحضة */,
      sale_price: newData.sale_price /* string - سعر الببيع */,
      tot_before_vat_after_d: tot_before_vat_after_desc,
    };
    const dataUpdate = [...isdata];
    const index = oldData.tableData.id;
    dataUpdate[index] = newData;
    setIsData([...dataUpdate]);
    handSums(dataUpdate);
    resolve();
  };


  const hRowDelete = (event, rowData) => {
    let dataDelete = [...isdata];

    rowData.forEach((rd) => {
      dataDelete = dataDelete.filter((t) => t.tableData.id !== rd.tableData.id);
    });

    setIsData([...dataDelete]);
    // 
    // setIsData(dataDelete);
    handSums(dataDelete);

    if (dataDelete.length === 0) {
      setIsData([]);
      setInvDisc(insh.InvDisc);
      toDoIdata("minus", "");
      setIData({
        ...idata, netAmount: 0,
        amountOfVat: 0,
        amountWithVat: 0,
        tot_purch: 0,
        totAfterDisc: 0,
        amountOfVats: 0,
        cash_part: 0,
        agel_part: 0,
        bank_part: 0,
        network_part: 0,
        Pay_mouny: 0,
        tot_sale: 0,
        Remaining: 0,
        tab3Val: 0,
        taxperc: 0,
        netAmount: 0,
        amountAfterDisc: 0,
        minus: 0,
        discounts: 0,
        amountBefordisc: 0,
      });
      setPayment({
        ...payment,
        paidup: 0,
        stay: 0,
      });
      setRemains({
        ...remains,
        cashPart: 0,
        agelPart: 0,
        networkPart: 0,
        bankPart: 0,
        empPart: 0,
      });
      setExSub(0);
    }
    setExSub(dataDelete.length);
  };

  const pinAutosValue = async (Tbl, id) => {
    if (Tbl === "Safe")
      AutoOptions.Safes.map((x) => {
        if (x.id == id) setValueSafes(x);
      });
    else if (Tbl === "Stock")
      AutoOptions.Stocks.map((x) => {
        if (x.id == id) setValueStock(x);
      });
    else if (Tbl === "omla")
      gets.omlats.map((x) => {
        if (x.id == id) setOmlaValue(x);
      });
    else if (Tbl === "Customer")
      optionsCustomers.map((x) => {
        if (x.id == id) setValueCustomers(x);
      });
    else if (Tbl === "salesmen")
      gets.salesmen.map((x) => {
        if (x.id == id) setValueSalesmen(x);
      });
    else if (Tbl === "costcenter")
      gets.costcenters.map((x) => {
        if (x.id == id) setValueCostcenter(x);
      });
    else if (Tbl === "Bank")
      AutoOptions.Banks.map((x) => {
        if (x.i == id) setValueBank(x);
      });
    else if (Tbl === "BankPMD")
      AutoOptions.Banks.map((x) => {
        if (x.id == id) setValueBankPMD(x);
      });
    else if (Tbl === "Bank2PMD")
      AutoOptions.Banks.map((x) => {
        if (x.id == id) setValueBankTransPMD(x);
      });
    else if (Tbl === "EmployeePMD")
      gets.Employees.map((x) => {
        if (x.id == id) setValueEmployePMD(x);
      });
    // }).catch(error => { })
  };

  const [numberbarcode, setNumberbarcode] = useState("");
  const [isBarcode, setIsBarcode] = useState(false);
  const [isApiConnect, setIsApiConnect] = useState(true);

  // useEffect(() => {
  //   if (isBarcode) {
  //     getrowDataCurrencie();
  //     setNumberbarcode("");
  //   }
  // }, [isBarcode]);

  const [barcoding, setBarcoding] = useState(false);

  const [multiCodeList, setMultiCodeList] = useState([]);
  const SearchActivityReports = {
    barcode: '',
    curr: {},
    symbol: '',
    barcodeS: '',
    groupS: { id: -1, name: "الكل ", parentId: -1 },
    groupSe: { id: -1, name: "الكل ", parentId: -1 },
    group: { id: -1, name: "الكل", parentId: -1 },
    currS: {}

  };

  const [rptParams, setRptParams] = useState(SearchActivityReports);
  const [code, setCode] = useState([]);

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };

  const onChangeAutoDataSubCurr = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
    if (newValue.id === "" || newValue.id == null) {

    } else {
      getBarcodeMulti(newValue.barcode);
    }
  };

  const [groupsOptions, setGroupsOptions] = useState(() => {
    return [{ id: -1, name: "الكل", parentId: -1 }, ...AutoOptions.groups];
  });
  const onChangeAutoDataG = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue, groupSe: newValue });
    if (newValue.id == null) {
      setGroupsSubs([]);
    } else {
      var subGroup = AutoOptions.groupsSub.filter((obj) => obj.parentId == newValue.id);
      setGroupsSubs([...subGroup]);
    }
    viewHandle(newValue.id);
  };
  const onChangeAutoDataSub = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue, groupSe: newValue });
    viewHandle(newValue.id);

  };
  const onChangeSearchD = (event) => {
    if (checked.autoOrStaticck == true) {
      event.preventDefault();
      event.stopPropagation();
      getBarcodeMulti(rptParams?.barcodeS);
    } else {
      event.preventDefault();
      event.stopPropagation();
      GetItemSearch(rptParams?.barcodeS);
    }
  };
  const onChangeDataA = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
    //event.stopPropagation();
  };
  const [fetching, setFetching] = useState(false);
  const viewHandle = async (group_id) => {

    if (rptParams.barcodeS == null && group_id == null && rptParams.symbol == null) {

    } else {
      setcuRows([]);
      setFetching(true);
      await axios
        .get(
          uri +
          `RptSafes/ItemsSearch?currShow=${-1}
      &&curr_type=${-1}
  &&groupid=${group_id}
  &&take=${50}
  &&item_code=${rptParams?.barcodeS}
  &&item_name=${rptParams?.symbol}
  &&emp=${sto.empi}`
        )
        .then((res) => {
          setcuRows(res.data);
          // setcuRows(
          //   res.data.map((sub) => {
          //     var nameGroup = AutoOptions.groups.find((x) => x.id === sub.group_id);
          //     return {
          //       ...res.data,
          //       namG: nameGroup,
          //       img: null,
          //       minPrice: sub.min_sale_price

          //     };
          //   }));
          //console.log(" the data is", res.data);
          setFetching(false);
        })
        .catch((err) => {
          setFetching(false);
          //console.log(err);
          setcuRows([]);
        });
    }
  };

  const [groupsSubs, setGroupsSubs] = useState([]);
  const [symbolSearch, setSymbolSearch] = useState({});
  const [custData, setCustData] = useState({});






  const GetItemSearch = async (itemcode) => {

    let item = null;

    if (itemcode == null) {

    } else {

      await axios
        .get(
          uri +
          `RptSales/CurrCodes?currCode=${itemcode}
            &&emp=${sto.empi}`
        )
        .then((res) => {
          item = res.data?.barcodes[0];
        })
        .catch((err) => {
          toast.error("لا يوجد صنف لهذا الباركود");
          console.log(err);
        });
      if (item != null) {
        await getListSearch(item);
      } else {
        toast.error("لا يوجد صنف لهذا الباركود");
      }
    }
  };

  const getListSearch = (dataItem) => {
    // eds-changes
    var nameGroup = AutoOptions.groups.find((x) => x.id === dataItem.group_id);

    setcuRows(
      // dataItem.map((sub) => {
      [
        {
          ...dataItem,
          namG: nameGroup,
          sale_price: dataItem.sale,
          purch_price: dataItem.purch,
          img: null,
        }]
    );

  }
  const onChangeSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    getBarcodeMulti(rptParams?.barcode);
  };

  const getBarcodeMulti = async (itemcode) => {
    let item = null;
    if (itemcode == "null") {

    } else {
      await axios
        .get(
          uri +
          `RptSales/CurrCodes?currCode=${itemcode}
            &&emp=${sto.empi}`
        )
        .then((res) => {

          item = res.data?.barcodes[0];
        })
        .catch((err) => {
          toast.error("لا يوجد صنف لهذا الباركود");
          console.log(err);
        });



      if (item != null) {
        setRptParams({ ...rptParams, barcode: '', barcodeS: '' });
        await barcodeadd(item);
        // setIsBarcode(true);
      } else {
        toast.error("لا يوجد صنف لهذا الباركود");
      }
    }
    //  setBarcoding(false);
  };

  const getCustomerData = async () => {
    if (valueCustomers?.id == null) {

      alert("ooooo");
    } else {
      await axios
        .get(
          uri +
          `RptSales/getCustData?custId=${valueCustomers?.id}`
        )
        .then((res) => {

          setCustData(res.data?.custData[0]);
        })
        .catch((err) => {
          toast.error("لا يوجد بيانات لهذا العميل ");
          console.log(err);
        });

      if (custData != null) {
        handleOpenCustomerData();
      } else {
        toast.error("لا يوجد بيانات لهذا العميل ");
      }
    }
  };

 
  const barcodeadd = (dataCode) => {
    let pricei = 0.0;
    let totJust = 0.0;
    let taxval = 0.0;
    let taxperc = 0.0;
    let diaperc = 0.0;
    let dismount = 0.0;
    let valperc = 0.0;
    if (setting.PrTy_InPr === "22") {
      taxperc = 0.0;
      pricei = parseFloat(dataCode?.purch).toFixed(2);
    } else if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" || setting.PrTy_InPr == "03") {
      pricei = parseFloat(dataCode?.sale);
      taxperc = (parseFloat(dataCode.tax)).toFixed(2);
    }
    else {
      pricei = parseFloat(dataCode?.purch);
      taxperc = (parseFloat(dataCode.tax)).toFixed(2);
    };

    if (dataCode.discount == null) { dataCode.discount = 0; };
    if (dataCode.amount_disc == null) { dataCode.amount_disc = 0; };

    taxval = ((parseFloat(pricei) * parseFloat(dataCode.tax)) / 100).toFixed(2);
    totJust = (parseFloat(pricei)).toFixed(2);
    diaperc = (parseFloat(dataCode.discount)).toFixed(2);
    dismount = (parseFloat(dataCode.amount_disc)).toFixed(2);
    valperc = (parseFloat(dataCode.perc)).toFixed(2);

    let errorList = [];
    let restaxvalA = 0,
      resNetA = 0,

      restotJustSumA = 0,
      restotJustAfterDiscSumA = 0,
      resDiscPerA = 0,
      resDisCountA = 0,
      tot_befor_vat_after_disc = 0;

    restotJustSumA = parseFloat(totJust);

    if (permistionInv.DSOB == true) {
      if (checked.discRateck === true) {
        //  alert('in if')
        resDiscPerA = parseFloat(invDisc.rate);
        resDisCountA = (
          parseFloat(invDisc.rate / 100) * parseFloat(totJust)
        ).toFixed(2);

        restotJustAfterDiscSumA =
          parseFloat(totJust) - parseFloat(resDisCountA);
      } else if (checked.discMinusck === true) {
        resDiscPerA = (
          (parseFloat(idata.minus) / parseFloat(totJust)) *
          100
        ).toFixed(2);
        resDisCountA = (
          parseFloat(resDiscPerA / 100) * parseFloat(totJust)
        ).toFixed(2);
      }

    } else {
      restotJustAfterDiscSumA =
        parseFloat(totJust) - parseFloat(dismount);

      resDiscPerA = parseFloat(diaperc);
      resDisCountA = (
        parseFloat(diaperc / 100) * parseFloat(totJust)
      ).toFixed(2);

    }



    restotJustAfterDiscSumA =
      parseFloat(totJust) - parseFloat(resDisCountA);


    restaxvalA = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(taxperc),
      "taxval"
    );

    tot_befor_vat_after_disc = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(taxperc),
      "before_vat"
    );


    resNetA = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(taxperc),
      "Net"
    );
    dataCode = {
      /* to show */
      minPrice: dataCode.mPrice,
      PricePu: dataCode.purch,
      unitame: dataCode.unitName /*  - الـوحدة name */,
      symbol: dataCode.symbol /*  - إسم الصنف  */,
      /* to maaths */
      /* int -  الإجمالي */
      totJust: restotJustSumA,
      /* int - إجمالي الخصم بعد الخصم */
      totAfterDisc: tot_befor_vat_after_disc,

      tot_before_vat_after_d: tot_befor_vat_after_disc,
      sale_price:parseFloat(dataCode?.sale),


      tab3Val: dataCode?.is_tab3_curr == 1 ? restotJustAfterDiscSumA : 0,

      /* to go */
      // currency_from: parseInt(subInfo.id), /* float - id الصنف */
      currency_from: parseInt(dataCode.curr) /* float - id الصنف */,
      unit: parseInt(dataCode.unit) /* int - الـوحدة id */,
      /* float - الكمية */
      val1: parseFloat(1),
      // new _val1:parseFloat(subInfo.new _val1),
      /* float - العبوة */
      val: parseFloat(valperc),
      // new _val:parseFloat(subInfo.perc),
      /* float - الـسعر */
      new_exchange_price: parseFloat(pricei),
      // all_new_exchange_price: parseFloat(subInfo.Price),
      /* float - الخصم  مبلغ*/
      discount: resDisCountA,
      /* float - %  خصم نسبة */
      DiscPers: resDiscPerA,
      /* float -  ضريبة القيمة المضافة نسبة */
      taxperc: parseFloat(taxperc),
      /* float - إجمالي قيمة المضافة  */
      taxval: parseFloat(restaxvalA),
      netAmountSub: (parseFloat(restaxvalA) + parseFloat(tot_befor_vat_after_disc)).toFixed(2),
      barcode: dataCode.barcode /*  - الباركود */,
      sub_center: 0 /* int -  */,
      sub_branch: sto.branchi /* int - الفرع */,

      unitame: dataCode.unitName,
      sale_price:parseFloat(dataCode.sale),
      // DiscPers: parseFloat(diaperc),
      // discount: parseFloat(dataCode.amount_disc),
      discount: resDisCountA,
      DiscPers: resDiscPerA,
      taxperc: parseFloat(taxperc),
    };
    if (errorList.length < 1) {
      //no error
      var oldData1 = isdata.filter((obj) => obj.barcode == dataCode.barcode && obj.id == dataCode.curr);
      var newData = isdata.filter((obj) => obj.barcode.toString() === dataCode.barcode.toString());

      console.log("the oldadata is", oldData1);


      if (newData.length != 0) {
        var oldData = isdata.filter((obj) => obj.barcode.toString() === dataCode.barcode.toString());

        // var oldData = isdata.filter((obj) => obj.barcode == dataCode.barcode );
        let valq = (parseFloat(oldData[0].val1) + parseFloat(1)).toFixed(2);
        let totb = (parseFloat(oldData[0].totJust) + parseFloat(totJust)).toFixed(2);

        let taxv = (parseFloat(oldData[0].taxval) + parseFloat(restaxvalA)).toFixed(2);

        let totAfter = (parseFloat(oldData[0].totAfterDisc) + parseFloat(tot_befor_vat_after_disc)).toFixed(2);

        let net = (parseFloat(oldData[0].netAmountSub) + parseFloat(resNetA)).toFixed(2);
        var otherData = isdata.filter((obj) => obj.barcode.toString() !== dataCode.barcode.toString());

        const musadata = [...otherData, {
          ...oldData[0], val1: valq, totJust: totb,
          taxval: taxv, netAmountSub: net, totAfterDisc: totAfter,
          val: parseFloat(valperc),
        }];
        setIsData([...otherData, {
          ...oldData[0], val1: valq, totJust: totb,
          taxval: taxv, netAmountSub: net, totAfterDisc: totAfter,
          val: parseFloat(valperc),
        }]);
        //  setIsData([...musadata]);
        handSums(musadata);
      } else {
        let dataToAdd = [...isdata];
        dataToAdd.push(dataCode);
        setIsData(dataToAdd);
        handSums(dataToAdd);
        setExSub(dataToAdd.length);
      }
    } else {
      // resolve();
    }
  };


  const transformDone = () => {
    his.replace({
      pathname: `/Invoces`,
      state: transToSal,
    });
    GetConvertingInv(
      location.state.setting.iformid,
      "02"
    );
    location.state.setting = transToSal.setting;
    setSetting(location.state.setting);
    location.state.stor = transToSal.stor;
    setStor(location.state.stor);

    setOr({ ...or, postput: "toPost" });
  };
  const transformDonePurch = () => {
    his.replace({
      pathname: `/Invoces`,
      state: transToPurch,
    });
    GetConvertingInv(
      location.state.setting.iformid,
      "01"
    );
    location.state.setting = transToPurch.setting;
    setSetting(location.state.setting);
    location.state.stor = transToPurch.stor;
    setStor(location.state.stor);

    setOr({ ...or, postput: "toPost" });
  };
  const showChosedInv = async (t, val) => {
    const tbs = {
      View_Inv_reseloving: true,
      parms: {
        iprocid: val,
        bol1: permiCuCo.CWVat,
        sProTyInvPro: setting.PrTy_InPr,
      },
      autos: {},
    };
    const alt = { View_Inv_reseloving: true };

    const al = Object.entries(alt).reduce((u, [n, v]) => {
      u[`${n}` + `${t ? "t" : ""}`] = v;
      return u;
    }, {});
    const alts = { ...al, ...tbs };
    await axios
      .post(uri + "Res", alts)
      .then((res) => {
        //console.log('res.data'); console.log(res.data);

        setIData(res.data.inv);
        console.log("the res data inve is", res.data.inv);
        // .then(resSub => {
        setIsData(res.data.invS);
        setPayment({
          ...payment,
          paidup: res.data.inv?.Pay_mouny,
          stay: res.data.inv?.Remaining,
        });
        setRemains({
          ...remains,
          cashPart: res.data.inv?.cash_part,
          agelPart: res.data.inv?.agel_part,
          networkPart: res.data.inv?.network_part,
          bankPart: res.data.inv?.bank_part,
          empPart: res.data.inv?.emp_part,
        });
        setSums({
          ...sums,
          val1: res.data.invS.reduce(
            (totalVal1, sub) => totalVal1 + sub.val1,
            0
          ),
        });

        res.data.inv.inv_type === 1
          ? setValueType(AutoOptions.types[0])
          : setValueType(AutoOptions.types[1]);
        pinAutosValue("Safe", res.data.inv.safe);

        pinAutosValue("Stock", res.data.inv.stock);
        pinAutosValue("Customer", res.data.inv.cust_id);
        /* Autos options */
        if (res.data.inv.salesman != -1)
          pinAutosValue("salesmen", res.data.inv.salesman);
        else {
          setValueSalesmen(AutoOptions?.salesuser);
        }
        if (res.data.inv.center != -1)
          pinAutosValue("costcenter", res.data.inv.center);
        else {
          setValueCostcenter(insh.Auto);
        }
        let letNetworkck = false,
          letCashck = false,
          letcashORnetworksw = false;
        if (res.data.inv.pay_type === 1) {
          setValueBank(insh.AutoBank);
          letCashck = true;
          letcashORnetworksw = false;
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: false,
            DeleIcon: true,
          });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
        }
        else if (res.data.inv.pay_type === 2) {
          letNetworkck = true;
          letcashORnetworksw = true;
          setUiElement({ ...uiElement, bank: false, DeleIcon: true });
          const text = transForm("network").toString();
          setLbl({ ...lbl, chashNetwork: text });
          pinAutosValue("Bank", res.data.inv.bank);
        }
        else if (res.data.inv.pay_type === -1 && parseFloat(res.data.inv.cash_part) > 0) {
          setValueBank(insh.AutoBank);
          letCashck = true;
          letcashORnetworksw = false;
          setSwitchState({ ...switchState, cashORnetworksw: false });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: false,
            DeleIcon: true,
          });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
        }
        else if (res.data.inv.pay_type === -1 && parseFloat(res.data.inv.network_part) > 0) {
          letNetworkck = true;
          letcashORnetworksw = true;
          setUiElement({ ...uiElement, bank: false, DeleIcon: true });

          setValueBank(res.data.inv.bank);
          const text = transForm("network").toString();
          setLbl({ ...lbl, chashNetwork: text });
          pinAutosValue("Bank", res.data.inv.bank);
        }
        else if (res.data.inv.pay_type === -1 && res.data.inv.Pay_mouny === 0) {
          // setValueStock(AutoOptions.Stocks[0]);
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: true,
            stock: true,
            DeleIcon: true,
          });
          setValueBank(insh.AutoBank);
        }
        else {
          // setValueStock(AutoOptions.Stocks[0]);
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: true,
            stock: true,
            DeleIcon: true,
          });
          setValueBank(insh.AutoBank);
        }

        setInfoInv({
          id: res.data.inv.id,
          InvId_Return:
            res.data.inv.InvId_Return != null
              ? res.data.inv.InvId_Return
              : infoInv.InvId_Return,
        });

        /* ======= S in PMD ======= */
        /* checked AND Autos */
        let cash_p = false,
          network_p = false,
          agel_p = false,
          bank_p = false,
          emp_p = false;

        if (res.data.inv.cash_part !== null || res.data.inv.cash_part === 0) {
          cash_p = true;
        }

        if (
          res.data.inv.network_part !== null ||
          res.data.inv.network_part === 0
        ) {
          // g etAutosOptions('Bank',res.data.inv.bank,'NWinPMD');
          pinAutosValue("BankPMD", res.data.inv.bank);
          network_p = true;
        } else {
          setValueBankPMD(insh.AutoBank);
        }

        if (res.data.inv.agel_part !== null || res.data.inv.agel_part === 0) {
          agel_p = true;
        }

        if (res.data.inv.bank_part !== null || res.data.inv.bank_part === 0) {
          // g etAutosOptions('Bank',res.data.inv.bank,'Bk2inPMD');
          pinAutosValue("Bank2PMD", res.data.inv.bank);
          bank_p = true;
        }

        if (res.data.inv.emp_part !== null || res.data.inv.emp_part === 0) {
          // g etAutosOptions('Employee',res.data.inv.emp,'EmpinPMD');
          pinAutosValue("EmployeePMD", res.data.inv.emp);
          emp_p = true;
        }

        setSwitchState({ ...switchState, cashORnetworksw: letcashORnetworksw });
        setChecked({
          ...checked,

          // in UP
          cashck: letCashck,
          networkck: letNetworkck,
          // in PMD
          cash_partck: cash_p,
          network_partck: network_p,
          agel_partck: agel_p,
          bank_partck: bank_p,
          emp_partck: emp_p,
        });

        /* ======= E in PMD ======= */
        setPermiElm({ ...permiElm, SaUp: permiElm.Up });
        // set discount values
        fcHandSumsToinvDisc(res.data.inv?.amountBefordisc, res.data.inv?.minus);

        handCloseDw();



      })
      .catch((error) => {
        /*console.log('no res');*/
      });
  };



  useEffect(() => {
    showDialogInv();
  }, []);

  const showDialogInv = async () => {

    if (props.isDialog===true){
      let t=false;
      setOr({ ...or, postput: "toPut", PrAC: 0 });
    const tbs = {
      View_Inv_reseloving: true,
      parms: {
        iprocid: props.invData.proc_id,
        bol1: permiCuCo.CWVat,
        sProTyInvPro: props.invData.ProcType_InvProc,
      },
      autos: {},
    };
    const alt = { View_Inv_reseloving: true };

    const al = Object.entries(alt).reduce((u, [n, v]) => {
      u[`${n}` + `${t ? "t" : ""}`] = v;
      return u;
    }, {});
    const alts = { ...al, ...tbs };
    await axios
      .post(uri + "Res", alts)
      .then((res) => {
        //console.log('res.data'); console.log(res.data);

        setIData(res.data.inv);
        console.log("the res data inve is", res.data.inv);
        // .then(resSub => {
        setIsData(res.data.invS);
        setPayment({
          ...payment,
          paidup: res.data.inv?.Pay_mouny,
          stay: res.data.inv?.Remaining,
        });
        setRemains({
          ...remains,
          cashPart: res.data.inv?.cash_part,
          agelPart: res.data.inv?.agel_part,
          networkPart: res.data.inv?.network_part,
          bankPart: res.data.inv?.bank_part,
          empPart: res.data.inv?.emp_part,
        });
        setSums({
          ...sums,
          val1: res.data.invS.reduce(
            (totalVal1, sub) => totalVal1 + sub.val1,
            0
          ),
        });

        res.data.inv.inv_type === 1
          ? setValueType(AutoOptions.types[0])
          : setValueType(AutoOptions.types[1]);
        pinAutosValue("Safe", res.data.inv.safe);

        pinAutosValue("Stock", res.data.inv.stock);
        pinAutosValue("Customer", res.data.inv.cust_id);
        /* Autos options */
        if (res.data.inv.salesman != -1)
          pinAutosValue("salesmen", res.data.inv.salesman);
        else {
          setValueSalesmen(AutoOptions?.salesuser);
        }
        if (res.data.inv.center != -1)
          pinAutosValue("costcenter", res.data.inv.center);
        else {
          setValueCostcenter(insh.Auto);
        }
        let letNetworkck = false,
          letCashck = false,
          letcashORnetworksw = false;
        if (res.data.inv.pay_type === 1) {
          setValueBank(insh.AutoBank);
          letCashck = true;
          letcashORnetworksw = false;
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: false,
            DeleIcon: true,
          });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
        }
        else if (res.data.inv.pay_type === 2) {
          letNetworkck = true;
          letcashORnetworksw = true;
          setUiElement({ ...uiElement, bank: false, DeleIcon: true });
          const text = transForm("network").toString();
          setLbl({ ...lbl, chashNetwork: text });
          pinAutosValue("Bank", res.data.inv.bank);
        }
        else if (res.data.inv.pay_type === -1 && parseFloat(res.data.inv.cash_part) > 0) {
          setValueBank(insh.AutoBank);
          letCashck = true;
          letcashORnetworksw = false;
          setSwitchState({ ...switchState, cashORnetworksw: false });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: false,
            DeleIcon: true,
          });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
        }
        else if (res.data.inv.pay_type === -1 && parseFloat(res.data.inv.network_part) > 0) {
          letNetworkck = true;
          letcashORnetworksw = true;
          setUiElement({ ...uiElement, bank: false, DeleIcon: true });

          setValueBank(res.data.inv.bank);
          const text = transForm("network").toString();
          setLbl({ ...lbl, chashNetwork: text });
          pinAutosValue("Bank", res.data.inv.bank);
        }
        else if (res.data.inv.pay_type === -1 && res.data.inv.Pay_mouny === 0) {
          // setValueStock(AutoOptions.Stocks[0]);
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: true,
            stock: true,
            DeleIcon: true,
          });
          setValueBank(insh.AutoBank);
        }
        else {
          // setValueStock(AutoOptions.Stocks[0]);
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: true,
            stock: true,
            DeleIcon: true,
          });
          setValueBank(insh.AutoBank);
        }

        setInfoInv({
          id: res.data.inv.id,
          InvId_Return:
            res.data.inv.InvId_Return != null
              ? res.data.inv.InvId_Return
              : infoInv.InvId_Return,
        });

        /* ======= S in PMD ======= */
        /* checked AND Autos */
        let cash_p = false,
          network_p = false,
          agel_p = false,
          bank_p = false,
          emp_p = false;

        if (res.data.inv.cash_part !== null || res.data.inv.cash_part === 0) {
          cash_p = true;
        }

        if (
          res.data.inv.network_part !== null ||
          res.data.inv.network_part === 0
        ) {
          // g etAutosOptions('Bank',res.data.inv.bank,'NWinPMD');
          pinAutosValue("BankPMD", res.data.inv.bank);
          network_p = true;
        } else {
          setValueBankPMD(insh.AutoBank);
        }

        if (res.data.inv.agel_part !== null || res.data.inv.agel_part === 0) {
          agel_p = true;
        }

        if (res.data.inv.bank_part !== null || res.data.inv.bank_part === 0) {
          // g etAutosOptions('Bank',res.data.inv.bank,'Bk2inPMD');
          pinAutosValue("Bank2PMD", res.data.inv.bank);
          bank_p = true;
        }

        if (res.data.inv.emp_part !== null || res.data.inv.emp_part === 0) {
          // g etAutosOptions('Employee',res.data.inv.emp,'EmpinPMD');
          pinAutosValue("EmployeePMD", res.data.inv.emp);
          emp_p = true;
        }

       // setSwitchState({ ...switchState, cashORnetworksw: letcashORnetworksw });
        setChecked({
          ...checked,

          // in UP
          cashck: letCashck,
          networkck: letNetworkck,
          // in PMD
          cash_partck: cash_p,
          network_partck: network_p,
          agel_partck: agel_p,
          bank_partck: bank_p,
          emp_partck: emp_p,
        });

        /* ======= E in PMD ======= */
        ///setPermiElm({ ...permiElm, SaUp: permiElm.Up });
        // set discount values
        setPermiElm({ ...permiElm, SaUp: permiElm.Up });

        fcHandSumsToinvDisc(res.data.inv?.amountBefordisc, res.data.inv?.minus);
        if (props.invData.ProcType_InvProc==="02"){
          setIshedden(true);
      }else{
          setIshedden(permistionInv.Up==true?false:true);
      }
      })
      .catch((error) => {
      });
    }
  };



  const showChosedInvt = async (t, val) => {
    const tbs = { parms: { iprocid: val, bol1: permiCuCo.CWVat }, autos: {} };
    const alt = { View_Inv_reseloving: true };
    const al = Object.entries(alt).reduce((u, [n, v]) => {
      u[`${n}` + `${t ? "t" : ""}`] = v;
      return u;
    }, {});
    const alts = { ...al, ...tbs };

    //  console.log('__tb--')
    //  console.log(tb)
    await axios
      .post(uri + "Res", alts)
      .then((res) => {
        //console.log('res.data'); console.log(res.data);

        setIData(res.data.inv);
        setIsData(res.data.invS);

        // .then(resSub => {
        setPayment({
          ...payment,
          paidup: res.data.inv?.Pay_mouny,
          stay: res.data.inv?.Remaining,
        });
        setRemains({
          ...remains,
          cashPart: res.data.inv?.cash_part,
          agelPart: res.data.inv?.agel_part,
          networkPart: res.data.inv?.network_part,
          empPart: res.data.inv?.emp_part,
          bankPart: res.data.inv?.bank_part,

        });
        setSums({
          ...sums,
          val1: res.data.invS.reduce(
            (totalVal1, sub) => totalVal1 + sub.val1,
            0
          ),
        });
        setStor({ ...stor, comt: val });
        res.data.inv.inv_type == 1
          ? setValueType(AutoOptions.types[0])
          : setValueType(AutoOptions.types[1]);
        pinAutosValue("Safe", res.data.inv.safe);
        pinAutosValue("Stock", res.data.inv.stock);
        pinAutosValue("Customer", res.data.inv.cust_id);


      
        /* Autos options */
        if (res.data.inv.salesman != -1)
          pinAutosValue("salesmen", res.data.inv.salesman);
        else {
          
          setValueSalesmen(AutoOptions?.salesuser);
        }
        if (res.data.inv.center != -1)
          pinAutosValue("costcenter", res.data.inv.center);
        else {
          setValueCostcenter(insh.Auto);
        }
        let letNetworkck = false,
          letCashck = false,
          letcashORnetworksw = false;
        if (res.data.inv.pay_type == 1) {
          setValueBank(insh.AutoBank);
          letCashck = true;
          letcashORnetworksw = false;

          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: false,
            DeleIcon: true,
          });
        } else if (res.data.inv.pay_type == 2) {
          letNetworkck = true;
          letcashORnetworksw = true;
          setUiElement({ ...uiElement, bank: false, DeleIcon: true });
          setLbl({ ...lbl, chashNetwork: transForm("network") });
          pinAutosValue("Bank", res.data.inv.bank);
        }
        else if (res.data.inv.pay_type == -1 && parseFloat(res.data.inv.cash_part)> 0) {
          setValueBank(insh.AutoBank);
          letCashck = true;
          letcashORnetworksw = false;
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: false,
            DeleIcon: true,
          });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
        }
        else if (res.data.inv.pay_type == -1 && parseFloat(res.data.inv.network_part)> 0) {
          letNetworkck = true;
          letcashORnetworksw = true;
          setUiElement({ ...uiElement, bank: false, DeleIcon: true });
          const text = transForm("network").toString();
          setLbl({ ...lbl, chashNetwork: text });
          pinAutosValue("Bank", res.data.inv.bank);
        }
        else if (res.data.inv.pay_type == -1 && parseFloat(res.data.inv.Pay_mouny) == 0) {
          setValueStock(AutoOptions.Stocks[0]);
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: true,
            stock: true,
            DeleIcon: true,
          });
          setValueBank(insh.AutoBank);
        }
        else {
          setValueStock(AutoOptions.Stocks[0]);
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: true,
            stock: true,
            DeleIcon: true,
          });
          setValueBank(insh.AutoBank);
        }
        setInfoInv({
          id: res.data.inv.id,
          InvId_Return:
            res.data.inv.InvId_Return != null
              ? res.data.inv.InvId_Return
              : infoInv.InvId_Return,
        });

        /* ======= S in PMD ======= */
        /* checked AND Autos */
        let cash_p = false,
          network_p = false,
          agel_p = false,
          bank_p = false,
          emp_p = false;

        if (res.data.inv.cash_part !== null || res.data.inv.cash_part === 0) {
          cash_p = true;
        }

        if (
          res.data.inv.network_part !== null ||
          res.data.inv.network_part === 0
        ) {
          pinAutosValue("BankPMD", res.data.inv.bank);
          network_p = true;
        } else {
          setValueBankPMD(insh.AutoBank);
        }

        if (res.data.inv.agel_part !== null || res.data.inv.agel_part === 0) {
          agel_p = true;
        }

        if (res.data.inv.bank_part !== null || res.data.inv.bank_part === 0) {
          pinAutosValue("Bank2PMD", res.data.inv.bank);
          bank_p = true;
        }

        if (res.data.inv.emp_part !== null || res.data.inv.emp_part === 0) {
          pinAutosValue("EmployeePMD", res.data.inv.emp);
          emp_p = true;
        }

        setSwitchState({ ...switchState, cashORnetworksw: letcashORnetworksw });
        setChecked({
          ...checked,

          // in UP
          cashck: letCashck,
          networkck: letNetworkck,
          // in PMD
          cash_partck: cash_p,
          network_partck: network_p,
          agel_partck: agel_p,
          bank_partck: bank_p,
          emp_partck: emp_p,
        });

        /* ======= E in PMD ======= */
        setPermiElm({ ...permiElm, SaUp: permiElm.Up });
        handCloseDwt();
      })
      .catch((error) => {
        /*console.log('no res');*/
      });
  };

  const [voucherDialog, setVoucherDialog] = useState({
    isOpen: false,
    data: "",
    onSucsess: "",
    onEnd: ""
  });


  const blurPaid2 = (pay) => {
    if (parseFloat(pay) > parseFloat(idata.netAmount)) {
      let valueVcher = (pay - parseFloat(idata.netAmount)).toFixed(2);
      //event.stopPropagation();
      setVoucherDialog({
        isOpen: true,
        data: {
          ...idata, id: infoInv.id,
          omlaId: omlaValue.id,
          exchange: omlaValue.exchange,
          valueOfVoucher: valueVcher
        },
        onSuccess: () => {
          // toDiloa
          setVoucherDialog({ ...voucherDialog, isOpen: false });
          toDoIdata("notes", "Receipt voucher for the amount:" + valueVcher);
        },

      });
      payment.paidupPMDcn = parseFloat(idata.netAmount);
      payment.stayPMDcn = payment.stay;
      payment.paidup = idata.netAmount;
      payment.stay = 0;
      //setPayment({ ...payment, paidup: parseFloat(idata.netAmount), stay: 0 });
    }
  };

  const changPayment = (prop) => (event) => {

    if (prop === "paidup") {

      let total = (parseFloat(idata.netAmount) - event.target.value).toFixed(2);
      // toDiloag
      payment.paidupPMDcn = event.target.value;
      payment.stayPMDcn = payment.stay;
      setPayment({ ...payment, paidup: event.target.value, stay: total });
      setRemains({ ...remains, cashPart: event.target.value, agelPart: total, bankPart: 0, networkPart: 0 });

      if (valueType.id === 1) {

        if (switchState.cashORnetworksw == false) {
          setUiElement({
            ...uiElement,
            stock: false,
            payMethod: false,
            bank: true,
          });

          setRemains({ ...remains, cashPart: event.target.value, agelPart: total, bankPart: 0, networkPart: 0 });

          const reet = reInshAuto(0, "");
          setValueBank(reet);
          setValueStock(AutoOptions.Stocks[0]);
          setSwitchState({ ...switchState, cashORnetworksw: false });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
        } else if (switchState.cashORnetworksw == true) {
          setRemains({ ...remains, cashPart: 0, agelPart: total, bankPart: 0, networkPart: event.target.value });

          setUiElement({
            ...uiElement,
            stock: true,
            payMethod: false,
            bank: false,
          });

          setSwitchState({ ...switchState, cashORnetworksw: true });
          setLbl({ ...lbl, chashNetwork: transForm("network") });
        }
        else {
          setUiElement({
            ...uiElement,
            stock: false,
            payMethod: false,
            bank: true,
          });

          setRemains({ ...remains, cashPart: event.target.value, agelPart: total, bankPart: 0, networkPart: 0 });

          const reet = reInshAuto(0, "");
          setValueBank(reet);
          setValueStock(AutoOptions.Stocks[0]);
          setSwitchState({ ...switchState, cashORnetworksw: false });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });

        }


      }

    } else if (prop === "paidupPMDcn") {
      payment.stayPMDcn = (
        event.target.value - parseFloat(idata.netAmount)
      ).toFixed(2);
      setPayment({ ...payment, [prop]: event.target.value });
    }
  };

  const MathChckedIdata = (prop, val) => {
    let res = Number(val);
    res += idata.cash_part;
    setIData({ ...idata, [prop]: "", cash_part: res });
  };


  const MathChangIdata = (prop, val) => {
    let i = {
      cash: Number(remains.cashPart),
      network: Number(remains.networkPart),
      agel: Number(remains.agelPart),
      bank: Number(remains.bankPart),
      emp: Number(remains.empPart),
      sum: Number(0),
      sub: Number(0),
      sumall: Number(0),
      totinv: Number(idata.netAmount),
      res: Number(val),
    };
    let kind = "";
    if (idata.pay_type == 1) {
      kind = "cashPart";
    } else if (idata.pay_type == 2) {
      kind = "networkPart";
    } else {
      kind = "agelPart";
    }
    // if (prop === "cashPart") i.sum = i.res + i.agel + i.bank + i.emp+i.network;
    // else if (prop === "networkPart") i.sum = i.res + i.agel + i.bank + i.emp+i.cash;
    if (prop === "cashPart") {
      // i.sum = i.res + i.agel + i.bank + i.emp+i.sub;
      if (switchState.cashORnetworksw == false && remains.isCash == true) {
        i.sum = i.res + i.agel + i.bank + i.emp + i.network;
        remains.cashPart = i.res;
        remains.allpart = i.sum;
      }
      else if (switchState.cashORnetworksw == true && valueType.id == 2 &&
        remains.isNetwork == true && parseFloat(remains.networkPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        i.sum = i.res + i.agel + i.bank + i.emp + i.sub;
        remains.cashPart = i.res;
        remains.networkPart = i.sub;
        remains.allpart = i.sum;
      } else if (remains.isAgel == true && valueType.id == 1 && parseFloat(remains.agelPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.cashPart = i.res;
        remains.agelPart = i.sub;
        i.sum = i.res + i.sub + i.bank + i.emp + i.network;
      }
    }
    else if (prop === "networkPart") {
      //i.sum = i.res + i.agel + i.bank + i.emp+i.cash;
      if (switchState.cashORnetworksw == false && valueType.id == 2 &&
        remains.isCash == true && parseFloat(remains.cashPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.networkPart = i.res;
        remains.cashPart = i.sub;
        i.sum = i.res + i.agel + i.bank + i.emp + i.sub;
        remains.allpart = i.sum;
      } else if (switchState.cashORnetworksw == true && valueType.id == 2 &&
        remains.isNetwork == true && parseFloat(remains.networkPart) > 0) {
        i.sum = i.res + i.agel + i.bank + i.emp + i.cash;
        remains.allpart = i.sum;
      } else if (remains.isAgel == true && valueType.id == 1 && parseFloat(remains.agelPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.networkPart = i.res;
        remains.agelPart = i.sub;
        i.sum = i.res + i.sub + i.bank + i.emp + i.cash;
        remains.allpart = i.sum;
      }
    }
    else if (prop === "agelPart") {
      if (switchState.cashORnetworksw == false && valueType.id == 2 &&
        remains.isCash == true && parseFloat(remains.cashPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.cashPart = i.sub;
        i.sum = i.res + i.network + i.bank + i.emp + i.sub;
        remains.allpart = i.sum;
      } else if (switchState.cashORnetworksw == true && valueType.id == 2 &&
        remains.isNetwork == true && parseFloat(remains.networkPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.agelPart = i.res;
        remains.networkPart = i.sub;
        i.sum = i.res + i.sub + i.bank + i.emp + i.cash;
        remains.allpart = i.sum;
      } else if (remains.isAgel == true && valueType.id == 1) {
        i.sum = i.res + i.network + i.bank + i.emp + i.cash;
        remains.agelPart = i.res;
        remains.allpart = i.sum;
      }
    }
    else if (prop === "bankPart") {
      if (switchState.cashORnetworksw == false && valueType.id == 2 &&
        remains.isCash == true && parseFloat(remains.cashPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = i.cash - i.res;
        remains.cashPart = i.sub;
        i.sum = i.res + i.network + i.agel + i.emp + i.sub;
        remains.allpart = i.sum;
      } else if (switchState.cashORnetworksw == true && valueType.id == 2 &&
        remains.isNetwork == true && parseFloat(remains.networkPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.bankPart = i.res;
        remains.networkPart = i.sub;
        i.sum = i.res + i.sub + i.agel + i.emp + i.cash;
        remains.allpart = i.sum;
      } else if (remains.isAgel == true && valueType.id == 1 && parseFloat(remains.agelPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.agelPart = i.sub;
        i.sum = i.res + i.network + i.sub + i.emp + i.cash;
        remains.allpart = i.sum;
      }

    }
    else if (prop === "empPart") {
      if (switchState.cashORnetworksw == false && valueType.id == 2 &&
        remains.isCash == true && parseFloat(remains.cashPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.cashPart = i.sub;
        i.sum = i.res + i.network + i.agel + i.bank + i.sub;
        remains.allpart = i.sum;
      } else if (switchState.cashORnetworksw == true && valueType.id == 2 &&
        remains.isNetwork == true && parseFloat(remains.networkPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.networkPart = i.sub;
        i.sum = i.res + i.sub + i.agel + i.bank + i.cash;
        remains.allpart = i.sum;
      } else if (remains.isAgel == true && valueType.id == 1 && parseFloat(remains.agelPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.agelPart = i.sub;
        i.sum = i.res + i.network + i.sub + i.bank + i.cash;
        remains.allpart = i.sum;
      }
    }
    setRemains({
      ...remains,
      [prop]: i.res,
      allpart: i.sum,
    });
  };

  const changIdata = (prop) => (event) => {
    if (prop === "cashPart" && remains.isCash === true) {
      MathChangIdata(prop, event.target.value);
    } if (prop === "networkPart" && remains.isNetwork === true) {
      MathChangIdata(prop, event.target.value);
    } else if (prop === "agelPart" && remains.isAgel === true) {
      MathChangIdata(prop, event.target.value);
    } else if (prop === "bankPart" && remains.isBank === true) {
      MathChangIdata(prop, event.target.value);
    }
    else if (prop === "empPart" && remains.isEmp === true) {
      MathChangIdata(prop, event.target.value);
    }
    else if (prop == "check_refno" && remains.isBank == true) {
      toDoIdata(prop, event.target.value);
    } else if (prop == "refno") {
      toDoIdata(prop, event.target.value);
    } else if (prop == "date") {
      toDoIdata(prop, event.target.value);
    } else if (prop == "notes") {
      toDoIdata(prop, event.target.value);
    }
  };


  const changinvDisc2 = (prop) => (event) => {
    console.log("the permissions is", permiElm);

    let ret = null,
      evenTargetValue = null;
    if (isdata.length !== 0) {
      if (prop === "minus") {
        if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12")
          evenTargetValue = (
            (event.target.value * 100) /
            parseFloat(idata.tot_sale)
          ).toFixed(4);
        else
          evenTargetValue = (
            (event.target.value * 100) /
            parseFloat(idata.tot_purch)
          ).toFixed(4);

        if (permiElm.SDV !== 0)
          ret = retPrmiSDPandSDV(
            parseFloat(event.target.value),
            permiElm.SDV,
            "DiscC"
          );
        else ret = retPrmiSDPandSDV(evenTargetValue, permiElm.SDP, "DiscP");
        if (ret === "") {
          event.target.value = "";
          /*invDisc.rate='';*/ afterGetCurrMathRate(0);
        } else {
          afterGetCurrMathRate(evenTargetValue);
        }
      } else if (prop === "rate") {
        if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12")
          evenTargetValue = (
            parseFloat(idata.tot_sale) *
            (event.target.value / 100)
          ).toFixed(2);
        else
          evenTargetValue = (
            parseFloat(idata.tot_purch) *
            (event.target.value / 100)
          ).toFixed(2);

        if (permiElm.SDP !== 0)
          ret = retPrmiSDPandSDV(
            parseFloat(event.target.value),
            permiElm.SDP,
            "DiscP"
          );
        else ret = retPrmiSDPandSDV(evenTargetValue, permiElm.SDV, "DiscC");
        if (ret === "") {
          event.target.value = "";
          /*idata.minus='';*/ afterGetCurrMathRate(0);
        } else {
          afterGetCurrMathRate(event.target.value);
        }
      }
    }
    prop === "minus"
      ? setInvDisc({
        ...invDisc,
        [prop]: event.target.value,
        rate: evenTargetValue,
      })
      : setInvDisc({
        ...invDisc,
        [prop]: event.target.value,
        minus: evenTargetValue,
      });
  };


  const changinvDisc = (prop) => (event) => {
    console.log("the permissions is", permistionInv);

    let ret = null,
      evenTargetValue = null;
    if (isdata.length !== 0) {
      if (prop === "minus") {
        if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" || setting.PrTy_InPr == "03")
          evenTargetValue = (
            (event.target.value * 100) /
            parseFloat(idata.tot_sale)
          ).toFixed(2);
        else
          evenTargetValue = (
            (event.target.value * 100) /
            parseFloat(idata.tot_purch)
          ).toFixed(2);


        if (permistionInv.SDV !== 0)
          ret = retPrmiSDPandSDV(
            parseFloat(event.target.value),
            permistionInv.SDV,
            "DiscC"
          );

        else ret = retPrmiSDPandSDV(evenTargetValue, permistionInv.SDP, "DiscP");

        if (ret === "") {
          event.target.value = "";
          /*invDisc.rate='';*/ afterGetCurrMathRate(0);
        } else {
          afterGetCurrMathRate(evenTargetValue);
        }
      } else if (prop === "rate") {
        if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" || setting.PrTy_InPr == "03")
          evenTargetValue = (
            parseFloat(idata.tot_sale) *
            (event.target.value / 100)
          ).toFixed(2);
        else
          evenTargetValue = (
            parseFloat(idata.tot_purch) *
            (event.target.value / 100)
          ).toFixed(2);

        if (permistionInv.SDP !== 0)
          ret = retPrmiSDPandSDV(
            parseFloat(event.target.value),
            permistionInv.SDP,
            "DiscP"
          );
        else ret = retPrmiSDPandSDV(evenTargetValue, permistionInv.SDV, "DiscC");
        if (ret === "") {
          event.target.value = "";
          /*idata.minus='';*/ afterGetCurrMathRate(0);
        } else {
          afterGetCurrMathRate(event.target.value);
        }
      }
    }
    prop === "minus"
      ? setInvDisc({
        ...invDisc,
        [prop]: event.target.value,
        rate: evenTargetValue,
      })
      : setInvDisc({
        ...invDisc,
        [prop]: event.target.value,
        minus: evenTargetValue,
      });
  };
  const afterGetCurrMathRate = (rate) => {
    const isdataU = [...isdata];
    if (rate === "") rate = 0;
    for (let m = 0; m < isdata.length; m++) {
      isdataU[m].DiscPers = rate;
      isdataU[m].barcode = isdata[m].barcode;
      isdataU[m].currency_from = isdata[m].currency_from;
      isdataU[m].discount = ((isdata[m].totJust * rate) / 100).toFixed(2);
      isdataU[m].new_exchange_price = isdata[m].new_exchange_price;
      isdataU[m].sub_branch = isdata[m].sub_branch;
      isdataU[m].sub_center = isdata[m].sub_center;
      isdataU[m].symbol = isdata[m].symbol;
      isdataU[m].totJust = isdata[m].totJust;


      // isdataU[m].totAfterDisc =
      //   isdata[m].totJust - parseFloat(isdataU[m].discount);

      isdataU[m].tot_before_vat_after_d =
        parseFloat(isdataU[m].totJust) - parseFloat(isdataU[m].discount);
      isdataU[m].taxperc = isdata[m].taxperc;

      /* from samer */
      isdataU[m].taxval = maths(
        parseFloat(isdataU[m].tot_before_vat_after_d),
        parseFloat(isdataU[m].taxperc),
        "taxval"
      );
      isdataU[m].netAmountSub = maths(
        parseFloat(isdataU[m].tot_before_vat_after_d),
        parseFloat(isdataU[m].taxperc),
        "Net"
      );
      isdataU[m].totAfterDisc = maths(
        parseFloat(isdataU[m].tot_before_vat_after_d),
        parseFloat(isdataU[m].taxperc),
        "before_vat"
      );

      /* from me */
      // console.log("memememememmemmememmm");
      isdataU[m].totJust = isdata[m].totJust;
      isdataU[m].unit = isdata[m].unit;
      isdataU[m].unitame = isdata[m].unitame;
      isdataU[m].val = isdata[m].val;
      isdataU[m].val1 = isdata[m].val1;
      isdataU[m].tab3Val = subInfo?.is_tab3_curr == 1 ? parseFloat(isdataU[m].tot_before_vat_after_d) : 0;
    }

    handSums(isdataU);
    setIsData([...isdataU]);
  };

  /* s toggle btn */
  const [toggle, setToggle] = React.useState(null);
  const [pdfToggle, setPdfToggle] = React.useState(null);

  const clickOpenToggle = (event, no = 1) => {
    if (isdata.length !== 0) {
      if (no != 1) setPdfToggle(event.currentTarget);
      else setToggle(event.currentTarget);
    } else {
      handOSnack({
        vertical: "top",
        horizontal: "right",
        severit: "warning",
        text: "يجب تنزيل اصناف أو إختيار فاتورة",
        time: 2000,
      });
    }
  };

  const clickOpenTogglePrint = (event) => {
    if (isdata.length !== 0) {
      setToggle(event.currentTarget);
    } else {
      handOSnack({
        vertical: "top",
        horizontal: "right",
        severit: "warning",
        text: "يجب تنزيل اصناف أو إختيار فاتورة",
        time: 2000,
      });
    }
  };


  const clickCloseToggle = () => {
    setToggle(null);
  };
  /* e toggle btn */
  // ========================================== s Snack

  /* 'error' 'warning' 'info' 'success'
     'top' 'bottom' 'center' 'left' 'right' */
  const [snack, setSnack] = React.useState(insh.Snack);

  const { vertical, horizontal, openSnak } = snack;

  const elmntBtn = (title, icondiv) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
        disabled={true}
        startIcon={icondiv}
      >
        {" "}
        {title}
      </Button>
    );
  };

  const elmntIcon = (icondiv, dis) => {
    return (
      <Fab
        size="medium"
        color="primary"
        disabled={dis}
        style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
      >
        {icondiv}
      </Fab>
    );
  };

  const styBtn = {
    dis: { color: "#3b773d", background: "#4fa152" },
    undis: { color: "#fff" },
  };
  const handOSnack = (newState) => {
    setSnack({ openSnak: true, ...newState });
  };

  const handCSnack = (event, reason) => {
    // if (reason === 'clickaway') { return; }
    setSnack({ ...snack, openSnak: false });
  };

  const frontSnack = () => {
    return (
      <div className={clasSN.rootSN}>
        <Toaster />

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openSnak}
          autoHideDuration={snack.time}
          onClose={handCSnack}
          key={vertical + horizontal}
          className={clasSN.snackbarSN}
        >
          <Alert onClose={handCSnack} severity={snack.severit}>
            {" "}
            {snack.text}{" "}
          </Alert>
        </Snackbar>
      </div>
    );
  };



  const changChckedYahm = () =>
    alert("يمكنك تغييرها من إعدادات المبيعات في النظام");

  //----alter1
  const hedInv = () => {
    return (
      <InvoicesHead {...{
        ...props,
        AutoOptions,
        isPurches,
        setting,
        changChckedYahm,
        checked,
        handleOpenCustomer,
        changAutoCustomers
        , optionsCustomers,
        changAutoSalesMen,
        valueSalesmen,
        changAutoCostcenter,
        valueCostcenter,
        idata,
        changAutoType,
        valueType,
        valueSafes,
        changAutoSafes,
        changAutoStock,
        valueStock,
        changAutoBank,
        valueBank,
        changSwitch,
        uiElement,
        switchState,
        lbl,
        claslbl,
        gets,
        onChangeSearch,
        handleFocus,
        onChangeData,
        rptParams,
        setShowPaid,
        exsub,
        handClickInvs,
        infoInv,
        changIdata,
        omlaValue,
        showPaid,
        onChangeAutoDataSubCurr,
        clasCAB,
        changInvs,
        loading,
        toWhere,
        buttonClassnameSavetmp,
        changAutoOmla,
        SaleCheckbox,
        valueCustomers,
        permiCuCo,
        itmTble,
        footerInvoices,
        maxShifts,
        isStock,
        handleOpenItems,
        idSearch,
        setIdSearch,
        isHedden,
        permistionInv,
        getCustomerData,
      }}
      />

    );
  };

  const itmTble = () => {
    return (
      <Grid item container className={clasup.rootupMu}
      >
        <Grid item md={12} sm={12} xs={12} spacing={1}>
          <MaterialTable
            style={{ boxShadow: "none" }}
            options={{
              showTitle: false,
              toolbar: true,
              search: false,
              sorting: false,
              selection: true,
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,

              cellStyle: stElemnt.SubR,
              headerStyle: isPurches ? stElemnt.SubHPruch : isStock ? stElemnt.SubHSafes : stElemnt.SubHMu,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              paging: false,

              maxBodyHeight: Mtb.minmaxBHSub,
              minBodyHeight: Mtb.minmaxBHSub,
            }}
            components={{
              Toolbar: (props) => (
                <Fragment>
                  <div
                    className={clstoolbar.toolbarWrapper}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "2.7vh",
                    }}
                  >
                    <MTableToolbar
                      {...props}

                      searchFieldStyle={{ height: "2.4vh" }}
                    />
                  </div>
                </Fragment>
              ),
            }}

            actions={

              (!isHedden
                ?
                ([
                  {
                    icon: () => <Delete />,
                    tooltip: "حذف الأصناف",
                    onClick: hRowDelete,
                  },
                ])
                :
                false
              )

            }
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h3 style={{ color: "#000" }}></h3>
                ),
              },
              toolbar: [{ searchPlaceholder: "بحث في السجل" }],
            }}
            columns={columnsInvSub}
            data={isdata}
            icons={
              (!isHedden
                ?
                tableIcons
                :
                false
              )
            }


            editable={
              (!isHedden
                ?
                ({
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      hRowUpdate(newData, oldData, resolve);
                    })
                })
                :
                false
              )
            }

          />
        </Grid>
      </Grid>
    );



  };
  const footerInvoices = () => {
    return (
      <InvoiceFooter {...{
        isPurches,
        setting,
        idata,
        valueType,
        uiElement,
        changIdata,
        isStock,
        permistionInv,
        changinvDisc,
        invDisc,
        changClick,
        payment,
        changPayment,
        sums,
        currentUserName,
        isHedden,
      }}
      />

    );
  };

  const footAppbar = () => {
    return (
      <InvoicesAppar {...{
        ...props,
        buttonClassnameSave,
        buttonClassnameDelete,
        clasCAB,
        setting,
        loading,
        backHome,
        uiElement,
        success,
        handOpenDw,
        clickOpenToggle,
        isHedden,
        permistionInv,
        style,
        stElemnt,
        clasBA,
        switchState,
        handCOpenCu,
        TransitionUp,
        toWhere,
        toPrint,
        transForm,
        buttonClassnamePrint,
        elmntBtn,
        or,
        stor,
        setPdfToggle,
        generating,
        StyledMenuItem,
        deleteTos,
        transformDone,
        transformDonePurch,
        setConfirmDialog,
        pdfToggle,
        StyledMenu,
        handOpenDwt,
        handNewInv,
        HomeBcode,
        TransitionDown,
        styBtn,
        clickCloseToggle,
        toggle,
        clickOpenTogglePrint,
        isDialog,
      }}
      />

    );
  };


  const frontInvs = () => {

    return (
      // <SearchInvoiceModal
      <SearchInvoicesMains
        {...{
          openDw,
          handCloseDw,
          transition,
          showChosedInv,
          invsShow,
          stElemnt,
          style,
          valueTypeFltr,
          gets,
          searchedInvShow,
          setOr,
          or,
          optionsCustomers,
          PcIdIvPc: setting.PrTy_InPr,
          setting,
          setIsClick,
          ished,
          AutoOptions,
          isHedden,
          permistionInv,
          setIshedden,
          isStock,
          isPurches,
        }}
      />
    );
  };

  function ScrollTop(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });

    const handUp = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        "#back-to-top-anchor"
      );
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

    return (
      <Zoom in={trigger}>
        <div onClick={handUp} role="presentation" className={clasFu.root}>
          {children}
        </div>
      </Zoom>
    );
  }
  ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
  };

  const frontInvst = () => {
    return (
      // <Outstandingdf />
      <OutstandingInvoiceModal
        {...{
          openDwt,
          handCloseDwt,
          transition,
          showChosedInvt,
          invsShowt,
          stElemnt,
          style,
        }}
      />
    );
  };

  const [scroll, setScroll] = React.useState("paper");

  const CurrFavorite = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={dialog.fav}
          onClose={handleClDia}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle
            className={clasSM.dialogTitleSM}
            id="customized-dialog-title"
          >
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {gets.FavGroup.map((group, i) => (
                <Tab
                  key={i}
                  label={group.name}
                  onClick={() => changFavSlides(group.id)}
                />
              ))}
            </Tabs>
          </DialogTitle>

          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText id="scroll-dialog-description">
              <FavCard
                prop={favGroups}
                onclick={(id, barcode) => BefrowDataCurrencie(id, barcode)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClDia("fav", false)} color="primary">
              {transForm("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const stylesserch = {
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "blue",
        fontSize: 10,
      },
    },
  };

  const frontCurrencis = () => {

    return (
      // <InvoiceItemsModal
      <InvoiceItemsModals
        {...{
          openCuD,
          handleCloseCuD,
          transition,
          switchState,
          groups,
          groupsSub,
          changSlides,
          changAutoss,
          subInfo,
          cuRows,
          permiCuCo,
          setting,
          changAutoPricePay,
          valuePricePay,
          changAutoCurrUnits,
          changInfo,
          optionsPricePay,
          optionsCurrUnits,
          checked,
          changSwitch,
          _onDetected,
          changChcked,
          getrowDataCurrencie,
          stElemnt,
          counts,
          permistionInv,
          rptParams,
          onChangeSearchD,
          AutoOptions,
          isPurches,
          onChangeData,
          onChangeDataA,
          onChangeAutoDataG,
          groupsSubs,
          onChangeAutoDataSub,
          onRowClicks,
          viewHandle,
          groupsOptions,
          handleOpenItems,
          isStock,
          valueItemUnits,
          valueCurrUnits,
        }}
      />
    );
  };

  if (!isApiConnect) return <ReConnect />;

  return showSc ? (
    <BackdropScI />
  ) : (
    <div>
      {/* s Fixed */}
      {frontSnack()}
      {/* {frontAppBar()} */}
      {footAppbar()}

      {/* e Fixed */}
      {/* s UP */}
      {/* {frontUp()} */}


      {hedInv()}
      {/* <InvItemsTable {...{
        isdata, optionsCurrUnitsTable,setIsData,maths,handSums ,isPurches
      }}
      /> */}

      {frontInvs()}
      {/* e DW */}
      {/* s DW */}
      {frontInvst()}
      {frontCurrencis()}
      {/* s currFavD */} {CurrFavorite()} {/* e currFavD */}
      {/* s PMD */}
      {/* {frontPMDialog()} */}
      {/* e PMD */}
      {
        allow.c ? (
          <CustomersDialog
            open={isOpenCustomer}
            type={location.state.setting.cusType}
            onClose={() => {
              setIsOpenCustomer(false);
              // reloadGet();
            }}
            onSave={(custData) => {
              setIsOpenCustomer(false);
              reLoadCust(custData);
            }}

          />
        ) : null}
      {
        allow.cud ? (
          <CustomerDialogData
            open={isOpenCustomerData}
            type={location.state.setting.cusType}
            onClose={() => {
              setIsOpenCustomerData(false);
              // reloadGet();
            }}
            onSave={(updateData) => {
              setIsOpenCustomerData(false);
              reUpdateCust(updateData);
            }}
            custData={custData}
          />
        ) : null}

      {
        allow.it ? (
          <ItemsDialog
            open={isOpenItems}
            onClose={() => {
              setIsOpenItems(false);
            }}

          />
        ) : null}
      <DeleteConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* {allow.s ? (
        <ItemsDialog
          DialogOpened={isOpenSy}
          DialogClose={() => {
            setIsOpenSy(false);
            setAllow({ ...allow, s: false });
          }}
          locats={{ state: storS }}
        />
      ) : null} */}
      <ExtVoucherDialog
        voucherDialog={voucherDialog}
        setVoucherDialog={setVoucherDialog}
      />

      <PaidWindowForm
        {... {
          idata,
          invDisc,
          remains,
          setRemains,
          payment,
          changPayment,
          buttonClassnameSavePMD,
          toDB,
          restPM,
          loading,
          handClosePMD,
          style,
          changClick,
          changinvDisc,
          gets,
          openPMD,
          valueEmployePMD,
          uiElement,
          changChcked,
          transition,
          checked,
          clasAo,
          valueBankPMD,
          changAutoBankPMD,
          changIdata,
          changAutoEmployee,
          valueBankTransPMD,
          changAutoBank2,
          typePress,
          valueType,
        }}
      />
      {/* {allowPaid.c ? ( */}
      {/* <PaidWindowForm
          key="ddd"
          isOpen={isOpenCustomer}
          // remains={{}}
          // idata={{}}
          // payment={{}}
          // onClose={() => {
          //   setOpenPaidWindows(false);
          //   reloadGet();
          // }}
        /> */}
      {/* ) : null} */}

    </div>
  );
}
